// CheckboxList.tsx
import React from "react";

interface ExtraData {
  risk52value?: number;
  risk18value?: number;
}

interface Checkbox {
  label: string;
  checked: boolean;
  disabled: boolean;
  value: number;
  extra?: ExtraData;
}

interface CheckboxListProps {
  checkboxes: Checkbox[];
  onCheckboxChange: (
    label: string,
    checked: boolean,
    value: number,
    extra?: ExtraData
  ) => void;
  onSelectChange: (value: number, checkboxIndex: number) => void;
  formData?: any;
}

const CheckboxList: React.FC<CheckboxListProps> = ({
  checkboxes,
  onCheckboxChange,
  onSelectChange,
  formData,
}) => {
  const givenNumber = formData?.data.capital || 0;
  const filteredArray = [10, 25, 50, 75, 100];
  const maxLimit = 150000;

  // Verifica se o MAX-150000 já foi adicionado
  let maxAdded = false;

  return (
    <div>
      {checkboxes.map((checkbox, index) => (
        <div key={index} style={{ marginBottom: "10px" }}>
          <input
            type="checkbox"
            id={checkbox.label}
            checked={checkbox.checked}
            disabled={checkbox.disabled}
            onChange={(e) =>
              onCheckboxChange(
                checkbox.label,
                e.target.checked,
                checkbox.value,
                checkbox.extra
              )
            }
          />
          <label
            htmlFor={checkbox.label}
            style={{ marginLeft: "5px", fontWeight: "bold" }}
          >
            {checkbox.label}
          </label>

          {checkbox.extra &&
            onSelectChange &&
            formData &&
            formData.data.capital !== null && (
              <div style={{ marginLeft: "20px", marginTop: "5px" }}>
                <label
                  htmlFor={`risk-select-${index}`}
                  style={{ marginRight: "5px" }}
                >
                  Nível de Risco:
                </label>{" "}
                <select
                  id={`risk-select-${index}`}
                  value={checkbox.extra?.risk52value || ""}
                  onChange={(e) =>
                    onSelectChange(Number(e.target.value), index)
                  }
                >
                  {filteredArray.map((value,key) => {
                    const calculatedValue = givenNumber * (value / 100);
                    // Se o valor calculado for maior ou igual ao limite máximo, mostra apenas a opção MAX-150000
                    if (calculatedValue >= maxLimit) {
                      return (
                        !maxAdded && ((maxAdded = true), `MAX-${maxLimit}`)
                      );
                    }

                    // Caso contrário, mostra a opção normal
                    return (
                      <option key={value} value={value}>
                        {value}%
                        {/* show the calculatedvalue on currency */}
                        {` - ${calculatedValue.toLocaleString("pt-PT", {
                          style: "currency",
                          currency: "EUR",
                        })}`}
                      </option>
                    );
                  })}
                  {maxAdded && (
                    <option key={maxLimit} value="6">
                      MAX- {maxLimit.toLocaleString("pt-PT",{
                        style: "currency",
                        currency: "EUR",
                      })}
                    </option>
                  )}
                </select>
              </div>
            )}
        </div>
      ))}
    </div>
  );
};

export default CheckboxList;
