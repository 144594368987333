import React, { useEffect, useState } from "react";
import { Spin, Button } from "antd";
import SalesPieChart from "./Sales/Piechart";
import { useAuthContext } from "../context/AuthContext";
import { getToken } from "../api/Auth/helpers";
import { useNavigate } from "react-router-dom";

// Define types if needed
type SimuladorDataItem = {
  attributes: {
    estado: string;
  };
};

const Navegacao: React.FC = () => {
  const { simuladorData, isLoading } = useAuthContext();

  const [user, setUser] = useState(false);
  const navigate = useNavigate(); // Use the useNavigate hook

  useEffect(() => {
    const token = getToken();
    setUser(!!token);
    if (!token) {
      setTimeout(() => {
      }, 3000);
    }
  }, [navigate]);

  const estadoCounts: Record<string, number> = simuladorData.reduce(
    (acc: Record<string, number>, item: SimuladorDataItem) => {
      const estado = item.attributes.estado;
      if (acc[estado]) {
        acc[estado]++;
      } else {
        acc[estado] = 1;
      }
      return acc;
    },
    {}
  );

  const getEstadoColor = (estado: string): string => {
    switch (estado) {
      // Estados -
      // Fechada
      // Aberta
      // Entregue
      // Reagendado
      // Cancelada
      // Falsa
      // Repetida
      // Outro

      case "Fechada":
        return "#ff4d4f";
      case "Aberta":
        return "#ffa940";
      case "Entregue":
        return "#73d13d";
      case "Reagendada":
        return "#9254de";
      case "Cancelada":
        return "#595959";
      case "Falsa":
        return "#ffec3d";
      case "Repetida":
        return "#40a9ff";
      case "Outro":
        return "#ff7a45";
      default:
        return "#000000";
    }
  };

  const pieChartData = Object.entries(estadoCounts).map(([estado, count]) => ({
    name: `${estado}s`,
    value: count as number,
    color: getEstadoColor(estado),
  }));

  if (isLoading || !user) {
    return (
      <div className="bg-gray-200 min-h-screen flex items-center justify-center p-8">
        <div className="max-w-screen-lg w-full text-center">
          <Spin size="large" /> 
        </div>
      </div>
    );
  }
  return (
    <div className="bg-gray-200 min-h-screen flex items-center justify-center p-8">
      <div className="max-w-screen-2xl w-full">
        <SalesPieChart data={pieChartData} />
      </div>
    </div>
  );
};

export default Navegacao;
