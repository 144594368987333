import React, { useState, useEffect } from 'react';
import { List, Button, notification as antdNotification } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { getToken } from '../../api/Auth/helpers';
import { useAuthContext } from '../../context/AuthContext';

// Definindo a interface de Notificação
interface Notification {
    id: number | string;
    read: boolean;
    notification: {
        message: string;
        time: string;
        level: 'Low' | 'Medium' | 'High';
        link?: string;
    };
}

// Função auxiliar para obter a cor baseada na prioridade
function getPriorityColor(level: string) {
    switch (level) {
        case 'High': return 'red';
        case 'Medium': return 'orange';
        case 'Low': return 'green';
        default: return 'grey';
    }
}
const NotificationComponent = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const { user } = useAuthContext(); // Supondo que 'user' contém o ID do usuário autenticado

    const fetchNotifications = async () => {
        const token = getToken();
        try {
            const response = await fetch(`https://strapi.realbusinesscenter.wt.pt/api/notification-users?filters[user][id][$eq]=${user?.id}&filters[deleted_at][$null]=true&populate=*`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const result = await response.json();
                const formattedNotifications = result.data.map((item: any) => ({
                    id: item.id,
                    read: item.attributes.read,
                    notification: item.attributes.notification.data.attributes // Presumindo que essa é a estrutura de uma notificação
                }));
                setNotifications(formattedNotifications);
            } else {
                throw new Error('Failed to fetch notifications');
            }
        } catch (error) {
            antdNotification.error({
                message: 'Error',
                description: 'Could not fetch notifications.',
            });
        }
    };

    const markAsRead = async (notificationUserId: number | string) => {
        const token = getToken();
        try {
            const response = await fetch(`https://strapi.realbusinesscenter.wt.pt/api/notification-users/${notificationUserId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { read: true } }), // Adaptação para Strapi v4
            });
            if (response.ok) {
                fetchNotifications(); // Atualiza as notificações após marcar como lida
            } else {
                throw new Error('Failed to mark notification as read');
            }
        } catch (error) {
            antdNotification.error({
                message: 'Error',
                description: 'Could not mark notification as read.',
            });
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, [user?.id]); // Refetch when user changes

    const deleteNotification = async (notificationId: number | string) => {
        const token = getToken();
        try {
            // Atualiza o campo 'deleted_at' para o timestamp atual para realizar o soft delete
            const response = await fetch(`https://strapi.realbusinesscenter.wt.pt/api/notification-users/${notificationId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { deleted_at: new Date().toISOString() } }), // Define 'deleted_at' para o timestamp atual
            });
            if (response.ok) {
                // Remove a notificação da lista no estado após a remoção bem-sucedida
                setNotifications(currentNotifications =>
                    currentNotifications.filter(notification => notification.id !== notificationId)
                );
                antdNotification.success({
                    message: 'Success',
                    description: 'Notification removed successfully.',
                });
            } else {
                throw new Error('Failed to delete notification');
            }
        } catch (error) {
            antdNotification.error({
                message: 'Error',
                description: 'Could not delete notification.',
            });
        }
    };

    return (
        <List
            itemLayout="horizontal"
            dataSource={notifications}
            renderItem={item => (
                <List.Item
                    actions={[
                        <Button
                            type="link"
                            onClick={() => markAsRead(item.id)}
                            icon={<CheckOutlined />}
                            disabled={item.read} 
                        >
                        </Button>,
                        <Button
                            type="link"
                            onClick={() => deleteNotification(item.id)}
                            icon={<DeleteOutlined />} // Certifique-se de importar DeleteOutlined do @ant-design/icons
                        >
                        </Button>
                    ]}
                >
                    <List.Item.Meta
                        title={<a href={`${process.env.REACT_APP_PUBLIC_URL
                            }${item.notification.link || '#'}`} target="_blank" rel="noopener noreferrer">{item.notification.message}</a>}

                        description={
                            <>
                                <div>Received at {new Date(item.notification.time).toLocaleString()}</div>
                                <div>Priority: <span style={{ color: getPriorityColor(item.notification.level) }}>{item.notification.level}</span></div>
                            </>
                        }
                    />
                    {!item.read && <span style={{ color: 'red', marginLeft: '10px' }}>New</span>}
                </List.Item>
            )}
        />
    );
};

export default NotificationComponent;
