import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-gray-800">
      <h1 className="text-6xl font-bold">404</h1>
      <p className="text-2xl mb-4">Página não encontrada</p>
      <p className="mb-8">
        Desculpe, a página que você está procurando não existe.
      </p>
    </div>
  );
};

export default NotFoundPage;
