import React from "react";

interface RadioGroupProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  children: (Radio: React.FC<RadioProps>) => React.ReactNode;
}

interface RadioProps {
  value: string;
  checked: boolean;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  value,
  onChange,
  className,
  children,
}) => {
  const handleRadioChange = (newValue: string) => {
    if (value !== newValue) {
      onChange(newValue);
    }
  };

  return (
    <div className={className}>
      {children(({ value: radioValue, checked }) => (
        <input
          type="radio"
          value={radioValue}
          checked={checked}
          onChange={() => handleRadioChange(radioValue)}
        />
      ))}
    </div>
  );
};

export default RadioGroup;
