import React, { useEffect, useState } from "react";
import { Modal, Select } from "antd";
import { useAuthContext } from "../context/AuthContext";

const EditLeadModal = ({ record, visible, onCancel, onSave }: any) => {
  const [selectedZona, setSelectedZona] = useState("");
  const [selectedEquipa, setSelectedEquipa] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedEstado, setSelectedEstado] = useState("");
  // userid
  const [selectedUserId, setSelectedUserId] = useState("");

  useEffect(() => {
    setSelectedZona(
      record?.attributes?.user?.data?.attributes?.equipa?.data?.attributes?.zona
        ?.data?.attributes?.nome_zona
    );
    setSelectedEquipa(
      record?.attributes?.user?.data?.attributes?.equipa?.data?.attributes
        ?.nome_equipa
    );
    setSelectedUser(record?.attributes?.user?.data?.attributes?.username);
    setSelectedEstado(record?.attributes?.estado);
    setSelectedUserId(record?.attributes?.user?.data?.id);
  }, [record]);

  const { users } = useAuthContext();

  const handleUserChange = (value: any) => {
    setSelectedUser(value);
    
  };

  return (
    <Modal
      title="Editar"
      open={visible}
      onCancel={onCancel}
      onOk={() => {
        onSave({
          estado: selectedEstado,
          user: selectedUserId
        });
      }}
      okButtonProps={{ className: "bg-blue-500 text-white" }}
    >
      <div>
        <label htmlFor="zona">Zona:</label>
        <Select value={selectedZona} style={{ width: "100%" }}>
          <Select.Option
            key={
              record?.attributes?.user?.data?.attributes?.equipa?.data
                ?.attributes?.zona?.data?.id
            }
            value={
              record?.attributes?.user?.data?.attributes?.equipa?.data
                ?.attributes?.zona?.data?.id
            }
          >
            {
              record?.attributes?.user?.data?.attributes?.equipa?.data
                ?.attributes?.zona?.data?.attributes?.nome_zona
            }
          </Select.Option>
        </Select>
      </div>

      <div>
        <label htmlFor="equipa">Equipa:</label>
        <Select value={selectedEquipa} style={{ width: "100%" }}>
          <Select.Option
            key={record?.attributes?.user?.data?.attributes?.equipa?.data?.id}
            value={record?.attributes?.user?.data?.attributes?.equipa?.data?.id}
          >
            {
              record?.attributes?.user?.data?.attributes?.equipa?.data
                ?.attributes?.nome_equipa
            }
          </Select.Option>
        </Select>
      </div>

      <div>
        <label htmlFor="user">User:</label>
        <Select
          value={selectedUser}
          onChange={handleUserChange}
          style={{ width: "100%" }}
        >
          {Array.isArray(users) ? (
            users.map((user) => (
              <Select.Option key={user.id.toString()} value={user.id}>
                {user.username}
              </Select.Option>
            ))
          ) : (
            <Select.Option
              key={record?.attributes?.user?.data?.id}
              value={record?.attributes?.user?.data?.id}
            >
              {record?.attributes?.user?.data?.attributes?.username}
            </Select.Option>
          )}
        </Select>
      </div>
      {/* change and set estado */}
      <div>
        <label htmlFor="estado">Estado:</label>
        <Select
          value={selectedEstado}
          onChange={(value) => setSelectedEstado(value)}
          style={{ width: "100%" }}
        >
          <Select.Option value="Aberta">Aberta</Select.Option>
          <Select.Option value="Reagendado">Reagendado</Select.Option>
          <Select.Option value="Entregue">Entregue</Select.Option>
          <Select.Option value="Cancelada">Cancelada</Select.Option>
          <Select.Option value="Fechada">Fechada</Select.Option>
          <Select.Option value="Inválida">Inválida</Select.Option>
        </Select>
      </div>
    </Modal>
  );
};

export default EditLeadModal;
