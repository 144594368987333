import React, { useEffect, useState } from "react";
import backg from "../../../../assets/img/bkground.png";
import { useParams } from "react-router-dom";
import { getResultById } from "../../../../api/getSimInfo";
import { useNavigate } from "react-router-dom";
import { Spin, message } from "antd";
import { RiFileTextLine, RiArrowGoBackLine } from "react-icons/ri";
import LeadReport from "../../Relatorio";
import { useAuthContext } from "../../../../context/AuthContext";
import { CreateRelatorio, createObservacao } from "../../../../api/Backend";
import SimuladoModal from "../../sales_sim/Modal/NewSimModal";

const formatCurrency = (value: any) => {
  if (value === undefined || value === "" || (typeof value !== "string" && typeof value !== "number"))
    return "";
  const numericValue = typeof value === "string" ? parseFloat(value) : value;
  if (isNaN(numericValue)) return "";
  return `${numericValue.toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`;
};

const PageResultadosSaude: React.FC = () => {
  const { id } = useParams<any>();
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState<any>(false);
  const [isNewSimModalOpen, setIsNewSimModalOpen] = useState<any>(false);
  const { user, triggerUpdate } = useAuthContext();
  const [additionalData, setAdditionalData] = useState<any>({
    user_id: "",
    referencia: "",
    username: "",
    created_at: "",
    id: "",
    saleSim: undefined,
  });
  const [newSimData, setNewSimData] = useState<any>({
    nome: "",
    email: "",
    phone: "",
    birthday: "",
    uuid: "",
    id: "",
  });
  const [selectedTipo, setSelectedTipo] = useState<any>(40); // Tipo 40 selecionado por padrão
  const [periodo, setPeriodo] = useState("Anual"); // Estado para guardar o período escolhido


  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getResultById(id)
      .then((response: any) => {
        if (response && response.resultado_simulacao && response.resultado_simulacao.result) {
          const result = response.resultado_simulacao.result;

          // Descodificar agregados
          if (result.agregados) {
            try {
              result.agregados = JSON.parse(result.agregados);
            } catch (error) {
              console.error("Erro ao fazer parse de agregados", error);
            }
          }

          // Descodificar opcoesPlano
          if (result.opcoesPlano) {
            try {
              result.opcoesPlano = JSON.parse(result.opcoesPlano);
            } catch (error) {
              console.error("Erro ao fazer parse de opcoesPlano", error);
            }
          }

          // Descodificar opcoesPlanoAgregado
          if (result.opcoesPlanoAgregado) {
            try {
              result.opcoesPlanoAgregado = JSON.parse(result.opcoesPlanoAgregado);
            } catch (error) {
              console.error("Erro ao fazer parse de opcoesPlanoAgregado", error);
            }
          }

          // Descodificar descontos
          if (result.descontos) {
            try {
              result.descontos = JSON.parse(result.descontos);
            } catch (error) {
              console.error("Erro ao fazer parse de descontos", error);
            }
          }

          // Descodificar simples, completo e total se necessário
          if (result.simples) {
            try {
              result.simples = JSON.parse(result.simples);
            } catch (error) {
              console.error("Erro ao fazer parse de simples", error);
            }
          }

          if (result.completo) {
            try {
              result.completo = JSON.parse(result.completo);
            } catch (error) {
              console.error("Erro ao fazer parse de completo", error);
            }
          }

          if (result.total) {
            try {
              result.total = JSON.parse(result.total);
            } catch (error) {
              console.error("Erro ao fazer parse de total", error);
            }
          }

          // Após o parse, salvar no estado
          setData(response);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [id]);



  if (
    !data ||
    !data.resultado_simulacao ||
    !data.resultado_simulacao.result ||
    !data.resultado_simulacao.result.opcoesPlano
  ) {
    return (
      <div className="bg-gray-200 min-h-screen flex items-center justify-center p-8">
        <div className="max-w-screen-lg w-full text-center">
          <Spin size="large" />
        </div>
      </div>
    );
  }
  const calcularValorPorPeriodo = (valorAnual: number, periodo: string) => {
    switch (periodo) {
      case "Mensal":
        return valorAnual / 12;
      case "Trimestral":
        return valorAnual / 4;
      case "Semestral":
        return valorAnual / 2;
      case "Anual":
      default:
        return valorAnual;
    }
  };


  const handlePeriodoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPeriodo(event.target.value);
  };


  const openReportModal = () => {
    triggerUpdate();
    const date = new Date(data?.createdAt);
    const dateStr = date.toLocaleDateString("pt-PT");
    setAdditionalData({
      referencia: data?.uuid || "",
      user_id: user?.id.toString() || "",
      username: user?.username || "",
      created_at: dateStr,
      id: data?.id || "",
      saleSim: data?.saleSim,
    });
    setIsReportModalOpen(true);
  };

  const closeReportModal = () => {
    setIsReportModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsNewSimModalOpen(false);
  };

  window.onfocus = () => {
    if (!isReportModalOpen) window.location.reload();
  };

  const openNewSimModal = () => {
    setNewSimData({
      nome: data?.nome,
      email: data?.email,
      phone: data?.phone,
      birthday: data?.resultado_simulacao?.data_nasc,
      uuid: data?.uuid,
      id: data?.id || "",
    });
    setIsNewSimModalOpen(true);
  };

  const handleFormSubmit = (formData: any, observacao?: any) => {
    setLoading(true);
    CreateRelatorio(formData);
    if (observacao) {
      createObservacao(observacao);
    }
    closeReportModal();
    message.success("Relatório enviado com sucesso, a retornar às leads");
    setTimeout(() => {
      navigate("/leads");
      window.location.reload();
    }, 1500);
  };

  const handleTipoChange = (tipo: any) => {
    setSelectedTipo(tipo);
  };

  const getLabelByTipo = (tipo: any): string => {
    switch (tipo) {
      case 40:
        return "SIMPLES";
      case 41:
        return "Completo";
      case 42:
        return "Total";
      default:
        return "Desconhecido";
    }
  };

  const { resultado_simulacao } = data;
  const { result } = resultado_simulacao;
  const opcoesPlano = Array.isArray(result.opcoesPlano) ? result.opcoesPlano : [];
  const planoSelecionado = selectedTipo === "TODOS" ? opcoesPlano : opcoesPlano.find((plano: any) => plano.tipo === selectedTipo);


  return (
    <div className="w-full bg-gray-100 flex items-center justify-center">
      <div className="p-4 font-nexa w-full h-full overflow-auto">
        <h1 className="text-2xl font-bold mb-6">Simulação Real Seguro Saúde</h1>

        <div className="flex justify-end mb-4">
          <button className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded shadow-md" onClick={openReportModal}>
            <RiFileTextLine className="inline-block mr-2" />
            Gerar Relatório
          </button>
          <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded ml-2 shadow-md" onClick={() => navigate("/leads")}>
            <RiArrowGoBackLine className="inline-block mr-2" />
            Voltar
          </button>
        </div>

        {loading ? (
          <div className="bg-gray-200 min-h-screen flex items-center justify-center p-8">
            <div className="max-w-screen-lg w-full text-center">
              <Spin size="large" />
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-lg p-6">
            {/* Dados do Tomador */}
            <div className="grid grid-cols-2 gap-4 mb-8">
              <div className="text-left font-semibold">Nome Tomador do Seguro:</div>
              <div className="border p-2 rounded-md text-gray-700">{data?.nome}</div>

              <div className="text-left font-semibold">Email Tomador do Seguro:</div>
              <div className="border p-2 rounded-md text-gray-700">{data?.email}</div>

              <div className="text-left font-semibold">Número de telefone do Tomador do Seguro:</div>
              <div className="border p-2 rounded-md text-gray-700">{data?.phone}</div>
            </div>

            {/* Tabs para selecionar o tipo de seguro */}
            <div className="mb-8">
              <h2 className="text-xl font-bold mb-4">Escolha o tipo de seguro</h2>
              <ul className="flex space-x-4">
                <li
                  className={`cursor-pointer px-6 py-3 rounded-full text-center font-semibold transition-colors ${selectedTipo === "TODOS"
                    ? "bg-blue-500 text-white shadow-md"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                    }`}
                  onClick={() => handleTipoChange("TODOS")}
                >
                  TODOS
                </li>
                {opcoesPlano.map((plano: any) => (
                  <li
                    key={plano.tipo}
                    className={`cursor-pointer px-6 py-3 rounded-full text-center font-semibold transition-colors ${selectedTipo === plano.tipo
                      ? "bg-blue-500 text-white shadow-md"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                      }`}
                    onClick={() => handleTipoChange(plano.tipo)}
                  >
                    {getLabelByTipo(plano.tipo)}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mb-8">
              <h2 className="text-xl font-bold mb-4">Escolha o período de pagamento</h2>
              <select
                className="border p-2 rounded-md"
                value={periodo}
                onChange={handlePeriodoChange}
              >
                <option value="Anual">Anual</option>
                <option value="Semestral">Semestral</option>
                <option value="Trimestral">Trimestral</option>
                <option value="Mensal">Mensal</option>
              </select>
            </div>

            {/* Dados do Seguro */}
            {selectedTipo === "TODOS" ? (
              <div className="grid grid-cols-3 gap-4">
                {opcoesPlano.map((plano: any) => (
                  <div key={plano.tipo} className="p-4 bg-blue-50 rounded-lg shadow-inner">
                    <div className="text-center mb-4">
                      <h3 className="text-xl font-bold text-blue-600">{getLabelByTipo(plano.tipo)}</h3>
                      <p className="text-lg font-semibold text-gray-800">
                        <span className="text-blue-500">Premio Comercial:</span> {formatCurrency(calcularValorPorPeriodo(plano.premioComercial, periodo))}
                      </p>
                      <p className="text-lg font-semibold text-gray-800">
                        <span className="text-blue-500">Premio Total:</span> {formatCurrency(calcularValorPorPeriodo(plano.premioTotal, periodo))}
                      </p>

                    </div>

                  </div>
                ))}
              </div>
            ) : (
              planoSelecionado && (
                <div className="p-6 bg-blue-50 rounded-lg shadow-inner">
                  <div className="text-center mb-6">
                    <h3 className="text-2xl font-bold text-blue-600 mb-2">{getLabelByTipo(planoSelecionado.tipo)}</h3>
                    <div className="flex justify-center space-x-8">
                      <p className="text-lg font-semibold text-gray-800">
                        <span className="text-blue-500">Premio Comercial:</span> {formatCurrency(calcularValorPorPeriodo(planoSelecionado.premioComercial, periodo))}
                      </p>
                      <p className="text-lg font-semibold text-gray-800">
                        <span className="text-blue-500">Premio Total:</span> {formatCurrency(calcularValorPorPeriodo(planoSelecionado.premioTotal, periodo))}
                      </p>
                    </div>
                  </div>

                  {/* Mensagem adicional para o agente */}
                  <div className="text-center mt-4">
                    <p className="text-sm text-red-600">
                      Atenção: Caso sejam necessárias coberturas adicionais, o valor do prémio terá de ser ajustado de acordo com as coberturas selecionadas.
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
        )}
        <LeadReport isOpen={isReportModalOpen} onRequestClose={closeReportModal} onSubmit={handleFormSubmit} additionalData={additionalData} />
        {/* <SimuladoModal isOpen={isNewSimModalOpen} onRequestClose={handleCloseModal} dataRecieved={newSimData} />
       */}</div>
    </div>
  );
};

export default PageResultadosSaude;
