import { ConfigProvider, DatePicker, Modal, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/pt"; // Import Portuguese locale if needed
import "dayjs/plugin/utc"; // Import any other plugins if needed
import axios from "axios";
import { getTipoSimulacao } from "../../../../api/Backend";
import CheckboxList from "../../../checkboxList";

const backendApi = process.env.REACT_APP_BACKEND_API;
const SvIp = process.env.REACT_APP_SERVER_IP;

type Data = {
  nome: string;
  email: string;
  phone: string;
  resultado_simulacao: any;
  dados_simulacao: any;
  simulacao: number;
  tipo_simulacao: number;
};

const token =
  "0YJMNzew238n1s5f+nR9eq0Hb8vmWurDnZwHkBbuYMOIXet250/kNctAV/OPRk7ru7tM6vU3m0sNZPmh5ZAGNmVPzo1tJlzWcuMbR8hpkm7Q+g7mx2SB5OMV4Vhi7ggTxN42w4zElQ3jVfTDgwWmuQ7czidRnM2zG4RHPvikf8eDr2w118xV4MJrjyzUbOEl2RMsmJsV6oNNVGT3iQ92lt0+sHWLvzqFfM0kquIumVuhkni8ddOcNL6+vyRB3WQtoQszoaeKaAaN4mrph5ZqwmM18feM+CP325ZonfdFfbh6gZDmDd6QDFy/obNClX/XftfTHbOL7ddgl4RVOhGWkzXKZ2WbFWal5WNGl6Td94VL4awD4B3SVfpav3BllaG0HGDplNT1W2DA410eoqdi8CbxvOcE5xUTUP0ALjgyeoWeAKOdioE6rrWJZcSFt+vu";

const SimuladoModal = ({ dataRecieved, isOpen, onRequestClose }: any) => {
  const [activeSimulado, setActiveSimulado] = useState<any>(null); // State to control the active simulated scenario
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control the visibility of the inner modal
  const [formData, setFormData] = useState<any>({
    type: "vida",
    data: {
      name: "",
      data_inicio_contrato: null,
      birthDay1Insurance: null,
      birthDay2Insurance: null,
      date: null,
      fraccionamento: null,
      origemdoacesso: null,
      capital: null,
      numberOfInsurances: null,
      parcel: null,
      paymentType: null,
      risks: [],
    },
  });

  const [checkboxes, setCheckboxes] = useState<any[]>([
    { label: "Morte", checked: true, disabled: true, value: 0 },
    {
      label: "Invalidez Absoluta e Definitiva",
      checked: true,
      disabled: true,
      value: 3,
    },
    { label: "Médico Online", checked: true, disabled: true, value: 19 },
    { label: "Médico ao Domicílio", checked: true, disabled: true, value: 55 },
    {
      label: "Aconselhamneto Médico Telefónico",
      checked: true,
      disabled: true,
      value: 56,
    },
  ]);

  const [dataset, setDataSet] = useState<{
    [key: string]: {
      name: string;
      type: string;
      value: any;
      min?: any;
      max?: any;
      options?: {
        [key: string]: {
          value: string;
          text: string;
        };
      };
      color?: any;
      aviso?: any;
    };
  }>({});
  const [forceUpdate, setForceUpdate] = useState(0);

  const [slug, setSlug] = useState("");
  const [SimulacaoResult, setSimulacaoResult] = useState({});
  const [extraTrue, setExtraTrue] = useState(false);
  const [loading, setLoading] = useState(false);
  // show button to open page
  const [showButton, setShowButton] = useState(false);

  const simuladores = [
    { name: "Vida Futura", active: true, tipo: "vida-futura" },
    { name: "Vida Habitação", active: true, tipo: "vida-habitacao" },
    { name: "Vida Pleno", active: true, tipo: "vida-pleno" },
    { name: "Seguro de Saúde", active: false },
    { name: "Doenças Graves", active: false },
    { name: "Seguro Hospitalização", active: false },
    { name: "Global", active: false },
  ];

  const handleInnerModalCancel = () => {
    setIsModalVisible(false);
    // reload page
    window.location.reload();
  };
  const handleSimuladoClick = (simulado: any) => {
    setActiveSimulado(simulado);
    setSlug(simulado.tipo);

    // Check if tipo is vida-futura
    if (simulado.tipo === "vida-futura") {
      // Prepare simulation data for the vida-futura page
      const simulationData = {
        name: {
          name: "Nome",
          type: "text",
          value: dataRecieved.nome,
        },
        birthday: {
          name: "Data de nascimento",
          type: "date",
          value: dataRecieved.birthday,
        },
        contract: {
          name: "Contrato",
          type: "date",
          value: null, // Add your logic for contract value
          // min is today
          min: new Date(),
          max: new Date(new Date().setDate(new Date().getDate() + 30)),
        },
        duration: {
          name: "Duração",
          type: "select",
          value: "",
          // min is 10 and max is 20
          min: "10",
          max: "20",
        },
        radAwardCapital: {
          name: "Prémio mensal ou capital seguro",
          type: "select",
          value: "",
          options: {
            1: {
              value: "1",
              text: "mensal",
            },
            2: {
              value: "2",
              text: "capital seguro",
            },
          },
        },
        award: {
          name: "Valor",
          type: "number",
          value: null, // Add your logic for award value (int)
        },
        nome: {
          name: "Nome",
          type: "text",
          value: dataRecieved.nome,
        },
        email: {
          name: "Email",
          type: "text",
          value: dataRecieved.email,
        },
        phone: {
          name: "Phone",
          type: "text",
          value: dataRecieved.phone,
        },
      };

      setDataSet(simulationData);

      // Set the active simulated scenario
      setActiveSimulado(simulado);

      // Open the inner modal for data entry
      setIsModalVisible(true);
    }
    if (simulado.tipo === "vida-habitacao") {
      const novoObjeto = {
        capital: {
          name: "Capital em dívida",
          type: "number",
          value: null,
        },
        data_inicio_contrato: {
          name: "Data início contrato",
          type: "date",
          value: null, // Add your logic for contract value
          // min is today
          min: new Date(),
          max: new Date(new Date().setDate(new Date().getDate() + 30)),
        },
        data_nasc_1: {
          name: "Data de nascimento",
          type: "date",
          // datarecieved.birthday can be undefined so we need to check if it is undefined before setting the value
          value: dataRecieved.birthday,  
        },
        
        mensalidade: {
          name: "Mensalidade atual",
          type: "number",
          value: null,
        },
        titulares: {
          name: "Número de titulares",
          type: "select",
          value: "",
          // min 1 max 2
          min: "1",
          max: "2",
        },
        actualizacao_capitais: {
          // checkbox either 1 or 0
          name: "Actualização de capitais",
          type: "select",
          value: "",
          options: {
            1: {
              value: "0",
              text: "Não",
            },
            0: {
              value: "1",
              text: "Sim",
            },
          },
        },
        endpoint: {
          name: "Endpoint",
          type: "text",
          value: "simulador/simular/habitacao'",
        },
        invalidez_60: {
          // checkbox either 1 or 0
          name: "Invalidez 60% ou superior",
          type: "select",
          value: "",
          options: {
            1: {
              value: "1",
              text: "Sim",
            },
            0: {
              value: "0",
              text: "Não",
            },
          },
        },
      };
      setDataSet(novoObjeto);
      setActiveSimulado(simulado);
      setIsModalVisible(true);
    }
    if (simulado.tipo === "vida-pleno") {
      const vidaPlenoObject = {
        nome: {
          name: "Nome",
          type: "text",
          value: dataRecieved.nome,
        },
        email: {
          name: "Email",
          type: "text",
          value: dataRecieved.email,
        },
        phone: {
          name: "Phone",
          type: "text",
          value: dataRecieved.phone,
        },
        capital: {
          name: "Capital em dívida",
          type: "number",
          value: null,
        },
        data_inicio_contrato: {
          name: "Data início contrato",
          type: "date",
          value: null, // Add your logic for contract value
          // min is today
          min: new Date(),
          max: new Date(new Date().setDate(new Date().getDate() + 30)),
        },
        birthDay1Insurance: {
          name: "Data de nascimento",
          type: "date",
          value: dataRecieved.birthday,
        },
        numberOfInsurances: {
          name: "Número de titulares",
          type: "select",
          value: "",
          min: "1",
          max: "2",
        },
        // select for paymenttype debito direto ou multibanco value of select is 68 or 77
        paymentType: {
          name: "Tipo de pagamento",
          type: "select",
          value: "",
          options: {
            68: {
              value: "68",
              text: "Débito Direto",
            },
            77: {
              value: "77",
              text: "Multibanco",
            },
          },
        },
        // also fraccionamento mensal trimestral semestral ou anual
        fraccionamento: {
          name: "Fraccionamento",
          type: "select",
          value: "",
          options: {
            1: {
              value: "Mensal",

              text: "Mensal",
            },
            3: {
              value: "Trimestral",
              text: "Trimestral",
            },
            6: {
              value: "Semestral",
              text: "Semestral",
            },
            12: {
              value: "Anual",
              text: "Anual",
            },
          },
        },
        // field type extra that shows message "Consultar simulador area reservada"
        extraMessage: {
          name: "Extra Message",
          type: "message",
          value: null,
          aviso:
            "Caso queira alterar os riscos utilize o simulador da área reservada.",
          color: "red",
        },
        checkboxes: {
          name: "Coberturas",
          value: "",
          type: "extra",
        },
      };

      setDataSet(vidaPlenoObject);
      setActiveSimulado(simulado);
      setIsModalVisible(true);
    }
  };
  function transformData(data: any) {
    function formatDate(dateString: any) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    const transformedData: {
      name: any;
      birthday: string;
      contract: string;
      duration: string;
      radAwardCapital?: number;
      award?: any;
      capital?: any;
    } = {
      name: data.nome || "",
      birthday: formatDate(data.birthday) ?? "", // Format as yyyy-MM-dd
      contract: formatDate(data.contract) ?? "", // Format as yyyy-MM-dd
      duration: data.duration ? String(data.duration) : "", // Convert to string
    };

    // Check radAwardCapital and apply logic
    if (data.radAwardCapital === "1") {
      transformedData.radAwardCapital = 1;
      transformedData.award = data.award;
    } else if (data.radAwardCapital === "2") {
      transformedData.award = null;
      transformedData.capital = data.award;
    }

    return transformedData;
  }

  // clearform
  const clearForm = () => {
    // reload
    window.location.reload();
  };

  const handleSelectChange = (value: number, checkboxIndex: number) => {
    const updatedCheckboxes = checkboxes.map((checkbox, index) =>
      index === checkboxIndex
        ? {
            ...checkbox,
            extra: {
              risk52value: value,
            },
          }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
  };

  const handleCheckboxChange = (
    label: string,
    checked: boolean,
    value: number,
    extra?: any
  ) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.label === label
        ? {
            ...checkbox,
            checked,
            extra: extra || checkbox.extra, // Preserve existing extra data
          }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
  };

  const risks = checkboxes
    .filter((checkbox) => checkbox.checked)
    .map((checkbox) => checkbox.value);

  // add the checked risks to the default one
  const risksIds = [...risks];







  //   make a function to handle ok on simulacao
  const handleOkClickOnSim = async () => {
    // check if the activeSimulado is null
    if (!activeSimulado) {
      // if it is return
      return;
    }

    // make loading true

    setLoading(true);

    // check the tipo

    // check the activesimulado tipo
    if (activeSimulado.tipo === "vida-futura") {
      // if it is vida-futura then check if the dataset is empty
      if (Object.keys(dataset).length === 0) {
        // if it is empty return
        setLoading(false);
        return;
      }
      const dadosSimulacaoVidaFutura: any = {
        birthday: dataset.birthday.value || dataRecieved.birthday,
        contract: dataRecieved.contract,
        duration: dataRecieved.duration || 10,
        radAwardCapital: dataRecieved.radAwardCapital || "1",
        award: dataRecieved.award,
        nome: dataset.name.value,
        email: dataset.email.value,
        phone: dataset.phone.value,
      };
      try {
        const transformedData = transformData(dadosSimulacaoVidaFutura);
        const response = await axios.post(
          `${backendApi}/vida-futura`,
          transformedData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              'x-server-ip-for': SvIp,
              "x-api-key": "RealVidaSeguros-Leads" // Include your API key here
            },
          }
        );
        //  adiciona o nome, o email e o phone ao dadosSimulacaoVidaFutura

        if (response.status === 200) {
          const tipo = await getTipoSimulacao(slug);
          const simulation: Data = {
            nome: dataRecieved.nome,
            email: dataRecieved.email,
            phone: dataRecieved.phone,
            resultado_simulacao: response.data,
            dados_simulacao: dadosSimulacaoVidaFutura,
            simulacao: dataRecieved.id,
            tipo_simulacao: tipo,
          };
          const json = {
            uuid: dataRecieved.uuid,
            ...simulation,
          };
          setSimulacaoResult(json);
        }
      } catch (error: any) {
        if (error.response && error.response.status === 405) {
          console.error("Erro 405:", error.response.data.message);
          message.error(error.response.data.message);
        } else {
          console.error("Erro:", error.message);
        }
        setLoading(false);
        return;
      }
    }
    // make for vida-habitacao
    if (activeSimulado.tipo === "vida-habitacao") {
      // if it is vida-futura then check if the dataset is empty
      if (Object.keys(dataRecieved).length === 0) {
        // if it is empty return
        setLoading(false);
        return;
      }

      const dadosSimulacaoVidaHabitacao2 = {
        capital: dataRecieved.capital,
        data_inicio_contrato: dataRecieved.data_inicio_contrato,
        data_nasc_1: dataRecieved.data_nasc_1 ,
        mensalidade: dataRecieved.mensalidade,
        titulares: dataRecieved.titulares ? dataRecieved.titulares : "1", // If not set, default to "1"
        endpoint: "simulador/simular/habitacao",
        actualizacao_capitais:
          dataRecieved.actualizacao_capitais === "0" ? "1" : "1", // If 1, set to 1, else set to 0
        ...(dataRecieved.invalidez_60 === "1" && { invalidez_60: "1" }), // Add invalidez_60 only if it's "1"
        ...(dataRecieved.data_nasc_2 && {
          data_nasc_2: dataRecieved.data_nasc_2,
        }),
      };

      try {
        const response = await axios.post(
          `${backendApi}/habitacao`,
          dadosSimulacaoVidaHabitacao2,
          {
            headers: {
              "Content-Type": "application/json",
              'x-server-ip-for': SvIp,
              'x-api-key': 'RealVidaSeguros-Leads',
            },
          }
        );
        if (response.status === 200) {
          //  if STATUS = "ERROR" also give error
          if (response.data.STATUS === "ERROR") {
            message.error(response.data.MESSAGE);
            setLoading(false);
            return;
          }
          const tipo = await getTipoSimulacao(slug);

          // Adiciona o nome, o email e o phone ao dadosSimulacaoVidaHabitacao2
          dadosSimulacaoVidaHabitacao2.nome = dataRecieved.nome;
          dadosSimulacaoVidaHabitacao2.email = dataRecieved.email;
          dadosSimulacaoVidaHabitacao2.phone = dataRecieved.phone;

          const simulation: Data = {
            nome: dataRecieved.nome,
            email: dataRecieved.email,
            phone: dataRecieved.phone,
            resultado_simulacao: response.data,
            dados_simulacao: dadosSimulacaoVidaHabitacao2,
            simulacao: dataRecieved.id,
            tipo_simulacao: tipo,
          };
          const json = {
            uuid: dataRecieved.uuid,
            ...simulation,
          };
          setSimulacaoResult(json);
        }
      } catch (error: any) {
        if (error.response && error.response.status === 405) {
          console.error("Erro 405:", error.response.data.message);
          message.error(error.response.data.message);
        } else {
          console.error("Erro:", error.message);
        }
        setLoading(false);
        return;
      }
    }
    // make for vida-pleno
    if (activeSimulado.tipo === "vida-pleno") {
      // if it is vida-futura then check if the dataset is empty
      if (Object.keys(dataRecieved).length === 0) {
        // if it is empty return
        setLoading(false);
        return;
      }
      const dadosSimulacaoVidaPleno = {
        type: "Pleno",
        data: {
          numberOfInsurances: dataRecieved.numberOfInsurances
            ? dataRecieved.numberOfInsurances
            : "1", // If not set, default to "1"
          // date should be yyyy-mm-ddThh:MM:SS.xxxZ format dataRecieved.data_inicio_contrato
          date: new Date(dataRecieved.data_inicio_contrato).toISOString(),
          // birthDay1Insurance: dataRecieved.birthday ou entao a definida no dataset
          birthDay1Insurance: dataset.birthDay1Insurance.value || dataRecieved.birthday,
          birthDay2Insurance: dataRecieved.data_nasc_2
            ? dataRecieved.data_nasc_2
            : null,
          capital: dataRecieved.capital,
          paymentType: dataRecieved.paymentType ? dataRecieved.paymentType : 68,
          parcel: 77,
          fraccionamento: dataRecieved.fraccionamento
            ? dataRecieved.fraccionamento
            : "Mensal",
          risks: risksIds,
        },
      };

      try {
        const response = await axios.post(
          `${backendApi}/pleno`,
          dadosSimulacaoVidaPleno,
          {
            headers: {
              "Content-Type": "application/json",
              "x-server-ip-for": SvIp,
              // key
              "x-api-key": "RealVidaSeguros-Leads",
            },
          }
        );
        if (response.status === 200) {
          const tipo = await getTipoSimulacao(slug);

          const simulation: Data = {
            nome: dataRecieved.nome,
            email: dataRecieved.email,
            phone: dataRecieved.phone,
            resultado_simulacao: response.data,
            dados_simulacao: dadosSimulacaoVidaPleno,
            simulacao: dataRecieved.id,
            tipo_simulacao: tipo,
          };

          // make a newDadosSimulacaoVidaPleno where it goes the name, email and contacto, nro de titulares fraccionamento
          const newDadosSimulacaoVidaPleno : any ={
            ...dadosSimulacaoVidaPleno.data,
            nome:dataRecieved.nome,
            email:dataRecieved.email,
            phone:dataRecieved.phone
          }
          const newData : any = {
            nome: dataRecieved.nome,
            email: dataRecieved.email,
            phone: dataRecieved.phone,
            resultado_simulacao: response.data,
            dados_simulacao:newDadosSimulacaoVidaPleno,
            simulacao: dataRecieved.id,
            tipo_simulacao: tipo,
          }
          const json = {
            uuid: dataRecieved.uuid,
            ...newData,
          };
          setSimulacaoResult(json);
        }
      } catch (error: any) {
        console.error(error);
        if (error.response && error.response.status === 405) {
          console.error("Erro 405:", error.response.data.message);
          message.error(error.response.data.message);
        } else if (error.message) {
          message.error(error.response.data.message);
          // reset all values after 2 seconds
          setTimeout(() => {
            clearForm();
          }, 2000);
        } else {
          console.error("Erro:", error.message);
          setTimeout(() => {
            clearForm();
          }, 2000);
        }
        setLoading(false);
        return;
      }
    }
  };

  useEffect(() => {
    // Lógica que depende de SimulacaoResult
    if (SimulacaoResult && Object.keys(SimulacaoResult).length > 0) {
      // make a post request to the API with the data

      // Retrieve existing sessionStorage data
      const existingSimulacao = sessionStorage.getItem("simulacao");

      if (existingSimulacao) {
        // Parse existing data
        const simulacao = JSON.parse(existingSimulacao);

        // Update or add the new data
        simulacao[activeSimulado.tipo] = SimulacaoResult;

        // Save the updated data back to sessionStorage
        sessionStorage.setItem("simulacao", JSON.stringify(simulacao));
      } else {
        // If sessionStorage data doesn't exist, create a new object with the new data
        const simulacao = {
          [activeSimulado.tipo]: SimulacaoResult,
        };

        // Save the new data to sessionStorage
        sessionStorage.setItem("simulacao", JSON.stringify(simulacao));
      }
      setLoading(false);

      // Close the modal
      setIsModalVisible(false);
      onRequestClose();

      // open a new page with the {tipo}-sim/uuid
      // check if the uuid exists and simulacaoresult
      if (dataRecieved.uuid && SimulacaoResult) {
        window.open(
          `${window.location.origin}/${slug}/sim/${dataRecieved.uuid}`,
          "_blank"
        );
        // this is not working on the browser, make a button to open the new page
        setShowButton(true);
      } else {
        setLoading(false);
        message.error("Não foi possivel fazer a simulação");
      }
    }

    // Restante da lógica aqui
  }, [SimulacaoResult, activeSimulado]);
  const onChange = ({ date, dateString }: any) => {
    dataRecieved["data_nasc_2"] = dateString; // Atualize para a representação de string da data
  };

  useEffect(() => {
    if (
      (dataRecieved["titulares"] && dataRecieved["titulares"] === "2") ||
      (dataRecieved["numberOfInsurances"] &&
        dataRecieved["numberOfInsurances"] === "2")
    ) {
      setExtraTrue(true);
    } else {
      setExtraTrue(false);
    }
  }, [forceUpdate]);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorTextLightSolid: "#0000000",
            colorPrimaryBg: "#00b96b",
            colorBorder: "#e5e7eb",
          },
        }}
      >
        <Modal
          title="Nova Simulação"
          open={isOpen} // Changed from "open" to "visible"
          onCancel={onRequestClose}
          okButtonProps={{ className: "bg-blue-500 text-white" }}
          width={800}
          className="bg-transparent"
        >
          {/* show buttons per sim but only if they are active */}
          <div className="flex flex-col">
            {simuladores.map((simulado, index) => (
              <React.Fragment key={index}>
                {simulado.active && (
                  <button
                    className={`flex items-center justify-center h-10 rounded-lg border-2 border-gray-300 mt-2 ${
                      simulado.active ? "bg-gray-100" : ""
                    }`}
                    onClick={() => handleSimuladoClick(simulado)}
                  >
                    {simulado.name}
                  </button>
                )}
              </React.Fragment>
            ))}
          </div>
        </Modal>

        {/* Inner Modal for data entry */}
        <Modal
          title="Preencher Dados em falta"
          open={isModalVisible}
          onCancel={handleInnerModalCancel}
          okButtonProps={{ className: "bg-blue-500 text-white" }}
          width={600}
          className="bg-transparent"
          onOk={handleOkClickOnSim}
        >
          {/* if loading show loading screen else show the rest */}
          {loading ? (
            <div className="flex items-center justify-center h-32">
              {/* if page not loading then show button to open new page */}
              {showButton && (
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/${slug}/sim/${dataRecieved.uuid}`,
                      "_blank"
                    );
                  }}
                >
                  Abrir Simulação
                </button>
              )}
              <Spin size="large" />
            </div>
          ) : (
            <div className="flex flex-col">
              {Object.entries(dataset).map(
                ([key, campo]) =>
                  campo &&
                  (campo.value === "" || campo.value === null ||  campo.value === undefined) && (
                    <React.Fragment key={key}>
                      {campo.type === "text" ? (
                        <>
                          <p className="text-sm mt-2">{campo.name}</p>
                          <input
                            type="text"
                            placeholder={campo.name}
                            className="h-10 rounded-lg border-2 border-gray-300 px-2"
                            value={dataRecieved[key]}
                            onChange={(e) => {
                              dataRecieved[key] = e.target.value;
                            }}
                          />
                        </>
                      ) : campo.type === "date" ? (
                        <>
                          <p className="text-sm mt-2">{campo.name}</p>
                          <DatePicker
                            className="h-10 rounded-lg border-2 border-gray-300 px-2"
                            value={
                              dataRecieved[key]
                                ? dayjs(dataRecieved[key])
                                : null
                            }
                            onChange={(date, dateString) => {
                              dataRecieved[key] = dateString; // Atualize para a representação de string da data
                              setForceUpdate((prev) => prev + 1); // Increment the value to trigger re-render
                            }}
                            disabledDate={(current) => {
                              // Não pode selecionar dias antes da data mínima e após a data máxima
                              return (
                                (campo.min &&
                                  current &&
                                  current < dayjs(campo.min).endOf("day")) ||
                                (campo.max &&
                                  current &&
                                  current > dayjs(campo.max).endOf("day"))
                              );
                            }}
                          />
                        </>
                      ) : campo.type === "number" ? (
                        // must change the campo.value onChange event

                        <>
                          <p className="text-sm mt-2">{campo.name}</p>
                          <input
                            type="number"
                            placeholder={campo.name}
                            className="h-10 rounded-lg border-2 border-gray-300 px-2"
                            value={dataRecieved[key]}
                            onChange={(e) => {
                              dataRecieved[key] = e.target.value;
                            }}
                          />
                        </>
                      ) : campo.type === "select" ? (
                        // if select as min and max then select options must be betwen min and max, if it doesnt then must have options, if options show them
                        <>
                          <p className="text-sm mt-2">{campo.name}</p>
                          <select
                            className="h-10 rounded-lg border-2 border-gray-300 px-2"
                            value={dataRecieved[key]}
                            onChange={(e) => {
                              dataRecieved[key] = e.target.value;
                              setForceUpdate((prev) => prev + 1); // Increment the value to trigger re-render
                            }}
                          >
                            {campo.options
                              ? // Display options if they exist, options {key:{value,text}}
                                Object.values(campo.options).map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </option>
                                ))
                              : // Display a range of numbers between min and max
                                Array.from(
                                  {
                                    length:
                                      parseInt(campo.max) -
                                      parseInt(campo.min) +
                                      1,
                                  },
                                  (_, index) => parseInt(campo.min) + index
                                ).map((number) => (
                                  <option key={number} value={number}>
                                    {number}
                                  </option>
                                ))}
                          </select>
                        </>
                      ) : campo.type === "extra" ? (
                        <div className="mb-4">
                          <label
                            htmlFor="risks"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Riscos
                          </label>
                          <CheckboxList
                            checkboxes={checkboxes}
                            onCheckboxChange={handleCheckboxChange as any}
                            onSelectChange={handleSelectChange as any}
                            formData={formData}
                          />
                        </div>
                      ) : campo.type === "message" ? (
                        <>
                          <p
                            className="text-sm mt-2"
                            style={{ color: campo.color }}
                          >
                            {campo.aviso}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="text-sm mt-2">{campo.name}</p>
                          <input
                            type="text"
                            placeholder={campo.name}
                            className="h-10 rounded-lg border-2 border-gray-300 px-2"
                            value={dataRecieved[key]}
                            onChange={(e) => {
                              dataRecieved[key] = e.target.value;
                            }}
                          />
                        </>
                      )}
                    </React.Fragment>
                  )
              )}
              {extraTrue && (
                <>
                  <p className="text-sm mt-2">
                    Data de nascimento do 2º titular
                  </p>
                  <DatePicker
                    className="h-10 rounded-lg border-2 border-gray-300 px-2"
                    onChange={onChange}
                    disabledDate={(current) => {
                      if (dataset["titulares"]) {
                        return (
                          (dataset["data_nasc_1"].min &&
                            current &&
                            current <
                              dayjs(dataset["data_nasc_1"].min).endOf("day")) ||
                          (dataset["data_nasc_1"].max &&
                            current &&
                            current >
                              dayjs(dataset["data_nasc_1"].max).endOf("day"))
                        );
                      } else if (dataset["numberOfInsurances"]) {
                        return (
                          (dataset["birthDay1Insurance"].min &&
                            current &&
                            current <
                              dayjs(dataset["birthDay1Insurance"].min).endOf(
                                "day"
                              )) ||
                          (dataset["birthDay1Insurance"].max &&
                            current &&
                            current >
                              dayjs(dataset["birthDay1Insurance"].max).endOf(
                                "day"
                              ))
                        );
                      }
                    }}
                    
                  />
                </>
              )}
              {/* if simuladortipo.active is vida-pleno then show checboxed at the end */}
            </div>
          )}
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default SimuladoModal;
