import api from "./api";

export async function GeralSubmitToBackEnd(FormDatas, user) {
  if (user.length > 1) {
    user = user[Math.floor(Math.random() * user.length)];
  } else {
    user = user[0];
  }
  const formDataJson = {};
  FormDatas.forEach((value, key) => {
    formDataJson[key] = value;
  });

  let dados = JSON.parse(formDataJson.dados_simulacao);
  // add user, email and phone to dados
  dados.nome = formDataJson.nome;
  dados.email = formDataJson.email;
  dados.phone = formDataJson.phone;

  // Parse JSON strings in formDataJson
  formDataJson.resultado_simulacao = JSON.parse(
    formDataJson.resultado_simulacao
  );
  formDataJson.dados_simulacao = dados;
  formDataJson.estado = "Entregue";
  formDataJson.user = user;

  // Create the data object with parsed JSON-formatted FormData

  const tipo = await GetDataBeforeCreate(
    JSON.stringify({
      "tipo-simulacoes": JSON.parse(formDataJson.tipo_simulacao),
    })
  );

  formDataJson.tipo_simulacao = tipo;

  // set tipo_simulacao
  const dataObject = {
    data: formDataJson,
  };

  try {
    const response = await api.post("/simulacoes", dataObject);

    const uuid_simulador = response?.data?.data?.attributes?.uuid;

    if (response && response.status === 200 && uuid_simulador != null) {
      // email
      let emailTemplate = "";
      if (tipo.attributes?.simulador === "Vida Habitação") {
        emailTemplate = `<!DOCTYPE html>
      <html lang="pt">
      <head>
        <meta charset="UTF-8">
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }
          h1 {
            font-size: 24px;
            font-weight: bold;
            text-align: center;
      
            margin-top: 20px;
          }
          h2 {
            font-weight: bold;
            text-align: center;
            margin-top: 20px;
          }
          .info-section {
            text-align: left;
            margin: 20px;
          }
          .info-section p {
            margin: 5px 0;
          }
          table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
            border-radius: 10px; /* Bordas arredondadas */
            overflow: hidden; /* Evita que as bordas arredondadas sejam afetadas pelo conteúdo */
            background-color: #fff; /* Fundo branco da tabela */
          }
          th, td {
            padding: 10px;
            text-align: center;
            border: 1px solid #fff; /* Bordas brancas */
          }
          th {
            background-color: #007bff;
            color: #fff;
          }
          tr:nth-child(even) {
            background-color: #f2f2f2;
          }
          p.total {
            text-align: right;
            font-weight: bold;
            margin-top: 10px;
            margin-right: 20px;
          }
          .table{
              font-size: 12px;
              color:black;
          }
          .pad{
            margin:20px;
          }
        </style>
      </head>
      <body> 
        <img src="https://strapi.realbusinesscenter.wt.pt/uploads/logo_fw_62b6294912.png" alt="Real vida" width="200" height="auto" style="display:block;margin:auto;">
        <h1>${tipo.attributes?.simulador}</h1>
        <div class="info-section">
          <h2>Informações da lead</h2>
          <p><strong>Id da lead:</strong> ${uuid_simulador} </p>
          <p><strong>Capital em dívida:</strong> ${formatCurrency(
            formDataJson?.dados_simulacao?.capital
          )}</p>
          <p><strong>Número de Titulares:</strong> ${
            formDataJson?.dados_simulacao?.titulares
          } </p>
        </div>
        <div class="info-section">
          <h2>Informações do Cliente</h2>
          <p><strong>Nome do Cliente:</strong> ${formDataJson?.nome} </p>
          <p><strong>Data de Nascimento:</strong> ${
            formDataJson?.dados_simulacao?.data_nasc_1
          } </p>
          <p><strong>Data de Início de Contrato:</strong> ${
            formDataJson?.dados_simulacao?.data_inicio_contrato
          } </p>
          <p><strong>Prazo do contrato:</strong> ${
            formDataJson?.resultado_simulacao?.prazo
          } anos </p>
        </div>
        <div id='print'>
        <div class="info-section">
          <h2>Entregue ao Mediador</h2>
          <p><strong>Nome do Mediador:</strong> ${user?.username} </p>
          <p><strong>Email do Mediador:</strong> ${user?.email} </p>
          <p><strong>Telemóvel do Mediador:</strong> ${user?.phone} </p>
          <p><strong>Equipa do Mediador:</strong> ${
            user?.equipa?.nome_equipa
          } </p>
        </div>
        <h2>Resultado da Simulação</h2> 
        <div class="pad">
          <div class="tables-container">
            <table class="pad" >
              <thead>
                <tr>
                  <th>Original</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                  <strong>V. Fracionado:</strong> ${
                    formDataJson?.resultado_simulacao?.original?.v_fraccionado
                  }€<br>
                  <strong> Tipo de Fraccionamento:</strong> ${
                    formDataJson?.resultado_simulacao?.original?.fraccionamento
                  } <br>
                  <strong>V. Anual:</strong> ${
                    formDataJson?.resultado_simulacao?.original?.v_anual
                  }€ <br>
                  <strong>Poupança Anual:</strong> ${
                    formDataJson?.resultado_simulacao?.original?.p_anual
                  }€ <br>
                  <tr>
        <td colspan="2"><strong>Morte</strong></td>
      </tr>
      <tr>
        <td colspan="2">✔</td>
      </tr>
      <tr>
        <td colspan="2"><strong>Invalidez Absoluta e Definitiva</strong></td>
      </tr>
      <tr>
        <td colspan="2">✔</td>
      </tr>
      <tr>
        <td colspan="2"><strong>Morte por Acidente (Oferta)</strong></td>
      </tr>
      <tr>
        <td colspan="2">✔</td>
      </tr>
      <tr>
        <td colspan="2"><strong>Filhos Menores a Cargo (Oferta)</strong></td>
      </tr>
      <tr>
        <td colspan="2">✔</td>
      </tr>
                  </td>
                </tr>
              </tbody>
            </table>
      
            <table class="pad">
              <thead>
                <tr>
                  <th>Sugerido</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                <td>
                <strong>V. Fracionado:</strong> ${
                  formDataJson?.resultado_simulacao?.sugerido?.v_fraccionado
                }€ <br>
                <strong>Fraccionamento:</strong> ${
                  formDataJson?.resultado_simulacao?.sugerido?.fraccionamento
                } <br>
                <strong>V. Anual:</strong> ${
                  formDataJson?.resultado_simulacao?.sugerido?.v_anual
                }€ <br>
                <strong>Poupança Anual:</strong> ${
                  formDataJson?.resultado_simulacao?.sugerido?.p_anual
                }€ <br>
                <strong>Cobertura:</strong> ${
                  formDataJson?.resultado_simulacao?.sugerido?.cobertura
                } <br>
              </td>
              <tr>
        <td colspan="2"><strong>Morte</strong></td>
      </tr>
      <tr>
        <td colspan="2">✔</td>
      </tr>
      <tr>
        <td colspan="2"><strong>Invalidez Absoluta e Definitiva</strong></td>
      </tr>
      <tr>
        <td colspan="2">✔</td>
      </tr>
      <tr>
        <td colspan="2"><strong>Invalidez Total e Permanente 60%</strong></td>
      </tr>
      <tr>
        <td colspan="2">✔</td>
      </tr>
      <tr>
        <td colspan="2"><strong>Morte por Acidente (Oferta)</strong></td>
      </tr>
      <tr>
        <td colspan="2">✔</td>
      </tr>
      <tr>
        <td colspan="2"><strong>Filhos Menores a Cargo (Oferta)</strong></td>
      </tr>
      <tr>
        <td colspan="2">✔</td>
      </tr>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
      </body>
      </html>
      `;
      } else if (tipo.attributes?.simulador === "Vida Futura") {
        emailTemplate = `<!DOCTYPE html>
      <html lang="pt">
      <head>
        <meta charset="UTF-8">
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }
          h1 {
            font-size: 24px;
            font-weight: bold;
            text-align: center;
      
            margin-top: 20px;
          }
          h2 {
            font-weight: bold;
            text-align: center;
            margin-top: 20px;
          }
          .info-section {
            text-align: left;
            margin: 20px;
          }
          .info-section p {
            margin: 5px 0;
          }
          table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
            border-radius: 10px; /* Bordas arredondadas */
            overflow: hidden; /* Evita que as bordas arredondadas sejam afetadas pelo conteúdo */
            background-color: #fff; /* Fundo branco da tabela */
          }
          th, td {
            padding: 10px;
            text-align: center;
            border: 1px solid #fff; /* Bordas brancas */
          }
          th {
            background-color: #007bff;
            color: #fff;
          }
          tr:nth-child(even) {
            background-color: #f2f2f2;
          }
          p.total {
            text-align: right;
            font-weight: bold;
            margin-top: 10px;
            margin-right: 20px;
          }
          .table{
              font-size: 12px;
              color:black;
          }
          .pad{
            margin:20px;
          }
        </style>
      </head>
      <body> 
        <img src="https://strapi.realbusinesscenter.wt.pt/uploads/logo_fw_62b6294912.png" alt="Real vida" width="200" height="auto" style="display:block;margin:auto;">
        <h1>Simulação de Vida</h1>
        <div class="info-section">
          <h2>Informações da lead</h2>
          <p><strong>Id da lead:</strong> ${uuid_simulador} </p>
        </div>
        <div class="info-section">
          <h2>Informações do Cliente</h2>
          <p><strong>Nome do Cliente:</strong> ${
            formDataJson.dados_simulacao.name
          } </p>
          <p><strong>Data de Nascimento:</strong> ${
            formDataJson.dados_simulacao.birthday
          } </p>
          <p><strong>Data de Início de Contrato:</strong> ${
            formDataJson.dados_simulacao.contract
          } </p>
          <p><strong>Valor Simulado:</strong> ${formatCurrencyAward(
            formDataJson.dados_simulacao?.award
          )} </p>
          <p><strong>Duração do Contrato:</strong> ${
            formDataJson.dados_simulacao?.duration
          } anos </p>
        </div>

        <div class="info-section">
          <h2>Entregue ao Mediador</h2>
          <p><strong>Nome do Mediador:</strong> ${user?.username} </p>
          <p><strong>Email do Mediador:</strong> ${user?.email} </p>
          <p><strong>Telemóvel do Mediador:</strong> ${user?.phone} </p>
          <p><strong>Equipa do Mediador:</strong> ${
            user?.equipa?.nome_equipa
          } </p>
        </div>

      
        <h2>Resultado da Simulação</h2>
        <div class="pad">
        <table>
          <thead>
            <tr>
              <th>Ano</th>
              <th>Prémio Total Mensal</th>
              <th>Prémio Total Anual</th>
              <th>Capital de Risco</th>
              <th>Valor* em Caso de Vida</th>
              <th>Valor* em Caso de Morte</th>
            </tr>
          </thead>
          <tbody class="table">
            ${formDataJson.resultado_simulacao.result
              .map(
                (item) => `
            <tr>
              <td>${item.yearK}</td>
              <td>${formatCurrency(item.prto_Monthly)}</td>
              <td>${formatCurrency(item.prto)}</td>
              <td>${formatCurrency(item.capital)}</td>
              <td>${formatCurrency(item.rgpf)}</td>
              <td>${formatCurrency(item.rgpf_Death)}</td>
            </tr>
            `
              )
              .join("")}
          </tbody>
        </table>
      </div>
      </body>
      </html>  
  `;
      }

      const emailData = new FormData();

      emailData.append("fromEmail", "Simulacao@mirasity.pt");
      emailData.append("fromName", "Simulação Vida Futura");
      emailData.append(
        "replyTo",
        `${formDataJson?.user} <${formDataJson?.email}>`
      );
      emailData.append("to", "filipe.braga@realvidaseguros.pt");
      emailData.append("toName", "Filipe Braga");
      emailData.append(
        "subject",
        `Simulação de ${formDataJson?.name} | ${uuid_simulador}`
      );
      emailData.append("html", emailTemplate);

      try {
        const emailResponse = await api.post("/email", emailData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (error) {
        console.error(error);
      }

      return uuid_simulador;
    }
  } catch (error) {
    console.error(error);
  }
}

// beore create make get post to get tipo_simulacao
const GetDataBeforeCreate = async (getData) => {
  // getData is an object where it comes the data base table name and the values inside it like {data_base_table:{values}}
  // data_base_table: tipo_simulacao
  // values: {tipo_simulacao: "Vida Futura"}

  const data = JSON.parse(getData);
  const data_base_table = Object.keys(data)[0];
  const values = data[data_base_table];
  const values_keys = Object.keys(values);
  const values_values = Object.values(values);
  const query = values_keys.map((key, index) => {
    return `filters[${values_keys}][$eq]=${values_values[index]}`;
  });
  const query_string = query.join("&");
  const response = await api.get(`/${data_base_table}?${query_string}`);

  return response.data?.data[0];
};

const formatCurrency = (value) => {
  return `€ ${value
    .toFixed(2)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
};
const formatCurrencyAward = (value) => {
  return `${value
    .toFixed(2)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`;
};
function formatDateToDdMmYyyy(date) {
  const formattedDate = new Date(date);
  const year = formattedDate.getFullYear();
  const month = String(formattedDate.getMonth() + 1).padStart(2, "0"); // Adiciona um para o mês (0 a 11)
  const day = String(formattedDate.getDate()).padStart(2, "0"); // Adiciona zero à esquerda para o dia
  return `${day}/${month}/${year}`;
}

export const create_sim = async (dataObject) => {
  try {
    const dataObject2 = {
      data: dataObject,
    };
    const response = await api.post("/sale-sims", dataObject2);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
