import React from "react";

interface RelatorioData {
  id: number;
  attributes: {
    simulacao: any;
    estado: any;
    Fechado: boolean;
    reagendado: boolean;
    outros: string;
  };
}

interface Props {
  data: RelatorioData;
}

const PrintableReport: React.FC<Props> = ({ data }) => {
  const formatDate = (date: string) => {
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    return formattedDate;
  };
  const centerBoxStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh", // Adjust the height as needed
  };

  const reportContainerStyle: React.CSSProperties = {
    maxWidth: "600px",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  };

  const reportTitleStyle: React.CSSProperties = {
    fontSize: "24px",
    marginBottom: "20px",
    fontWeight: "bold", // Add bold styling to the title
    textAlign: "center", // Align the title to the left
  };

  const reportInfoStyle: React.CSSProperties = {
    margin: "0",
    textAlign: "left", // Align the content to the left
  };

  const reportObservationsStyle: React.CSSProperties = {
    marginTop: "10px",
    textAlign: "left", // Align the content to the left
  };

  return (
    <div style={centerBoxStyle}>
      <div style={{ ...reportContainerStyle, textAlign: "left" }}>
        <h1 style={reportTitleStyle}>Relatório - </h1>
        <div style={reportInfoStyle}>
          <p>
            <strong>Nome do tomador de seguro:</strong>{" "}
            {data?.attributes?.simulacao?.data?.attributes?.nome}
          </p>
          <p>
            <strong>Identificador:</strong>{" "}
            {data?.attributes?.simulacao?.data?.attributes?.uuid_simulador}
          </p>
        </div>

        <p>
          <strong>Data de Receção:</strong>{" "}
          {formatDate(data?.attributes?.simulacao?.data?.attributes?.createdAt)}
        </p>
        <p>
          <strong>Número do Agente:</strong>{" "}
          {data?.attributes?.simulacao?.data?.attributes?.user?.data?.id}
        </p>
        <p>
          <strong>Nome do Agente:</strong>{" "}
          {
            data?.attributes?.simulacao?.data?.attributes?.user?.data
              ?.attributes?.username
          }
        </p>

        <p>
          <strong>Resultado do Tratamento:</strong>{" "}
          {data?.attributes?.simulacao?.data?.attributes?.estado}
        </p>
        <p>
          <strong>Resultado do Relatório:</strong> {data?.attributes?.estado}
        </p>

        {data?.attributes?.simulacao?.data?.attributes?.estado ===
          "Fechada" && (
          <p>
            <strong>Número das Apólices:</strong>{" "}
            {/* Replace with the actual number of policies */}
          </p>
        )}

        {data?.attributes?.simulacao?.data?.attributes?.estado ===
          "Reagendada" &&
          data?.attributes.estado === "Reagendada" && (
            <p>
              <strong>Data Hora de Reagendamento:</strong>{" "}
              {data.attributes.reagendado}
            </p>
          )}
      </div>
    </div>
  );
};

export default PrintableReport;
