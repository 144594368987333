import React, { useEffect, useState } from "react";
import backg from "../../../../../assets/img/bkground.png";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Spin, message } from "antd";
import { RiSave2Line, RiArrowGoBackLine } from "react-icons/ri";
import { useAuthContext } from "../../../../../context/AuthContext";
import { create_sim } from "../../../../../api/sendForm";
import { getResultById3 } from "../../../../../api/getSimInfo";

interface ResultItem {
  age: number;
  yearK: number;
  capital: number;
  papr: number;
  pacr: number;
  cmp: number;
  prto: number;
  prtoAgv: number;
  prto_Monthly: number;
  prtoAgv_Monthly: number;
  pac: number;
  papf: number;
  pmaf: number;
  rgpf: number;
  rgpf_Death: number;
}

interface ResultadoSimulacao {
  name: string;
  birthday: string;
  contract: string;
  duration: number;
  radAwardCapital: string;
  award: number;
  capital: number;
  payment: number;
  percentage: number;
  numberIterations: number;
  age: number;
  guaranteedRate: number;
  indexing: number;
  result: ResultItem[];
  capital_Ma: number;
  capital_Mac: number;
}
interface DadosSimulacao {
  birthday: string;
  contract: string;
  duration: string;
  radAwardCapital: string;
  award: string;
  nome: string;
  email: string;
  phone: string;
}

interface Data {
  nome: string;
  email: string;
  phone: string;
  resultado_simulacao: ResultadoSimulacao;
  dados_simulacao: DadosSimulacao;
  createdAt: string;
  updatedAt: string;
  id: string;
}

const formatCurrency = (value: number) => {
  return `€ ${value
    .toFixed(2)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
};

const AwardformatCurrency = (value: number) => {
  return `${value
    .toFixed(2)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`;
};

// date comes as 2023-09-11T23:00:00.000Z change to dd/mm/yyyy
const formatDate = (date: string) => {
  const dateSplit = date.split("T");
  const dateSplit2 = dateSplit[0].split("-");
  const day = dateSplit2[2];
  const month = dateSplit2[1];
  const year = dateSplit2[0];
  return `${day}/${month}/${year}`;
};

// url is sent with id, get id from props
const SimPageResultadosVidaFutura: React.FC = () => {
  const { id } = useParams();
  // get the simId from the url
  const { simId } = useParams();

  const [data, setData] = React.useState<any>({ result: [] });

  const [loading, setLoading] = React.useState(false);

  // useauth
  const { triggerUpdate} = useAuthContext();

  const backgroundStyle = {
    backgroundImage: `url(${backg})`, // Use a variável backg para o caminho da imagem
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  const navigate = useNavigate();

  //  check if current url has relatorio, if so then delete all session storage
  if (window.location.href.includes("relatorio")) {
    sessionStorage.removeItem("simulacao");
  }

  const [simulations, setSimulations] = useState("undefined");


  const checkdata = sessionStorage.getItem("simulacao")
    ? JSON.parse(sessionStorage.getItem("simulacao") || "{}")
    : {};

  // if simId is set then should go to backend and get the data from the daatabase if it is not set then should look for the sessionStorage
  useEffect(() => {
    const fetchDadosSeguro = async () => {
      getResultById3(simId)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          // if error then show the message
          message.error("Ocorreu um erro");
        });
    };
   
    if (simId) {
      fetchDadosSeguro();
    }
  }, []);

  // if it is not set then should go use the checkData["vida-futura to set data"]
  useEffect(() => {
    // check if there is a simId
    if (!simId) {
      // get the data from the backend
      setData(checkdata["vida-futura"]);
    }
  }, []);

  // loading
  if (!data || !data.resultado_simulacao || !data.resultado_simulacao.result) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-real-color text-white font-bold py-2 px-4 rounded-full">
          A carregar...
        </div>
      </div>
    );
  }

  const SaveSimulation = () => {
    // send to backend data to save it
    // send data to backend
    setLoading(true);

    // just send the data object, it is already made for the database
    create_sim(data)
      .then((res) => {
        setLoading(false);
        // go back to the lead
        message.success(
          "Simulação guardada com sucesso, a regressar á lead de origem"
        );
        // timout for 3 seconds then go backk to vida futura and reload
        setTimeout(() => {
          triggerUpdate();
          // fechar esta janela
          window.close();
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Ocorreu um erro ao guardar a simulação");
      });
  };

  return (
    <div className="w-full h-screen bg-gray-100 flex items-center justify-center">
      <div
        className="text-white p-4 font-nexa w-full h-full overflow-auto bg-bg-color"
        style={backgroundStyle}
      >
        <h1 className="text-2xl font-bold">
          Simulação Real Seguro Vida Futura
        </h1>
        {
          !simId && (
            <div className="flex justify-end">
              <button
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                onClick={SaveSimulation}
              >
                <RiSave2Line className="inline-block mr-2" />
                Guardar simulação
              </button>
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded ml-2"
                onClick={() => navigate(`/vida-futura/${id}`)}
              >
                <RiArrowGoBackLine className="inline-block mr-2" />
                Voltar
              </button>
            </div>
          )
          //  else show new voltar button
        }
        {simId && (
          <div className="flex justify-end">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded ml-2"
              // onclick navigate history back?
              onClick={() => window.history.back()}
            >
              <RiArrowGoBackLine className="inline-block mr-2" />
              Voltar
            </button>
          </div>
        )}

        {/* check loading */}
        {loading && (
          <div className="bg-gray-200 min-h-screen flex items-center justify-center p-8">
          <div className="max-w-screen-lg w-full text-center">
            <Spin size="large" />
          </div>
        </div>
        )}

        <div className="p-6">
          <div className="flex flex-row p-1 m-0 w-full">
            <div className="w-1/4 mr-6">
              <p className="font-semibold text-right">
                Nome Tomador do Seguro:
              </p>
            </div>
            <div className="border p-1 w-1/3 text-center rounded-2xl">
              {data?.nome}
            </div>
          </div>

          <div className="flex flex-row p-1 m-0 w-full">
            <div className="w-1/4 mr-6">
              <p className="font-semibold text-right">
                Email Tomador do Seguro:
              </p>
            </div>
            <div className="border p-1 w-1/3 text-center rounded-2xl">
              {data?.email}
            </div>
          </div>

          <div className="flex flex-row p-1 m-0 w-full">
            <div className="w-1/4 mr-6">
              <p className="font-semibold text-right">
                Número de telefone do Tomador do Seguro:
              </p>
            </div>
            <div className="border p-1 w-1/3 text-center rounded-2xl">
              {data?.phone}
            </div>
          </div>

          <div className="flex flex-row p-1 m-0 w-full">
            <div className="w-1/4 mr-6">
              <p className="font-semibold text-right">
                Data Nascimento Pessoa Segura:
              </p>
            </div>
            <div className="w-1/3 text-center">
              <div className="w-1/2 text-center border rounded-2xl p-1 mx-auto">
                {data?.dados_simulacao.birthday}
              </div>
            </div>
          </div>

          <div className="flex flex-row p-1 m-0 w-full">
            <div className="w-1/4 mr-6">
              <p className="font-semibold text-right">
                Data de inicio de contrato:
              </p>{" "}
            </div>
            <div className="w-1/3 text-center">
              <div className="w-1/2 text-center border rounded-2xl p-1 mx-auto">
                {formatDate(data?.dados_simulacao?.contract)}
              </div>
            </div>

            <div className="w-1/4 mr-4">
              <p className="font-semibold text-right">Prémio Mensal:</p>
            </div>
            <div className="border p-1 w-[150px] text-center rounded-2xl">
              {AwardformatCurrency(data?.resultado_simulacao.award)}
            </div>
          </div>
        </div>

        <div className="p-2 m-12 border rounded-3xl mb-4">
          <div className="pt-4 pb-4 flex justify-center">
            <div className="risks">
              <table className="table-auto">
                <tbody>
                  <tr>
                    <td className="px-4 py-2">Coberturas</td>
                    <td className="px-4 py-2">Morte</td>
                    <td className="px-4 py-2">
                      {formatCurrency(data?.resultado_simulacao.capital)}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="px-4 py-2">
                      Invalidez Absoluta e Definitiva
                    </td>
                    <td className="px-4 py-2">
                      {formatCurrency(data?.resultado_simulacao.capital)}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="px-4 py-2">Morte por Acidente</td>
                    <td className="px-4 py-2">
                      {formatCurrency(data?.resultado_simulacao.capital_Ma)}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="px-4 py-2">
                      Morte por Acidente de Circulação
                    </td>
                    <td className="px-4 py-2">
                      {formatCurrency(data?.resultado_simulacao.capital_Mac)}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="px-4 py-2">Médico Online</td>
                    <td className="px-4 py-2">Oferta</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="px-4 py-2">
                      Aconselhamento Médico Telefónico
                    </td>
                    <td className="px-4 py-2">Oferta</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="px-4 py-2">Médico ao Domicilio</td>
                    <td className="px-4 py-2">Oferta</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="p-2 m-12 rounded-3xl flex justify-center">
          <table className="border w-full">
            <thead>
              <tr className=" text-center">
                <th className="py-2 border" style={{ userSelect: "none" }}>
                  Ano
                </th>
                <th className="py-2 border" style={{ userSelect: "none" }}>
                  Prémio Total Mensal
                </th>
                <th className="py-2 border" style={{ userSelect: "none" }}>
                  Premio Total Anual
                </th>
                <th className="py-2 border" style={{ userSelect: "none" }}>
                  Capital de Risco
                </th>
                <th className="py-2 border" style={{ userSelect: "none" }}>
                  Valor* em Caso de Vida
                </th>
                <th className="py-2 border" style={{ userSelect: "none" }}>
                  Valor* em Caso de Morte
                </th>
              </tr>
            </thead>
            <tbody>
              {data.resultado_simulacao.result.map((item: any, index: any) => (
                <tr key={index} className="text-center">
                  <td className="border py-2">{item.yearK}</td>
                  <td className="border py-2">
                    {" "}
                    {formatCurrency(item.prto_Monthly)}
                  </td>
                  <td className="border py-2">{formatCurrency(item.prto)}</td>
                  <td className="border py-2">
                    {formatCurrency(item.capital)}
                  </td>
                  <td className="border py-2">{formatCurrency(item.rgpf)}</td>
                  <td className="border py-2">
                    {formatCurrency(item.rgpf_Death)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <label className="p-2 m-12 ">
          * Será deduzido o valor da retenção na fonte e, se aplicável, a
          penalização contratual definida.
        </label>
        <div className="p-2 m-12 rounded-3xl justify-center text-sm">
          <h2 className="text-lg font-semibold mb-2 text-base">Nota:</h2>
          <ul className="list-disc pl-5">
            <li className="mb-1">
              <span className="font-semibold">Valor em caso de Morte:</span>{" "}
              Corresponde ao saldo acumulado da componente financeira, acrescido
              do capital de risco.
            </li>
            <li className="mb-1">
              <span className="font-semibold">Valor em caso de Vida:</span>{" "}
              Corresponde ao saldo acumulado da componente financeira.
            </li>
            <li className="mb-1">
              Apenas é possível o resgate parcial a partir da 3ª anuidade, sendo
              possível o resgate total a qualquer momento.
            </li>
            <li>
              Em caso de resgate antecipado, será aplicada uma penalização no
              valor de 100% no 1º ano de vigência do contrato e de 75% no 2º ano
              de vigência do contrato.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SimPageResultadosVidaFutura;
