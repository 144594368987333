import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        // setIsAuthenticated(decodedToken.exp * 1000 > Date.now());
        // expira em 30 segundos para teste
        setIsAuthenticated(decodedToken.exp * 1000 > Date.now() + 30000);
        //  if is not valid remove the token right?
      } catch (error) {
        console.error("Error decoding token:", error);
        localStorage.removeItem("authToken");
      }
    }
    setIsLoading(false); // Set loading to false after check
  }, []);

  return { isAuthenticated, isLoading };
};
