import React, { useState, useEffect, ReactNode } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { message } from "antd";
import { API, BEARER } from "../../../api/Auth/constant";
import { getToken } from "../../../api/Auth/helpers";
import { useAuth } from "../../../api/Auth/useAuth";

interface AuthProviderProps {
  children: ReactNode;
}

interface UserData {
  id: number;
  username: string;
  email: string;
  confirmed: boolean;
  blocked: boolean;
  role: {
    id: number;
    name: string;
    description: string;
    type: string;
  };
  tipo: {
    level: any;
    id: number;
    nivel: string;
    cor: string;
  };
  phone: string;
  equipa: {
    id: number;
    nome_equipa: string;
    zona: {
      id: number;
      nome_zona: string;
    };
  };
}

interface EquipaData {
  id: number;
  attributes: {
    nome_equipa: string;
    user_permissions_user: {
      id: number;
      username: string;
    };
    zona: {
      id: number;
      nome_zona: string;
    };
  };
}

interface ZonaData {
  id: number;
  attributes: {
    nome_zona: string;
    user_permissions_user: {
      id: number;
      username: string;
    };
  };
}

interface ResultItem {
  age: number;
  yearK: number;
  capital: number;
  papr: number;
  pacr: number;
  cmp: number;
  prto: number;
  prtoAgv: number;
  prto_Monthly: number;
  prtoAgv_Monthly: number;
  pac: number;
  papf: number;
  pmaf: number;
  rgpf: number;
  rgpf_Death: number;
}

interface simuladorData {
  attributes: {
    nome: string;
    email: string;
    phone: string;
    data_entrega: string;
    resultado_simulacao: any;
    createdAt: string;
    updatedAt: string;
    uuid: string;
    user: any;
    estado: any;
    tipo_simulacao: any;
    dados_simulacao: any;
    reagendado: boolean;
    data_reagendamento: any;
    observacoes: observacoes[];
  };
}
interface RelatorioData {
  id: number;
  attributes: {
    simulacao: simuladorData;
    estado: any;
    Observacoes: string;
    Fechado: boolean;
    reagendado: boolean;
    outros: string;
    createdAt: any;
    deletedAt: string;
  };
}

// observacao interface -> user -> simuladorData -> texto

interface observacoes {
  id: number;
  attributes: {
    user: UserData;
    texto: string;
    simulacao: simuladorData;
  };
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [equipas, setEquipas] = useState<EquipaData[]>([]);
  const [zonas, setZonas] = useState<ZonaData[]>([]);
  const [simuladorData, setSimuladorData] = useState<simuladorData[]>([]);
  const [users, setUsers] = useState<UserData[]>([]);
  const [relatorios, setRelatorios] = useState<RelatorioData[]>([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [tipos, setTipos] = useState<any[]>([]);

  const { isAuthenticated } = useAuth();

  const triggerUpdate = () => {
    setUpdateTrigger((prev) => prev + 1);
  };

  // use effect to setupdateTrigger
  useEffect(() => {
    setIsLoading(true);
    if (userData) {
      // get all data again
      fetchSimuladorData();
      fetchUsers();
      fetchRelatorioData();
    }
    // reset update trigger
    setUpdateTrigger(0);
    setIsLoading(false);
  }, [updateTrigger]); // useEffect will run whenever updateTrigger changes

  const [isLoading, setIsLoading] = useState(false);
  const authToken = getToken();

  const fetchData = async (url: string, setData: React.Dispatch<any>) => {
    try {
      const response = await fetch(url, {
        headers: { Authorization: `${BEARER} ${authToken}` },
      });
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }
      const data = await response.json();
      setData(data.data || data);
    } catch (error) {
      console.error(error);
      message.error(
        `Error While Getting ${setData === setEquipas ? "Equipas" : "Zonas"}`
      );
    }
  };

  const fetchTipos = async () => {
    await fetchData(`${API}/tipos`, (tipos) => {
      // Ordena os tipos por 'level', do menor para o maior
      // console.log tipos -> attributes

      const ordenado = tipos.sort(
        (a: any, b: any) => a.attributes.level - b.attributes.level
      );

      const tiposRemodelados = ordenado.map(
        (tipo: { attributes: any }) => tipo.attributes
      );
      setTipos(tiposRemodelados);
    });
  };
  // Função para determinar as opções baseadas no level do tipo do usuário
  function determineOptions(userLevel: any) {
    if (userLevel <= 1) {
      // Usuários com level 0 ou 1 podem criar todos os tipos

      return tipos.map((tipo) => tipo.nivel);
    } else {
      // Usuários com level 2 ou 3 só podem criar tipos abaixo do seu próprio level
      return tipos
        .filter((tipo) => tipo.level > userLevel)
        .map((tipo) => tipo.nivel);
    }
  }

  const fetchLoggedInUser = async () => {
    setIsLoading(true);
    try {
      await fetchData(
        `${API}/users/me?populate[equipa][populate]=*&populate[zona][populate]=*&populate[tipo][populate]=*&pagination[limit]=500`,
        setUserData
      );
      await fetchData(`${API}/equipas?populate=*`, setEquipas);
      await fetchData(`${API}/zonas`, setZonas);
      // fetch tipo
      await fetchTipos();
    } catch (error) {
      console.error(error);
      message.error("Error While Getting Logged In User Details");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSimuladorData = async () => {
    setIsLoading(true);
    try {
      // working filter https://strapi.mirasity.pt/api/simulador-vidas?filters[user][id][$eq]=8&populate[user][populate][equipa][populate]=*

      let apiUrl = `${API}/simulacoes?`;

      if (userData?.tipo?.level === 3) {
        apiUrl += `filters[user][equipa][zona][users_permissions_user][id][$eq]=${userData?.id}&populate[user][populate][equipa][populate][zona][populate]=*&populate[tipo_simulacao]=*&populate[saleSim][populate][tipo_simulacao]=*&populate[observacoes]populate=*&pagination[limit]=500`;
      } else if (userData?.tipo?.level == 4) {
        apiUrl += `filters[user][equipa][users_permissions_user][id][$eq]=${userData?.id}&populate[user][populate][equipa][populate]=*&populate[tipo_simulacao]=*&populate[saleSim][populate][tipo_simulacao]=*&populate[observacoes]populate=*&pagination[limit]=500`;
      } else if (userData?.tipo?.level >= 5) {
        apiUrl += `filters[user][id][$eq]=${userData?.id}&populate[user][populate][equipa][populate]=*&populate[tipo_simulacao]=*&populate[saleSim][populate][tipo_simulacao]=*&populate[observacoes]populate=*&pagination[limit]=500`;
      } else if (userData?.tipo?.level <= 2) {
        apiUrl += `populate[user][populate][equipa][populate]=*&populate[tipo_simulacao]=*&populate[saleSim][populate][tipo_simulacao]=*&populate[observacoes]populate=*&pagination[limit]=500`;
      }
      const response = await fetch(`${apiUrl}`, {
        headers: { Authorization: `${BEARER} ${authToken}` },
      });

      if (!response.ok) {
        if (response.status === 500) {
          // No results found, so set SimuladorData to an empty array
          setSimuladorData([]);
        } else {
          throw new Error(`HTTP Error: ${response.status}`);
        }
      } else {
        const datas = await response.json();
        setSimuladorData(datas?.data);
      }
    } catch (error: any) {
      // Handle other errors˙
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  // working filter https://strapi.mirasity.pt/api/simulador-vidas?filters[user][id][$eq]=8&populate[user][populate][equipa][populate]=*

  // fetch my users
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      let apiUrl = `${API}/users?`;
      switch (userData?.tipo?.level) {
        case 0: // Nível mais alto (Distribuição)
          apiUrl += "populate[equipa][populate]=*&populate[tipo][populate]=*&sort=username:ASC&pagination[limit]=500";
          break;
        case 1: // Nível mais alto (Admin)
          apiUrl += "populate[equipa][populate]=*&populate[tipo][populate]=*&sort=username:ASC&pagination[limit]=500";
          break;
        case 2: // Nível intermediário (por exemplo, Diretor)
          apiUrl += "populate[equipa][populate]=*&populate[tipo][populate]=*&sort=username:ASC&pagination[limit]=500";
          break;
        case 3: // Nível intermediário (por exemplo, Diretor)
          apiUrl += `filters[equipa][zona][users_permissions_user][id][$eq]=${userData?.id}&populate[equipa][populate]=id,nome_equipa&populate[tipo][populate]=*&sort=username:ASC&pagination[limit]=500`;
          break;
        case 4: // Outro nível intermediário (por exemplo, Gestor)
          apiUrl += `filters[equipa][users_permissions_user][id][$eq]=${userData?.id}&populate[equipa][populate]=id,nome_equipa&populate[tipo][populate]=*&sort=username:ASC&pagination[limit]=500`;
          break;
        default: // Nível mais baixo ou outros casos (por exemplo, Agente)
          apiUrl += `filters[id][$eq]=${userData?.id}&populate[equipa][populate]=id,nome_equipa&populate[tipo][populate]=&sort=username:ASC&pagination[limit]=500`;
          break;
      }

      const response = await fetch(`${apiUrl}`, {
        headers: { Authorization: `${BEARER} ${authToken}` },
      });

      if (!response.ok) {
        if (response.status === 500) {
          // No results found, so set SimuladorData to an empty array
          setUsers([]);
        } else {
          throw new Error(`HTTP Error: ${response.status}`);
        }
      } else {
        const datas = await response.json();
        setUsers(datas);
      }
    } catch (error: any) {
      // Handle other errors˙
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRelatorioData = async () => {
    setIsLoading(true);
    try {
      // make it through the level

      let apiUrl = `${API}/relatorios?`;
      switch (userData?.tipo?.level) {
        case 0: // Nível mais alto (Distribuição)
          apiUrl +=
            "populate=simulacao&populate=simulacao.user&populate=simulacao.user.equipa&populate=simulacao.tipo_simulacao&populate=simulacao.observacoes&pagination[limit]=500";
          break;
        case 1: // Nível mais alto (Admin)
          apiUrl +=
            "populate=simulacao&populate=simulacao.user&populate=simulacao.user.equipa&populate=simulacao.tipo_simulacao&populate=simulacao.observacoes&pagination[limit]=500";
          break;
        case 2: // Nível intermediário (por exemplo, Diretor)
          apiUrl += "populate=simulacao&populate=simulacao.user&populate=simulacao.user.equipa&populate=simulacao.tipo_simulacao&populate=simulacao.observacoes&pagination[limit]=500";
          break;
        case 3: // Nível intermediário (por exemplo, Diretor)
          apiUrl += `filters[simulacao][user][equipa][zona][users_permissions_user][id][$eq]=${userData?.id}&populate=simulacao&populate=simulacao.user&populate=simulacao.user.equipa&populate=simulacao.user.equipa.zona&populate=simulacao.tipo_simulacao&populate=simulacao.observacoes&pagination[limit]=500`;
          break;
        case 4: // Outro nível intermediário (por exemplo, Gestor)
          apiUrl += `filters[simulacao][user][equipa][users_permissions_user][id][$eq]=${userData?.id}&populate=simulacao&populate=simulacao.user&populate=simulacao.user.equipa&populate=simulacao.tipo_simulacao&populate=simulacao.observacoes&pagination[limit]=500`;
          break;
        default: // Nível mais baixo ou outros casos (por exemplo, Agente)
          apiUrl += `filters[simulacao][user][id][$eq]=${userData?.id}&populate=simulacao&populate=simulacao.user&populate=simulacao.user.equipa&populate=simulacao.tipo_simulacao&populate=simulacao.observacoes&pagination[limit]=500`;
          break;
      }
      const response = await fetch(`${apiUrl}`, {
        // const response = await fetch(`${API}/relatorios?populate=*`, {
        headers: { Authorization: `${BEARER} ${authToken}` },
      });

      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }

      const datas = await response.json();
      // remove the relatorios that has deletedAt if person not admin
      if (userData?.tipo?.level > 1) {
        const filteredRelatorios = datas.data.filter(
          (relatorio: RelatorioData) => !relatorio.attributes.deletedAt
        );
        setRelatorios(filteredRelatorios);
        return;
      }
      setRelatorios(datas.data);
    } catch (error) {
      console.error(error);
      message.error("Error While Getting Relatorio Data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken && isAuthenticated) {
      fetchLoggedInUser();
    }
  }, [authToken, isAuthenticated]);

  useEffect(() => {
    // Após o usuário ter sido buscado, busque os dados do simulador
    if (userData) {
      fetchSimuladorData();

      // fetch users
      fetchUsers();

      // fetch relatorios
      fetchRelatorioData();

      if (userData && tipos.length > 0) {
        const userLevel = userData.tipo.level;
        const options = determineOptions(userLevel);
      }
    }
  }, [userData, tipos]);

  const handleUser = (user: UserData | null) => {
    setUserData(user);
  };

  return (
    <AuthContext.Provider
      value={{
        user: userData,
        setUser: handleUser,
        isLoading,
        equipas,
        zonas,
        simuladorData,
        users,
        relatorios,
        tipos,
        triggerUpdate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
