import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from "react-icons/fa"; // Import the calendar icon
import pt from "date-fns/locale/pt";
import RadioGroup from "../../RadioGroup";
import axios, { AxiosError } from "axios";
import { GeralSubmitToBackEnd } from "../../../../api/sendForm";
import { useAuthContext } from "../../../../context/AuthContext";
import { Spin, message } from "antd";
const token = "0YJMNzew238n1s5f+nR9eq0Hb8vmWurDnZwHkBbuYMOIXet250/kNctAV/OPRk7ru7tM6vU3m0sNZPmh5ZAGNmVPzo1tJlzWcuMbR8hpkm7Q+g7mx2SB5OMV4Vhi7ggTxN42w4zElQ3jVfTDgwWmuQ7czidRnM2zG4RHPvikf8eDr2w118xV4MJrjyzUbOEl2RMsmJsV6oNNVGT3iQ92lt0+sHWLvzqFfM0kquIumVuhkni8ddOcNL6+vyRB3WQtoQszoaeKaAaN4mrph5ZqwmM18feM+CP325ZonfdFfbh6gZDmDd6QDFy/obNClX/XftfTHbOL7ddgl4RVOhGWkzXKZ2WbFWal5WNGl6Td94VL4awD4B3SVfpav3BllaG0HGDplNT1W2DA410eoqdi8CbxvOcE5xUTUP0ALjgyeoWeAKOdioE6rrWJZcSFt+vu";

interface FormData {
  name: string;
  birthday: Date | null;
  contract: Date | null;
  duration: number | null;
  radAwardCapital: string | null;
  award: number | null;
}

interface UserData {
  id: number;
  username: string;
  email: string;
  confirmed: boolean;
  blocked: boolean;
  role: {
    id: number;
    name: string;
    description: string;
    type: string;
  };
  tipo: {
    id: number;
    nivel: string;
    cor: string;
  };
  phone: string;
  equipa: {
    id: number;
    nome_equipa: string;
    zona: {
      id: number;
      nome_zona: string;
    };
  };
}

const PageSimuladorVidaFutura: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    birthday: null,
    contract: null,
    duration: null,
    radAwardCapital: "mensal",
    award: null,
  });
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [result, setResult] = useState<any>(null);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const { users, simuladorData, isLoading } = useAuthContext();
  //make a variable to send available users
  const [availableUsers, setAvailableUsers] = useState<UserData[]>([]);
  //make a variable to send available users

  useEffect(() => {
    if (users && simuladorData) {
      // Supondo que 'level === 1' representa um tipo específico de agente
      const targetLevel = 1;

      // Criar um conjunto com os IDs dos usuários que têm leads
      const userIdsWithLeads = new Set(
        simuladorData.map((lead) => lead?.attributes?.user?.data?.id)
      );

      // Filtrar agentes disponíveis que têm o level alvo e não têm leads
      let agentesDisponiveis = users.filter(
        (user) =>
          user.tipo?.level === targetLevel && !userIdsWithLeads.has(user.id)
      );

      if (agentesDisponiveis.length === 0 && users.length > 0) {
        // Se não houver agentes disponíveis, selecionar um aleatoriamente de todos os agentes com o level alvo
        const agentesComLevelAlvo = users.filter(
          (user) => user.tipo?.level === targetLevel
        );
        const randomAgentIndex = Math.floor(
          Math.random() * agentesComLevelAlvo.length
        );
        agentesDisponiveis = [agentesComLevelAlvo[randomAgentIndex]];
      }

      setAvailableUsers(agentesDisponiveis);
    }
  }, [simuladorData, users]);

  // if is loading show loading page

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );
  }

  function transformData(data: any) {
    function formatDate(dateString: any) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }

    const transformedData = {
      name: data.name || "",
      birthday: data.birthday ? formatDate(data.birthday) : "", // Format as yyyy-MM-dd
      contract: data.contract ? formatDate(data.contract) : "", // Format as yyyy-MM-dd
      duration: data.duration ? String(data.duration) : "", // Convert to string
      radAwardCapital: data.radAwardCapital === "mensal" ? "1" : "2", // Convert to "1" or "0"
      award: data.award ? Number(data.award) : 0, // Convert to number
    };

    return transformedData;
  }
  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    try {
      const transformedData = transformData(formData);

      // use axios post with data as json and bearer token
      const response = await axios.post(
        "https://sims.realvidaseguros.pt/sims/vidafutura",
        transformedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Log the response data
      setResult(response.data);
      // send data to backend

      const formDataToSend2 = new FormData();
      // resultado simulacao
      formDataToSend2.append(
        "resultado_simulacao",
        JSON.stringify(response.data)
      );
      // dados do cliente nome email e telefone
      formDataToSend2.append("nome", formData.name);
      formDataToSend2.append("email", email);
      formDataToSend2.append("phone", phone);
      // tipo de simulacao has json strin
      formDataToSend2.append(
        "tipo_simulacao",
        JSON.stringify({
          simulador: "Vida Futura",
        })
      );
      // dados da simulacao
      formDataToSend2.append(
        "dados_simulacao",
        JSON.stringify(transformedData)
      );


      // const simulation = await sendSimulationToBackend(data, availableUsers);
      const simulation = await GeralSubmitToBackEnd(
        formDataToSend2,
        availableUsers
      );

      setError("");

      // if success show success message
      message.success("Simulação feita com sucesso");

      // set timout for 2 seconds stop loading and redirect
      setTimeout(() => {
        window.location.href = `/vida-futura/${simulation}`;
      }, 3000);
    } catch (err: any | AxiosError) {
      setLoading(false);
      if (axios.isAxiosError(err)) {
        setError(err.response?.data?.message || "Erro desconhecido");
      } else {
        setError(err.message);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //handle change email
  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleBirthdayChange = (date: Date | null) => {
    setFormData({
      ...formData,
      birthday: date,
    });
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      duration: Number(value),
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      {loading ? (
        <div className="max-w-screen-lg w-full text-center">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <h2 className="text-2xl font-bold mb-4">
            Simulação de Seguro de Vida
          </h2>
          <form onSubmit={handleSubmit} className="max-w-md">
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Nome
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 p-2 w-full border rounded-md"
                required
              />
            </div>

            {/* email do cliente */}
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleChangeEmail}
                className="mt-1 p-2 w-full border rounded-md"
                required
              />
            </div>

            {/* telefone do cliente */}
            <div className="mb-6">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Telefone
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="mt-1 p-2 w-full border rounded-md"
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="birthday"
                className="block text-sm font-medium text-gray-700"
              >
                Data de Nascimento
              </label>
              <div className="relative mt-1 p-2 w-full border rounded-md">
                <DatePicker
                  selected={formData.birthday}
                  onChange={(date: Date | null) => handleBirthdayChange(date)}
                  dateFormat="dd-MM-yyyy"
                  required
                  className="w-full h-full p-2"
                  wrapperClassName="w-full h-full"
                  maxDate={
                    new Date(
                      // 18 + 6 meses ago
                      new Date().getTime() -
                        18 * 365 * 24 * 60 * 60 * 1000 -
                        6 * 30 * 24 * 60 * 60 * 1000
                    )
                  }
                  minDate={
                    new Date(
                      // 57years + 6 months years ago
                      new Date().getTime() -
                        57 * 365 * 24 * 60 * 60 * 1000 -
                        6 * 30 * 24 * 60 * 60 * 1000
                    )
                  }
                  locale={pt}
                  closeOnScroll={true}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd-mm-yyyy"
                />
                <span className="absolute right-2 top-4">
                  <FaCalendar style={{ color: "#9CFFA5" }} />
                </span>
              </div>
            </div>

            <div className="mb-4">
              <label
                htmlFor="contract"
                className="block text-sm font-medium text-gray-700"
              >
                Contrato
              </label>
              <div className="relative mt-1 p-2 w-full border rounded-md">
                <DatePicker
                  selected={formData.contract}
                  onChange={(date) =>
                    setFormData({ ...formData, contract: date })
                  }
                  dateFormat="dd-MM-yyyy"
                  minDate={new Date()} // Allow selecting from today
                  maxDate={
                    new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
                  } // 30 days from today
                  required
                  dropdownMode="select"
                  locale={pt}
                  placeholderText="dd-mm-yyyy"
                  className="w-full h-full p-2"
                  wrapperClassName="w-full h-full"
                />
                <span className="absolute right-2 top-4">
                  <FaCalendar style={{ color: "#9CFFA5" }} />
                </span>
              </div>
            </div>

            <div className="mb-4">
              <label
                htmlFor="duration"
                className="block text-sm font-medium text-gray-700"
              >
                Duração em anos
              </label>
              <select
                name="duration"
                value={formData.duration || ""}
                onChange={handleDurationChange}
                className="mt-1 p-2 w-full border rounded-md"
              >
                <option value="">Selecione a duração</option>
                {Array.from({ length: 11 }, (_, i) => i + 10).map((year) => (
                  <option key={year} value={year}>
                    {year} anos
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Capital de Prêmio de Risco e Poupança
              </label>
              <RadioGroup
                value={formData.radAwardCapital || ""}
                onChange={(value) =>
                  setFormData({ ...formData, radAwardCapital: value })
                }
                className="mt-1"
              >
                {(Radio) => (
                  <>
                    <label className="mr-4">
                      <Radio
                        value="mensal"
                        checked={formData.radAwardCapital === "mensal"}
                      />{" "}
                      Mensal
                    </label>
                    <label>
                      <Radio
                        value="Capital"
                        checked={formData.radAwardCapital === "Capital"}
                      />{" "}
                      Capital
                    </label>
                  </>
                )}
              </RadioGroup>
            </div>

            <div className="mb-4">
              <label
                htmlFor="award"
                className="block text-sm font-medium text-gray-700"
              >
                Prêmio
              </label>
              <input
                type="number"
                id="award"
                name="award"
                value={formData.award || ""}
                onChange={handleChange}
                className="mt-1 p-2 w-full border rounded-md"
                required
              />
            </div>

            <div className="mb-4">
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Simular
              </button>
            </div>
          </form>
          {error !== "" && (
            <div className="mt-4 text-red-500">
              <p>{error}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PageSimuladorVidaFutura;
