import React, { useEffect, useState } from "react";
import { getTipo, updateTipo } from "../../../api/Backend"; // Assuming you have an updateTipo function in your API
import { Spin, message, List, Input, Button, Form } from "antd";
import { useAuthContext } from "../../../context/AuthContext";

const TipoChange: React.FC = () => {
  const [editableTipos, setEditableTipos] = useState<any>([]);
  const [currentTipos, setCurrentTipos] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { user, isLoading,triggerUpdate } = useAuthContext();
  const [antForm] = Form.useForm();
  //gettipo on backend page is a funciton that returns all the tipos on the database
  useEffect(() => {
    const fetchTipo = async () => {
      try {
        setLoading(true);
        const tipos = await getTipo();
        setCurrentTipos(tipos);
        setEditableTipos(JSON.parse(JSON.stringify(tipos))); // Deep clone the types for editing
      } catch (err) {
        message.error("Ocorreu um erro");
      } finally {
        setLoading(false);
      }
    };
    fetchTipo();
  }, []);

  //if is loading
  if (loading || isLoading) {
    return (
      <div className="flex items-center justify-center h-screen overflow-hidden">
        <Spin size="large" />
      </div>
    );
  }
  
  // check if tipo.level is <= 1
  // if it is, goto dashboard
  if (user?.tipo?.level > 1) {
    // return to dashboard
    window.location.href = "/";
    
    return <></>;
  }
  const handleNivelChange = (id: number, value: string) => {
    setEditableTipos((prevTipos: any[]) =>
      prevTipos.map((item: any) => {
        // Verifica se o item atual tem o mesmo id
        if (item.id === id) {
          // Atualiza o 'nivel' do item
          return { ...item, attributes: { ...item.attributes, nivel: value } };
        }
        // Mantém os itens não alterados como estão
        return item;
      })
    );
  };
  

  const handleColorChange = (id: number, value: string) => {
    setEditableTipos((prevTipos: any) =>
      prevTipos.map((item: any) =>
        item.id === id
          ? { ...item, attributes: { ...item.attributes, cor: value } }
          : item
      )
    );
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await Promise.all(
        editableTipos.map((item: any) =>
          updateTipo(item.id, { ...item.attributes })
        )
      );
      message.success("Tipos atualizados com sucesso!");
      // REFRESH TIPOS
        const tipos = await getTipo();  
        setCurrentTipos(tipos);
        setEditableTipos(JSON.parse(JSON.stringify(tipos))); // Deep clone the types for editing
        
    } catch (error) {
      message.error("Ocorreu um erro ao salvar os tipos");
    } finally {
      setLoading(false);
      triggerUpdate();
    }
  };

  return (
    <div className="container mx-auto">
      <div className="mb-8 text-center">
        <h1 className="text-3xl font-semibold mb-2">Configuração de Tipos</h1>
        <p className="text-gray-600">Personalize os níveis e cores dos tipos</p>
      </div>

      

      <Form form={antForm} onFinish={handleSubmit}>
      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        className="
      bg-blue-500
      hover:bg-blue-700
      text-white
      font-bold
      
      px-4
      rounded
      mb-4
      "
      >
        Salvar
      </Button>
        <List
          itemLayout="vertical"
          dataSource={currentTipos}
          renderItem={(item: any) => (
            <List.Item
              key={item.id}
              className="bg-white p-4 mb-4 rounded shadow flex justify-center items-center space-x-8"
            >
              <div>
                {/* cada item tem um titulo que é o nivel atual, deve estar centrado no topo do item*/}
                <h2 className="text-center text-lg font-bold mb-2">
                  {item.attributes.nivel}
                </h2>

                <div
                  className="
                  flex
                  items-center
                  space-x-4
                  px-8
                "
                >
                  <label className="block text-sm font-semibold mb-1">
                    Nível:
                  </label>
                  <Form.Item
                    name={`nivel_${item.id}`}
                    initialValue={item.attributes.nivel}
                  >
                    <Input
                      type="text"
                      onChange={(e) =>
                        handleNivelChange(item.id, e.target.value)
                      }
                      className="w-32 mt-4"
                    />
                  </Form.Item>
                </div>
              </div>

              <div>
                <label className="block text-sm font-semibold mb-1">
                  Cor Atual:
                </label>
                <div
                  className="w-6 h-6 rounded-full mb-1"
                  style={{ backgroundColor: item.attributes.cor }}
                ></div>
                <span className="text-xs text-gray-500">
                  {item.attributes.cor}
                </span>
              </div>

              <div>
                <label className="block text-sm font-semibold mb-1">
                  Cor Nova:
                </label>
                <Form.Item
                  name={`cor_${item.id}`}
                  initialValue={item.attributes.cor}
                >
                  <Input
                    type="color"
                    onChange={(e) => handleColorChange(item.id, e.target.value)}
                  />
                </Form.Item>
              </div>
            </List.Item>
          )}
        />
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TipoChange;
