import { Form, Input, Spin, message } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { setToken } from "../../api/Auth/helpers";
import { useAuth } from "../../api/Auth/useAuth";
import login from "../../api/login";
const renderLoading = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <Spin size="large" />
    </div>
  );
};
const SignIn = () => {
  const { isDesktopView } = useScreenSize();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();


  const { setUser, isLoading, triggerUpdate } = useAuthContext();

  const [loading, setIsLoading] = useState(false);

  const [error, setError] = useState<string | null>(null); // Specify type for error state



  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
      window.location.reload();
    }
  }, [isAuthenticated, navigate]);


  const [form] = Form.useForm(); // Use Form.create() to create a controlled form
  const onFinish = async (values: any) => {
    setIsLoading(true);
    try {
      const data = await login(values.email, values.password);

      if (data.jwt) {
        setToken(data.jwt);
        setUser(data.user);
        message.success(`Welcome, ${data.user.username}!`);

        navigate("/");
        window.location.reload();
      } else {
        throw new Error(data.message || 'Authentication failed!');
      }
    } catch (error: any) { // Explicitly define error type here
      console.error('Login Error:', error);
      // Set error message appropriately
      if (error.response && error.response.data && error.response.data.error.message) {
        setError(error.response.data.message);
      } else if (error.message) {
        setError(error.message);
      } else {
        setError('An unknown error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  }


  if (isLoading) {
    return renderLoading();
  }

  return (
    <Fragment>
      <div className="flex items-center justify-center min-h-screen">
        <div className={`w-${isDesktopView ? "2/5" : "full"}`}>
          <div className="bg-white shadow-md rounded p-12">
            <div className="mb-6">
              <img
                src="https://strapi.realbusinesscenter.wt.pt/uploads/logo512_bbac297d2f.png"
                alt="Real Vida Seguros"
                className="w-24 h-24 mx-auto mb-4 p-2 object-cover rounded-full bg-gray-200
              "
              />
            </div>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              className="w-[250px]"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Coloque um email válido!",
                  },
                ]}
              >
                <Input
                  placeholder="Endereço de email"
                  className="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Coloque uma password" }]}
              >
                <Input.Password
                  placeholder="Password"
                  className="w-full px-3 py-2 leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                />
              </Form.Item>
              {error && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
                  role="alert"
                >
                  <span>{error}</span>
                  <button
                    className="absolute top-0 -right-4 px-4 py-3"
                    onClick={() => setError(null)}
                  >
                    <svg
                      className="fill-current h-6 w-6 text-red-500"
                      role="button"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <title>Fechar</title>
                      <path d="M14.348 5.652a.5.5 0 0 0-.708 0L10 9.293 6.06 5.353a.5.5 0 0 0-.708.708L9.293 10l-3.94 3.94a.5.5 0 0 0 .708.708L10 10.707l3.94 3.94a.5.5 0 0 0 .708-.708L10.707 10l3.94-3.94a.5.5 0 0 0 0-.708z" />
                    </svg>
                  </button>
                </div>
              )}
              <div>
                <button
                  type="submit"
                  className="w-full bg-blue-500 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Login {isLoading && <span className="animate-spin">...</span>}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignIn;
