import { getToken } from "./Auth/helpers";
import api from "./api";
// set API_URL and token
const API_URL = "https://strapi.realbusinesscenter.wt.pt/api";
const UPLOAD_URL = "https://strapi.realbusinesscenter.wt.pt";

const token = getToken();

export async function getResultById(id) {
  if (!id) {
    return [];
  }
  try {
    // add id to attributes
    // api 
    const response = await api.get(
      `/simulacoes?filters[uuid][$eq]=${id}&populate[saleSim][populate][tipo_simulacao]=*&populate=*`
    );
    response["data"]["data"][0]["attributes"].idSimuladorVida =
      response.data?.data[0]?.id;
    // get my id from get method with APi
    const myId = await api.get(`/users/me`);
    
    // compare myId.data.id with response.data?.data[0]?.attributes?.user?.data?.id if both match the update the state to Aberta
    if (
      myId.data.id === response.data?.data[0]?.attributes?.user?.data?.id &&
      response.data?.data[0].attributes.estado === "Entregue"
    ) {
      // api post the api_utl/simulador-vidas/id to set the simulador to state Aberta
      const response2 = await api.put(
        `/simulacoes/${response.data?.data[0].id}`,
        {
          data: {
            estado: "Aberta",
          },
        }
      );
    }

    return response.data?.data[0]?.attributes;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getUserByID(id) {
  if (!id) {
    return [];
  }
  try {
    const response = await api.get(`/users/${id}?populate[equipa][populate]=*&populate[zona][populate]=*&populate[tipo][populate]=*`);
    return response?.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

// export updateuserbyid
export async function updateUserById(id, data) {
  if (!id) {
    return [];
  }
  try {
    // use api
    const response = await api.put(`/users/${id}`, {
      data: data,
    });
    return response.data?.data?.attributes;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function deleteUserById(id) {
  if (!id) {
    return [];
  }
  try {
    // use api
    const response = await api.delete(`/users/${id}`);
    return response.data?.data?.attributes;
  } catch (error) {
    console.error(error);
    return [];
  }
}
// get relatorio by id
export async function getRelatorioById(id) {
  if (!id) {
    return [];
  }

  try {
    // api 
    const response = await api.get(`/relatorios?filters[id][$eq]=${id}&populate[0]=selectedSimSales.simulacao&populate[1]=simulacao.user&populate[2]=simulacao.observacoes&populate[3]=simulacao.observacoes.user&populate[4]=simulacao.observacoes.user.tipo&populate[5]=simulacao.tipo_simulacao.slug&populate[6]=selectedSimSales.tipo_simulacao.slug`);
    const relatorioData = response.data.data[0];

    return relatorioData;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getTypeById(id) {
  if (!id) {
    return [];
  }
  try {
    // api
    const response = await api.get(`/simulacoes?filters[uuid][$eq]=${id}&populate=*`);
    
    // add id to attributes
    response["data"]["data"][0]["attributes"].idSimuladorVida =
      response.data?.data[0]?.id;
    // get my id from get method with api to API_URL/users/me
    const myId = await api.get(`/users/me`);
    // compare myId.data.id with response.data?.data[0]?.attributes?.user?.data?.id if both match the update the state to Aberta
    if (
      myId.data.id === response.data?.data[0]?.attributes?.user?.data?.id &&
      response.data?.data[0].attributes.estado === "Entregue"
    ) {
      // api post the api_utl/simulacoes/id to set the simulador to state Aberta
      const response2 = await api.put(
        `/simulacoes/${response.data?.data[0].id}`,
        {
          data: {
            estado: "Aberta",
          },
        }
      );
    }

    return response.data?.data[0]?.attributes;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getResultById2(id) {
  if (!id) {
    return [];
  }
  try {
    // api 
    const response = await api.get(`simulacoes?filters[uuid][$eq]=${id}&populate[saleSim][populate][tipo_simulacao]=*&populate=*`);
    // add id to attributes
    response["data"]["data"][0]["attributes"].idSimuladorVida =
      response.data?.data[0]?.id;
    // get my id from get method with api to API_URL/users/me
    const myId = await api.get(`/users/me`);
    // compare myId.data.id with response.data?.data[0]?.attributes?.user?.data?.id if both match the update the state to Aberta
    if (
      myId.data.id === response.data?.data[0]?.attributes?.user?.data?.id &&
      response.data?.data[0].attributes.estado === "Entregue"
    ) {
      // api post the api_utl/simulador-vidas/id to set the simulador to state Aberta
      const response2 = await api.put(
        `/simulacoes/${response.data?.data[0].id}`,
        {
          data: {
            estado: "Aberta",
          },
        }
      );
    }
    return response.data?.data[0]?.attributes;
  } catch (error) {
    console.error(error);
    return [];
  }
}

// make a getResultById3 exatly from getResultById
export async function getResultById3(id) {
  if (!id) {
    return [];
  }
  try {
    // api
    const response = await api.get(
      `/sale-sims?filters[id][$eq]=${id}&populate[tipo_simulacao]=*&populate=*`
    );

    // just return the response data
    return response.data?.data[0]?.attributes;
  } catch (error) {
    console.error(error);
    return [];
  }
}
