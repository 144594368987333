import React from 'react';
import LogoEmpresa from "../assets/img/logo192.png";

const PatchNotes: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 overflow-hidden">
      <div className="max-w-4xl max-h-screen overflow-auto">
        <div className="flex items-center justify-center mb-8">
          <img src={LogoEmpresa} alt="Logo da Empresa" className="h-24" />
        </div>
        <div className="p-8 bg-white rounded-lg shadow-lg">
          <h1 className="text-4xl font-bold mb-8 text-center">Notas da Atualização</h1>
          
          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-4">Hoje:</h2>
            <ul className="list-disc ml-8 mb-6">
              <li className="mb-4">
                <span className="font-bold">Atualização dos endpoints da API para melhorar a funcionalidade.</span>
                <p className="text-sm">- Agora relatórios mostram (caso haja) mais de mil resultados.</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">Correção dos caminhos de importação em vários componentes para garantir a integridade do sistema.</span>
                <p className="text-sm">Adicionada tradução portuguesa nos date pickers.</p>
              </li>
            </ul>
          </div>
          <div className="mb-12">
            <ul className="list-disc ml-8 mb-6">
              <li className="mb-4">
                <span className="font-bold">Atualização das opções de paginação em SideMenu.tsx, Relatorio.tsx, agentes.tsx...</span>
                <p className="text-sm">- Alterada a forma de como as leads são distribuídas. Agora, primeiro verifica-se se existe uma lead com o mesmo email da nova lead. Caso exista e seja do mesmo tipo de simulação, a lead é marcada como repetida. Se existir uma lead com o mesmo email, mas de outra simulação, verifica-se se está atribuída a outro utilizador. Em caso afirmativo, a nova lead é atribuída ao mesmo utilizador, evitando assim que duas leads do mesmo utilizador tenham tipos de simulação diferentes.</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">Alteração visual do resultado da simulação de vida habitação. O texto dos dados do cliente agora está a branco.</span>
                <img
                  src="https://strapi.realbusinesscenter.wt.pt/uploads/Screenshot_2024_04_30_at_18_51_35_c1b7e152bb.png"
                  alt="Resultado da simulação de vida habitação"
                  className="w-full my-4"
                />
              </li>
              <li className="mb-4">
                <span className="font-bold">Alteração do tamanho horizontal da tabela onde são apresentadas as leads. O número de leads por página foi alterado para 10 na mesma tabela.</span>
                <p className="text-sm">- Foram removidas da tabela todas as leads com estado final da tabela inicial, bem como as leads com estado reagendado superior a 3 dias. Para ver uma lead, o utilizador deve clicar na checkbox todas. (Inserir captura de ecrã)</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">É possível pesquisar na barra de pesquisa da tabela por email, nome, UUID, telefone, estado, utilizador, categoria e tipo de simulação.</span>
              </li>
              <li className="mb-4">
                <span className="font-bold">Admin: Agora, ao filtrar por utilizador, são mostrados apenas agentes e estão organizados por ordem alfabética.</span>
              </li>
              <li className="mb-4">
                <span className="font-bold">Página de relatórios: A tabela de relatórios foi alterada para mostrar apenas 5 por página, mas com a opção de aumentar.</span>
                <p className="text-sm underline bg-yellow-300 text-center">- *PARA IMPLEMENTAR* Implementação de barra de pesquisa nos relatórios.</p>
              </li>
            </ul>
          </div>
          <div>
            <ul className="list-disc ml-8">
              <li className="mb-4">
                <span className="font-bold">Refatoração dos endpoints da API, atualização da estrutura de dados da simulação e correção de caminhos de importação.</span>
                <p className="text-sm">- Adicionada a possibilidade de verificar as observações de distribuição na página das leads.</p>
                <p className="text-sm">- Corrigida a data de nascimento da simulação.</p>
                <p className="text-sm">- Corrigido o erro ao clicar na referência na página de relatórios, agora redireciona para o local correto.</p>
                <img
                  src="https://strapi.realbusinesscenter.wt.pt/uploads/Screenshot_2024_04_30_at_16_39_57_9a42b55448.png"
                  alt="Resultado da simulação de vida habitação"
                  className="w-full my-4"
                />
                <p className="text-sm">- Adicionadas notificações do browser.</p>
                <p className="text-sm">- Manifesto refeito.</p>
                <p className="text-sm  underline bg-red-300 text-center">- Cross selling desativado até novo aviso.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatchNotes;
