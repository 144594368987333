import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Spin, Table, message, Modal } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { API } from "../../api/Auth/constant";
import { getToken } from "../../api/Auth/helpers";
interface AgentFormProps {
  user: any; // Define the type of the user prop
  handleCancel: () => void;
  handleOk: () => void;
}

const { Option } = Select;
export const AgentForm: React.FC<AgentFormProps> = ({
  user, // Now the user prop is properly defined
  handleCancel,
  handleOk,
}) => {
  const [form] = Form.useForm();
  const { isLoading, equipas, user : creatoruser, tipos} = useAuthContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  let option: string[] = [];
  if (creatoruser?.tipo?.level <= 1) {
    // Usuários com level 0 ou 1 podem criar todos os tipos
    option = tipos.map(tipo => tipo.nivel);
  } else {
    // Usuários com outros levels só podem criar tipos abaixo do seu próprio level
    option = tipos.filter(tipo => tipo.level > creatoruser?.tipo.level).map(tipo => tipo.nivel);
  }

  const handleGeneratePassword = () => {
    // generate random password
    const generatedPassword = Math.random().toString(36).slice(-8);

    // Set the generated password in state
    setPassword(generatedPassword);
  };

  const handleCopyPassword = () => {
    // Copy the password to clipboard
    navigator.clipboard.writeText(password);
    // Show the copied message
    const copied = document.getElementById("copied");
    if (copied) {
      copied.style.display = "block";
    }
    setTimeout(() => {
      if (copied) {
        copied.style.display = "none";
      }
    }, 1000);
  };
  const handleFinishPass = async (values: any) => {
    setLoading(true);
    // check for password
    if (!user && !password) {
      setShowPasswordModal(true);
      setLoading(false);
      return;
    }
    // if user make link {api}/users/{user.id} else remove the id
    let url = "";
    if (user) {
      url = `${API}/users/${user?.id}`;
    } else {
      url = `${API}/users`;
      // set password on values
      values.password = password;
      values.role = 3;
      // Supondo que values.tipo seja um número representando o nível
      const tipoObjeto = { level: values.tipo }; // Cria um novo objeto com a propriedade level
      values.tipo = tipoObjeto; // Atribui esse objeto de volta a values.tipo

    }
    try {
      const response = await fetch(url, {
        // if user ?  post : put
        method: user ? "PUT" : "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(values),
      });
      const responseData = await response.json();
      /* if response is 400 return the message inside error */
      if (!response.ok) {
        throw new Error(responseData.error);
      }
      message.success("Data saved successfully!");

      // set timout and reload window
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error(error);
      message.error("Error While Updating the Profile!");
    } finally {
      setLoading(false);
    }
  };

  const handleFinishFailed = (errorInfo: any) => {
    message.error("Failed", errorInfo)
  };
  if(user?.tipo?.level <= 1 && creatoruser?.tipo?.level > 1){
    return (
      <div className="flex justify-center items-center bg-gray-100">
        <div className="bg-white rounded-lg shadow-md p-8">
          <h1 className="text-2xl font-semibold mb-4">
            Desculpe, você não tem permissão para editar um utilizador
            administrador.
          </h1>
        </div>
      </div>
    );
  }



  const renderForm = () => {
    return (
      
      <Form
        form={form}
        name="agent-form"
        onFinish={handleFinishPass}
        onFinishFailed={handleFinishFailed}
        layout="vertical"
        initialValues={{
          username: user?.name,
          email: user?.email,
          phone: user?.phone,
          equipa: user?.equipa || "Sem Equipa",
          tipo : user?.tipo?.nivel || "Sem Tipo"
        }}
        key={user?.id}
      >
        <Form.Item
          label="Nome"
          name="username"
          rules={[{ required: true, message: "Por favor, insira um nome!" }]}
          key={1}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Por favor, insira um email!" }]}
          key={2}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Telefone"
          name="phone"
          rules={[
            { required: true, message: "Por favor, insira um telefone!" },
          ]}
          key={3}
        >
          <Input />
        </Form.Item>
        {/* if no user show select team */}
        {!user && (
          <>
          <Form.Item
            label="Equipa"
            name="equipa"
            rules={[
              { required: true, message: "Por favor, selecione uma equipa!"},
            ]}
            key={4}
          >
            <Select>
              {Array.isArray(equipas) ? (
                // com equipas
                equipas.map((equipa) => (
                  <Option value={equipa.id} key={equipa.id}>
                    {equipa.attributes.nome_equipa}
                  </Option>
                ))
              ) : (
                // sem equipas
                <Option value="" key={"null"}>
                  Sem equipas disponiveis
                </Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Tipo"
            name="tipo"
            rules={[{ required: true, message: "Por favor, selecione um tipo!" }]}
          >
            <Select>
              {option.map((tipo) => (
                <Option value={tipo} key={tipo}>
                  {tipo}
                </Option>
              ))}
            </Select>
          </Form.Item>
          </>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            // send info to api
            className="bg-green-500 hover:bg-green-600/20 mr-2 text-white"
          >
            {user ? "Atualizar" : "Criar"}
          </Button>
        </Form.Item>
      </Form>
    );
  };
  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          {renderForm()}
          <Modal
            title="Password Generator"
            open={showPasswordModal}
            onCancel={() => setShowPasswordModal(false)
            }
            footer={[
              <Button key="generate" onClick={handleGeneratePassword}>
                Generate Password
              </Button>,
              <Button
                key="copy"
                type="primary"
                className="bg-green-500"
                onClick={handleCopyPassword}
              >Copy Password</Button>
            ]}
          >
            <div className="flex items-center">
              <Input.Password
                value={password}
                className="w-3/4"
                placeholder="Generated Password"
              />
              <div id="copied" className="ml-2" style={{ display: "none" }}>
              ✅ Sucesso!
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default AgentForm;