import React, { useEffect, useState } from "react";
import backg from "../../../../assets/img/bkground.png";
import { useParams } from "react-router-dom";
import { getResultById } from "../../../../api/getSimInfo";
import { useNavigate } from "react-router-dom";
import { Spin, message } from "antd";
import { RiFileTextLine, RiArrowGoBackLine } from "react-icons/ri";
import LeadReport from "../../Relatorio";
import { useAuthContext } from "../../../../context/AuthContext";
import { CreateRelatorio, createObservacao } from "../../../../api/Backend";
import { FaPlus } from "react-icons/fa";
import SimuladoModal from "../../sales_sim/Modal/NewSimModal";

interface ResultItem {
  age: number;
  yearK: number;
  capital: number;
  papr: number;
  pacr: number;
  cmp: number;
  prto: number;
  prtoAgv: number;
  prto_Monthly: number;
  prtoAgv_Monthly: number;
  pac: number;
  papf: number;
  pmaf: number;
  rgpf: number;
  rgpf_Death: number;
}

interface ResultadoSimulacao {
  name: string;
  birthday: string;
  contract: string;
  duration: number;
  radAwardCapital: string;
  award: number;
  capital: number;
  payment: number;
  percentage: number;
  numberIterations: number;
  age: number;
  guaranteedRate: number;
  indexing: number;
  result: ResultItem[];
  capital_Ma: number;
  capital_Mac: number;
}
interface DadosSimulacao {
  birthday: string;
  contract: string;
  duration: string;
  radAwardCapital: string;
  award: string;
  nome: string;
  email: string;
  phone: string;
}

interface simulation {
  id: number;
  attributes: {
    nome: string;
    email: string;
    phone: string;
    createdAt: string;
    updatedAt: string;
    dados_simulacao: any;
    resultado_simulacao: any;
    simulacao: Data;
    tipo_simulacao: {
      id: number;
      attributes: {
        name: string;
      };
    };
  };
}
interface SalesSim {
  data: simulation[];
}

interface Data {
  nome: string;
  email: string;
  phone: string;
  resultado_simulacao: ResultadoSimulacao;
  dados_simulacao: DadosSimulacao;
  createdAt: string;
  updatedAt: string;
  id: string;
  tipo_simulacao: {
    id: number;
    attributes: {
      name: string;
    };
  };
  saleSim: SalesSim;
}
const formatCurrency = (value: string | number | undefined) => {
  // Verifica se o valor é indefinido, uma string vazia ou não é uma string ou número
  if (
    value === undefined ||
    value === "" ||
    (typeof value !== "string" && typeof value !== "number")
  )
    return ""; // Retorna uma string vazia se o valor for indefinido, uma string vazia ou não é uma string ou número

  // Converte o valor para um número, se for uma string
  const numericValue = typeof value === "string" ? parseFloat(value) : value;

  // Verifica se a conversão foi bem-sucedida e se o resultado é um número válido
  if (isNaN(numericValue)) return ""; // Retorna uma string vazia se a conversão falhar ou o número não for válido

  // Formata o número convertido com duas casas decimais, substitui o ponto decimal por vírgula e adiciona separadores de milhar
  const formattedValue = `€ ${numericValue
    .toFixed(2)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;

  // Retorna a string formatada
  return formattedValue;
};
const AwardformatCurrency = (value: string | number | undefined): string => {
  // Verifica se o valor é indefinido, uma string vazia ou não é uma string ou número
  if (
    value === undefined ||
    value === "" ||
    (typeof value !== "string" && typeof value !== "number")
  )
    return ""; // Retorna uma string vazia se o valor for indefinido, uma string vazia ou não é uma string ou número

  // Converte o valor para um número, se for uma string
  const numericValue = typeof value === "string" ? parseFloat(value) : value;

  // Verifica se a conversão foi bem-sucedida e se o resultado é um número válido
  if (isNaN(numericValue)) return ""; // Retorna uma string vazia se a conversão falhar ou o número não for válido

  // Formata o número convertido com duas casas decimais, substitui o ponto decimal por vírgula e adiciona separadores de milhar
  const formattedValue = numericValue
    .toFixed(2)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Retorna a string formatada com o símbolo do euro
  return `${formattedValue} €`;
};

// date comes as 2023-09-11T23:00:00.000Z change to dd/mm/yyyy
const formatDate = (date: string) => {
  const dateSplit = date.split("T");
  const dateSplit2 = dateSplit[0].split("-");
  const day = dateSplit2[2];
  const month = dateSplit2[1];
  const year = dateSplit2[0];
  return `${day}/${month}/${year}`;
};

function parseResultadoSimulacao(resultado: any) {
  let parsedResult;

  if (typeof resultado === "string") {
    try {
      parsedResult = JSON.parse(resultado.replace(/\\"/g, '"'));
    } catch (error) {
      console.error("Erro ao fazer o parse do JSON:", error);
      return null; // Retorna null em caso de erro
    }
  } else if (typeof resultado === "object" && resultado !== null) {
    parsedResult = resultado;
  } else {
    console.error("Tipo de resultado não suportado:", typeof resultado);
    return null; // Retorna null se o tipo não for suportado
  }

  return parsedResult;
}

// url is sent with id, get id from props
const PageResultadosVidaFutura: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Get the 'id' parameter from the URL
  const [data, setData] = React.useState<any>({ result: [] });
  const [loading, setLoading] = React.useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isNewSimModalOpen, setIsNewSimModalOpen] = useState(false);
  // get user
  const { user, triggerUpdate } = useAuthContext();
  const [additionalData, setAdditionalData] = useState({
    user_id: "",
    referencia: "",
    username: "",
    created_at: "",
    id: "",
    // sale sim is an object
    saleSim: undefined,
  });
  const [newSimData, setNewSimData] = useState({
    nome: "",
    email: "",
    phone: "",
    birthday: "",
    uuid: "",
    id: "",
  });

  const backgroundStyle = {
    backgroundImage: `url(${backg})`, // Use a variável backg para o caminho da imagem
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  const navigate = useNavigate();

  // get data from api

  useEffect(() => {
    getResultById(id).then((data) => {
      data.resultado_simulacao = data.resultado_simulacao.result;
      setData(data);
    });
  }, [id]);

  // loading
  if (!data || !data.resultado_simulacao || !data.resultado_simulacao) {
    return (
      <div className="bg-gray-200 min-h-screen flex items-center justify-center p-8">
        <div className="max-w-screen-lg w-full text-center">
          <Spin size="large" />
        </div>
      </div>
    );
  }


  const openReportModal = () => {
    triggerUpdate();

    // change created at to readable string
    const date = new Date(data?.createdAt);
    const dateStr = date.toLocaleDateString("pt-PT");
    setAdditionalData({
      referencia: data?.uuid || "",
      user_id: user?.id.toString() || "",
      username: user?.username || "",
      created_at: dateStr,
      id: data?.idSimuladorVida || "",
      // send the salessim if exists
      saleSim: data?.saleSim,
    });

    setIsReportModalOpen(true);
  };
  const closeReportModal = () => {
    setIsReportModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsNewSimModalOpen(false);
  };

  window.onfocus = () => {
    // check if any modal is open if not reload
    if (!isReportModalOpen) window.location.reload();
  };

  // faz um openNewSim modal
  const openNewSimModal = () => {
    setNewSimData({
      nome: data?.nome,
      email: data?.email,
      phone: data?.phone,
      birthday: data?.resultado_simulacao?.data_nasc,
      uuid: data?.uuid,
      id: data?.idSimuladorVida || "",
    });
    setIsNewSimModalOpen(true);
  };
  const handleFormSubmit = (formData: any, observacao?: any) => {
    // log the form mdata
    setLoading(true);

    CreateRelatorio(formData);

    // if observacao is set then createObservacao on backend
    if (observacao) {
      // create observacao
      createObservacao(observacao);
    }

    closeReportModal();
    message.success("Relatório enviado com sucesso, a retornar ás leads");
    // timout and return
    setTimeout(() => {
      navigate("/leads");
      window.location.reload();
    }, 1500);
  };
  return (
    <div className="w-full h-screen bg-gray-100 flex items-center justify-center">
      <div
        className="text-white p-4 font-nexa w-full h-full overflow-auto bg-bg-color"
        style={backgroundStyle}
      >
        <h1 className="text-2xl font-bold">
          Simulação Real Seguro Vida Futura
        </h1>
        <div className="flex justify-end">
          {/* Botão para nova simulação */}
          {/* <button
            className="bg-blue-200 hover:bg-blue-200 text-white px-4 py-2 rounded mr-2"
            onClick={openNewSimModal}
          >
            <FaPlus className="inline-block mr-2" />
            Gerar Nova Simulação
          </button> */}
          <button
            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
            onClick={openReportModal}
          >
            <RiFileTextLine className="inline-block mr-2" />
            Gerar Relatório
          </button>
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded ml-2"
            onClick={() => navigate("/leads")}
          >
            <RiArrowGoBackLine className="inline-block mr-2" />
            Voltar
          </button>
        </div>

        {/* check loading */}
        {loading && (
          <div className="bg-gray-200 min-h-screen flex items-center justify-center p-8">
            <div className="max-w-screen-lg w-full text-center">
              <Spin size="large" />
            </div>
          </div>
        )}

        <div className="p-6">
          <div className="flex flex-row p-1 m-0 w-full">
            <div className="w-1/4 mr-6">
              <p className="font-semibold text-right">
                Nome Tomador do Seguro:
              </p>
            </div>
            <div className="border p-1 w-1/3 text-center rounded-2xl">
              {data?.nome}
            </div>
          </div>

          <div className="flex flex-row p-1 m-0 w-full">
            <div className="w-1/4 mr-6">
              <p className="font-semibold text-right">
                Email Tomador do Seguro:
              </p>
            </div>
            <div className="border p-1 w-1/3 text-center rounded-2xl">
              {data?.email}
            </div>
          </div>

          <div className="flex flex-row p-1 m-0 w-full">
            <div className="w-1/4 mr-6">
              <p className="font-semibold text-right">
                Número de telefone do Tomador do Seguro:
              </p>
            </div>
            <div className="border p-1 w-1/3 text-center rounded-2xl">
              {data?.phone}
            </div>
          </div>

          <div className="flex flex-row p-1 m-0 w-full">
            <div className="w-1/4 mr-6">
              <p className="font-semibold text-right">
                Data Nascimento Pessoa Segura:
              </p>
            </div>
            <div className="w-1/3 text-center">
              <div className="w-1/2 text-center border rounded-2xl p-1 mx-auto">
                {data?.resultado_simulacao.data_nasc}
              </div>
            </div>
          </div>

          <div className="flex flex-row p-1 m-0 w-full">
            <div className="w-1/4 mr-6">
              <p className="font-semibold text-right">
                Data de inicio de contrato:
              </p>{" "}
            </div>
            <div className="w-1/3 text-center">
              <div className="w-1/2 text-center border rounded-2xl p-1 mx-auto">
                {formatDate(data?.resultado_simulacao?.data_inicio_contrato)}
              </div>
            </div>

            <div className="w-1/4 mr-4">
              <p className="font-semibold text-right">Prémio Mensal:</p>
            </div>
            <div className="border p-1 w-[150px] text-center rounded-2xl">
              {AwardformatCurrency(data?.resultado_simulacao?.award)}
            </div>
          </div>
        </div>

        <div className="p-2 m-12 border rounded-3xl mb-4">
          <div className="pt-4 pb-4 flex justify-center">
            <div className="risks">
              <table className="table-auto">
                <tbody>
                  <tr>
                    <td className="px-4 py-2">Coberturas</td>
                    <td className="px-4 py-2">Morte</td>
                    <td className="px-4 py-2">
                      {formatCurrency(data?.resultado_simulacao.capital)}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="px-4 py-2">
                      Invalidez Absoluta e Definitiva
                    </td>
                    <td className="px-4 py-2">
                      {formatCurrency(data?.resultado_simulacao.capital)}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="px-4 py-2">Morte por Acidente</td>
                    <td className="px-4 py-2">
                      {formatCurrency(data?.resultado_simulacao.capital_Ma)}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="px-4 py-2">
                      Morte por Acidente de Circulação
                    </td>
                    <td className="px-4 py-2">
                      {formatCurrency(data?.resultado_simulacao.capital_Mac)}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="px-4 py-2">Médico Online</td>
                    <td className="px-4 py-2">Oferta</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="px-4 py-2">
                      Aconselhamento Médico Telefónico
                    </td>
                    <td className="px-4 py-2">Oferta</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="px-4 py-2">Médico ao Domicilio</td>
                    <td className="px-4 py-2">Oferta</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="p-2 m-12 rounded-3xl flex justify-center">
          <table className="border w-full">
            <thead>
              <tr className=" text-center">
                <th className="py-2 border" style={{ userSelect: "none" }}>
                  Ano
                </th>
                <th className="py-2 border" style={{ userSelect: "none" }}>
                  Prémio Total Mensal
                </th>
                <th className="py-2 border" style={{ userSelect: "none" }}>
                  Prémio Total Anual
                </th>
                <th className="py-2 border" style={{ userSelect: "none" }}>
                  Capital de Risco
                </th>
                <th className="py-2 border" style={{ userSelect: "none" }}>
                  Valor* em Caso de Vida
                </th>
                <th className="py-2 border" style={{ userSelect: "none" }}>
                  Valor* em Caso de Morte
                </th>
              </tr>
            </thead>
            <tbody>
              {parseResultadoSimulacao(data.resultado_simulacao.result).map(
                (item:any, index:any) => (
                  <tr key={index} className="text-center">
                    <td className="border py-2">{item.yearK}</td>
                    <td className="border py-2">
                      {formatCurrency(item.prto_Monthly)}
                    </td>
                    <td className="border py-2">{formatCurrency(item.prto)}</td>
                    <td className="border py-2">
                      {formatCurrency(item.capital)}
                    </td>
                    <td className="border py-2">{formatCurrency(item.rgpf)}</td>
                    <td className="border py-2">
                      {formatCurrency(item.rgpf_Death)}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <label className="p-2 m-12 ">
          * Será deduzido o valor da retenção na fonte e, se aplicável, a
          penalização contratual definida.
        </label>
        <div className="p-2 m-12 rounded-3xl justify-center text-sm">
          <h2 className="text-lg font-semibold mb-2 text-base">Nota:</h2>
          <ul className="list-disc pl-5">
            <li className="mb-1">
              <span className="font-semibold">Valor em caso de Morte:</span>{" "}
              Corresponde ao saldo acumulado da componente financeira, acrescido
              do capital de risco.
            </li>
            <li className="mb-1">
              <span className="font-semibold">Valor em caso de Vida:</span>{" "}
              Corresponde ao saldo acumulado da componente financeira.
            </li>
            <li className="mb-1">
              Apenas é possível o resgate parcial a partir da 3ª anuidade, sendo
              possível o resgate total a qualquer momento.
            </li>
            <li>
              Em caso de resgate antecipado, será aplicada uma penalização no
              valor de 100% no 1º ano de vigência do contrato e de 75% no 2º ano
              de vigência do contrato.
            </li>
          </ul>
        </div>
        {data.resultado_simulacao.observacoes ? (
          <div className="p-2 m-12 rounded-3xl justify-center text-sm">
            <h2 className="text-lg font-semibold mb-2 text-base">
              Observações:
            </h2>
            <ul className="list-disc pl-5">
              {data.resultado_simulacao.observacoes.map(
                (item: any, index: any) => (
                  <li key={index} className="mb-1">
                    <span className="font-semibold">User:</span> {item.user}{" "}
                    <br />
                    <span className="font-semibold">Texto:</span> {item.texto}
                  </li>
                )
              )}
            </ul>
          </div>
        ) : null}
      </div>
      <LeadReport
        isOpen={isReportModalOpen}
        onRequestClose={closeReportModal}
        onSubmit={handleFormSubmit}
        additionalData={additionalData}
      />
      <SimuladoModal
        isOpen={isNewSimModalOpen}
        onRequestClose={handleCloseModal}
        dataRecieved={newSimData}
      />
    </div>
  );
};

export default PageResultadosVidaFutura;
