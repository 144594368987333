import axios from "axios";
import { setToken } from "./Auth/helpers";

const login = async (identifier, password) => {
  try {
    const response = await axios.post(
      "https://strapi.realbusinesscenter.wt.pt/api/auth/local",
      {
        identifier,
        password,
      }
    );

    // add jwt
    if (response.data.jwt) {
      setToken(response.data.jwt);
    }
    // Return user data or token after successful login
    return response.data;
  } catch (error) {
    // Check if error message indicates invalid identifier or password
    if (
      error.response &&
      error.response.data &&
      error.response.data.error && 
      error.response.data.error.message === "Invalid identifier or password"
    ) {
      // Return custom error message indicating incorrect username or password
      throw new Error("Nome de utilizador ou senha inválidos. Por favor, verifique as suas credenciais e tente novamente.");
    } else {
      console.log(error.response.data.error.message)
      return error?.response ? error : new Error(error);
    }
  }
};

export default login;
