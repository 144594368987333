import React, { useEffect, useState } from "react";
import { Table, Spin, Button, Modal } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { AgentForm } from "./AgenteForm";
import { render } from "@testing-library/react";
import { RiRefreshLine } from "react-icons/ri";

const AgentesPage: React.FC = () => {
  // get users from authcontext
  const { users, isLoading, user, triggerUpdate } = useAuthContext();
  const navigate = useNavigate();
  // state to store the data of each user in table format
  const [data, setData] = useState<any>([]);
  // state to store the columns of the table
  const [columns, setColumns] = useState<any>([]);
  // state to store the selected user
  const [selectedUser, setSelectedUser] = useState<any>(null);
  // state to store the visibility of the modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  // state for loading spinner while fetching data and also when creating a new agent or updating an existing one
  const [loading, setLoading] = useState(false);


  // function that sets the values for the states when component is mounted
  useEffect(() => {
    // set the columns for the table
    setColumns([
      {
        title: "Nome",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Telefone",
        dataIndex: "phone",
        key: "phone",
        render: (text: string) => renderPhone(text),
      },
      {
        title: "Tipo",
        dataIndex: "tipo",
        key: "tipo",
        render: (text: string, record: any) => (
          <div>
            <span
              className="mr-2"
              style={{
                backgroundColor: record.cor,
                borderRadius: "5px",
                padding: "5px",
                color: "black",
              }}
            >
              {text}
            </span>
          </div>
        ),
      },
      {
        title: "Equipa",
        dataIndex: "equipa",
        key: "equipa",
        render: (text: any, record: any) => (
          <div>
            <span className="mr-2">{record.equipa?.nome_equipa}</span>
          </div>
        ),
      },

      {
        title: "Ações",
        key: "actions",
        render: (text: any, record: any) => (
          <div>
            <Button
              icon={<AiOutlineEye />}
              onClick={() => {
                navigate(`/agentes/${record.id}`);
              }}
              className="bg-green-500 hover:bg-green-600/20 mr-2 text-white"
            ></Button>
            <Button
              icon={<AiOutlineEdit />}
              onClick={() => {
                setSelectedUser(record);
                setIsModalVisible(true);
              }}
              className="bg-blue-500 hover:bg-blue-600/20 text-white"
            ></Button>
          </div>
        ),
      },
    ]);
    // set the data for the table

    setData(
      users.map((user: any) => {
        return {
          key: user.id,
          id: user.id,
          name: user.username,
          email: user.email,
          phone: user.phone,
          equipa: user.equipa,
          equipa_id: user.equipa?.id,
          tipo: user?.tipo?.nivel,
          // cor of tipo
          cor: user?.tipo?.cor,
        };
      })
    );
  }, [users]);

  // function to handle the click on the edit button
  const handleEditClick = (record: any) => {
    setSelectedUser(record);
    setIsModalVisible(true);
  };
  // function to handle the click on the cancel button of the modal
  const handleCancel = () => {
    setIsModalVisible(false);
    // remove information from form
    setSelectedUser(null);
  };
  // function to handle the click on the ok button of the modal
  const handleOk = () => {
    setIsModalVisible(false);
  };
  // function to render the phone number in the table
  const renderPhone = (text: string) => {
    return (
      <span>
        {/* make a telto  */}
        <a href={`tel:${text}`}>
          +351 {text.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3")}
        </a>
      </span>
    );
  };
  // function to render the table
  const renderTable = () => {
    return (
      <Table columns={columns} dataSource={data} pagination={{ pageSizeOptions: [10,25,50,100,200], defaultPageSize: 5 }} />
    );
  };
  // function to render the modal
  const renderModal = () => {
    return (
      <Modal
        title="Editar agente"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        <AgentForm
          user={selectedUser}
          handleCancel={handleCancel}
          handleOk={handleOk}
        />
      </Modal>
    );
  };
  // function to render the loading spinner
  const renderLoading = () => {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spin size="large" />
      </div>
    );
  };
  const renderPage = () => {
    return (
      <div className="bg-gray-100 flex items-start justify-center mt-24">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          {/* Verificar o tipo de usuário */}
          {user?.tipo?.level <= 1 || user?.tipo?.level === 2 ? (
            <button
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 sm:rounded-lg"
              onClick={() => {
                setSelectedUser(null);
                setIsModalVisible(true);
              }}
            >
              Criar um agente
            </button>
          ) : null}
          {/* refresh */}
          <Button
            type="primary"
            className="bg-blue-500 hover:bg-blue-600/20 ml-2 py-2 px-4"
            onClick={() => triggerUpdate()}
          >
            <RiRefreshLine />
          </Button>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4 p-4">
            {renderTable()}
            {renderModal()}
          </div>
        </div>
      </div>
    );
  };

  // function to render the page
  return <div>{isLoading ? renderLoading() : renderPage()}</div>;
};

export default AgentesPage;
