import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../../../../context/AuthContext";
import { Spin, message } from "antd";
import { CreateRelatorio, createObservacao } from "../../../../../api/Backend";
import { getResultById2, getResultById3 } from "../../../../../api/getSimInfo";
import { FaPlus } from "react-icons/fa";
import { RiArrowGoBackLine, RiFileTextLine, RiSave2Line } from "react-icons/ri";
import LeadReport from "../../../Relatorio";
import SimuladoModal from "../../../sales_sim/Modal/NewSimModal";
import { create_sim } from "../../../../../api/sendForm";

const SimTabelaComponent = () => {
  const [checkboxes, setCheckboxes] = useState<any[]>([
    { label: "Morte", value: 0 },
    {
      label: "Invalidez Absoluta e Definitiva",
      value: 3,
    },
    { label: "Médico Online", value: 19 },
    { label: "Médico ao Domicílio", value: 55 },
    {
      label: "Aconselhamneto Médico Telefónico",
      value: 56,
    },
    {
      label: "Invalidez Total e Permanente 60%",
      value: 26,
    },
    {
      label: "Invalidez Total e Permanente 65%",
      value: 27,
    },
    {
      label: "Morte por Acidente de Circulação",
      value: 5,
    },

    {
      label: "Doenças Graves e Medicina de Precisão",
      value: 52,
      extra: {
        risk52value: 1,
      },
    },

    {
      label: "Subsídio Diário por Hospitalização por Acidente",
      value: 18,
      extra: {
        risk18value: 25,
      },
    },

    {
      label: "Filhos Menores a Cargo",
      value: 62,
    },

    {
      label: "Morte por Acidente",
      value: 59,
    },
    {
      label: "Subsidio de Funeral",
      value: 63,
    },
  ]);

  const { id } = useParams<{ id: string }>();
  const { simId } = useParams();

  const { triggerUpdate } = useAuthContext();
  const navigate = useNavigate();

  const [data, setDados] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [riscos, setRiscos] = useState<any>(null);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [newSimData, setNewSimData] = useState({
    nome: "",
    email: "",
    phone: "",
    birthday: "",
    uuid: "",
    id: "",
    birthday2: "",
  });
  const [isNewSimModalOpen, setIsNewSimModalOpen] = useState(false);
  const [additionalData, setAdditionalData] = useState({
    user_id: "",
    referencia: "",
    username: "",
    created_at: "",
    id: "",
    saleSim: undefined,
  });
  if (window.location.href.includes("relatorio")) {
    sessionStorage.removeItem("simulacao");
  }

  const [simulations, setSimulations] = useState("undefined");
  const SaveSimulation = () => {
    // send to backend data to save it
    // send data to backend
    setLoading(true);

    // just send the data object, it is already made for the database
    create_sim(data)
      .then((res) => {
        setLoading(false);
        // go back to the lead
        message.success(
          "Simulação guardada com sucesso, a regressar á lead de origem"
        );
        // timout for 3 seconds then go backk to vida futura and reload
        setTimeout(() => {
          triggerUpdate();
          // fechar esta janela
          window.close();
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Ocorreu um erro ao guardar a simulação");
      });
  };
  const checkdata = sessionStorage.getItem("simulacao")
    ? JSON.parse(sessionStorage.getItem("simulacao") || "{}")
    : {};
  useEffect(() => {
    const fetchDadosSeguro = async () => {
      getResultById3(simId)
        .then((res) => {
          setDados(res);
        })
        .catch((err) => {
          // if error then show the message
          message.error("Ocorreu um erro");
        });
    };

    if (simId) {
      fetchDadosSeguro();
    }
  }, []);

  // if it is not set then should go use the checkData["vida-futura to set data"]
  useEffect(() => {
    // check if there is a simId
    if (!simId) {
      // get the data from the backend
      setDados(checkdata["vida-pleno"]);
    }
  }, []);
  const formatCurrency = (value: string | undefined) => {
    const numericValue = parseFloat(value as any);
    if (isNaN(numericValue)) {
      return "Invalid value";
    }

    return `${numericValue
      .toFixed(2)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}€`;
  };

  const closeReportModal = () => {
    setIsReportModalOpen(false)
  };

  const handleCloseModal = () => setIsNewSimModalOpen(false);

  const handleFormSubmit = (formData: any, observacao?: any) => {
    setLoading(true);

    CreateRelatorio(formData);

    if (observacao) {
      createObservacao(observacao);
    }

    closeReportModal();
    message.success("Relatório enviado com sucesso, a retornar ás leads");

    setTimeout(() => {
      navigate("/leads");
      window.location.reload();
    }, 1500);
  };

  window.onfocus = () => {
    // if (!isReportModalOpen) window.location.reload();
  };

  useEffect(() => {
    if (data) {
      const riscosCorrespondentes = checkboxes.filter((checkbox) =>
        data.dados_simulacao.risks.includes(checkbox.value)
      );
      setRiscos(riscosCorrespondentes);
    }
  }, [data]);

  if (!data) {
    return (
      <div className="bg-gray-200 min-h-screen flex items-center justify-center p-8">
        <div className="max-w-screen-lg w-full text-center">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">Detalhes do Seguro</h1>
      <div className="flex justify-end">
        {
          !simId && (
            <div className="flex justify-end">
              <button
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                onClick={SaveSimulation}
              >
                <RiSave2Line className="inline-block mr-2" />
                Guardar simulação
              </button>
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded ml-2"
                onClick={() => navigate(`/vida-futura/${id}`)}
              >
                <RiArrowGoBackLine className="inline-block mr-2" />
                Voltar
              </button>
            </div>
          )
          //  else show new voltar button
        }
      </div>

      {data && (
        <>
          <div className="container mx-auto p-8">
            <h2 className="text-2xl font-bold mb-4">Informações do Seguro</h2>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <tbody>
                <tr
                  className="border-b bg-blue-600
                 dark:bg-blue-800 dark:border-bg-blue-700"
                >
                  {" "}
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-blue-900 whitespace-nowrap dark:text-white"
                  >
                    Nome
                  </th>
                  <td
                    className="px-6 py-4"
                    style={{ color: "green", fontWeight: "bold" }}
                  >
                    {data.dados_simulacao.nome}
                  </td>
                </tr>
                <tr
                  className="border-b bg-blue-600
                 dark:bg-blue-800 dark:border-bg-blue-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-red-900 whitespace-nowrap dark:text-white"
                  >
                    Email
                  </th>
                  <td
                    className="px-6 py-4"
                    style={{ color: "green", fontWeight: "bold" }}
                  >
                    {data.dados_simulacao.email}
                  </td>
                </tr>
                <tr
                  className="border-b bg-blue-600
                 dark:bg-blue-800 dark:border-bg-blue-700
                  cursor-pointer
                 "
                  onClick={
                    // tel to
                    () => window.open(`tel:00351${data.dados_simulacao.phone}`)
                  }
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Contacto
                  </th>
                  <td
                    className="px-6 py-4"
                    style={{ color: "green", fontWeight: "bold" }}
                  >
                    +351{" "}
                    <a href={`tel:00351${data.dados_simulacao.phone}`}>
                      {data.dados_simulacao.phone}
                    </a>
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Capital Simulado
                  </th>
                  <td className="px-6 py-4">
                    {formatCurrency(data.dados_simulacao.capital)}
                  </td>
                </tr>
                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Data de início do contrato
                  </th>
                  <td className="px-6 py-4">
                    {/* {data.dados_simulacao.date} is yyyy-mm-ddThh:mm:ss.xxxZ make it only dd/mm/yyyy  */}
                    {data.dados_simulacao.date
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("-")}
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Número de titulares
                  </th>
                  <td className="px-6 py-4">
                    {data.dados_simulacao.numberOfInsurances}
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Data de Nascimento do titular
                  </th>
                  <td className="px-6 py-4">
                    {/* {data.dados_simulacao.birthDay1Insurance} is yyyy-mm-dd make it dd-mm-yyyy */}
                    {data.dados_simulacao.birthDay1Insurance
                      .split("-")
                      .reverse()
                      .join("-")}
                  </td>
                </tr>
                {data.dados_simulacao.birthDay2Insurance && (
                  <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Data de Nascimento do segundo titular
                    </th>
                    <td className="px-6 py-4">
                      {data.dados_simulacao.birthDay2Insurance}
                    </td>
                  </tr>
                )}
                {/* if payment type 68 is debito direto 77 referencia multibanco  show the payment tpe*/}
                {data.dados_simulacao.paymentType === 68 && (
                  <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Tipo de pagamento
                    </th>
                    <td className="px-6 py-4">Débito Direto</td>
                  </tr>
                )}
                {data.dados_simulacao.paymentType === 77 && (
                  <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Tipo de pagamento
                    </th>
                    <td className="px-6 py-4">Referência Multibanco</td>
                  </tr>
                )}

                {/* fracionamento  is mensal trimestral semestral ou anual*/}
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Fracionamento
                  </th>
                  <td className="px-6 py-4">
                    {data.dados_simulacao.fraccionamento}
                  </td>
                </tr>
                {/* risks is riscos value */}
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Riscos selecionados
                  </th>
                  <td className="px-6 py-4">
                    {riscos?.map((risco: any) => (
                      <div key={risco.value}>{risco.label}</div>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="container mx-auto p-8">
            <h2 className="text-2xl font-bold mb-4">Resultados da simulação</h2>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <tbody>
                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Valor Apresentado:
                  </th>
                  <td className="px-6 py-4">
                    {/* premiototalfrac must be rounded to decimals */}
                    {formatCurrency(data.resultado_simulacao.premioTotalFrac)} / Mensal
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Relatório */}
          <LeadReport
            isOpen={isReportModalOpen}
            onRequestClose={closeReportModal}
            onSubmit={handleFormSubmit}
            additionalData={additionalData}
          />

          <SimuladoModal
            isOpen={isNewSimModalOpen}
            onRequestClose={handleCloseModal}
            dataRecieved={newSimData}
          />
        </>
      )}
    </div>
  );
};

export default SimTabelaComponent;
