import React, { useEffect, useState } from "react";
import { Table, Button, message, Spin, DatePicker } from "antd";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { FiTrash2 } from "react-icons/fi";
import { deleteRelatorio } from "../../api/Backend";
import { report } from "process";
import { RiRefreshLine } from "react-icons/ri";
import moment, { Moment } from "moment";
import axios from "axios";
import locale from "antd/es/date-picker/locale/pt_PT";

const backendApi = process.env.REACT_APP_BACKEND_API;
const SvIp = process.env.REACT_APP_SERVER_IP;
const { RangePicker } = DatePicker;

interface Report {
  attributes: {
    name: string;
    simulacao: {
      Simulador_vida: string;
    };
    estado: string;
    observacoes: string;
    reagendado: string;
    outros: string;
    fechada: boolean;
  };
  id: number;
}

const ReportsPage: React.FC = () => {
  const navigate = useNavigate();
  const { relatorios, isLoading, triggerUpdate, user, simuladorData } =
    useAuthContext();
  const [dateRange, setDateRange] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  // set loading
  const [loading, setLoading] = useState<boolean>(false);

  const getEstadoColor = (estado: string): string => {
    switch (estado) {
      case "Fechada":
        return "#ff4d4f";
      case "Aberta":
        return "#ffa940";
      case "Entregue":
        return "#73d13d";
      case "Reagendada":
        return "#9254de";
      case "Cancelada":
        return "#595959";
      case "Falsa":
        return "#ffec3d";
      case "Repetida":
        return "#40a9ff";
      case "Outro":
        return "#ff7a45";
      case "Contacto Errado":
        return "#2596be"; // Cor a definir para este estado
      case "Não atendeu":
        return "#e28743"; // Cor a definir para este estado
      case "Não vai avançar":
        return "#abdbe3"; // Cor a definir para este estado

      default:
        return "#000000";
    }
  };

  const columns = [
    // adicionar nome do agente
    {
      title: "Agente",
      dataIndex: [
        "attributes",
        "simulacao",
        "data",
        "attributes",
        "user",
        "data",
        "attributes",
        "username",
      ],
      key: "agente",
    },
    {
      title: "Nome",
      dataIndex: ["attributes", "simulacao", "data", "attributes", "nome"],
      key: "name",
      // deverá mostrar até ao maximo de 24 caracteres
      render: (text: string, record: any) => (
        <span>{text.length > 14 ? text.substring(0, 14) + "..." : text}</span>
      ),
    },
    {
      title: "ID Simulação",
      dataIndex: ["attributes", "simulacao", "data", "id"],
      key: "id",
      render: (text: string, record: any) => (
        <a
          href={`/${record.attributes.simulacao.data.attributes.tipo_simulacao.data.attributes.slug}/${record.attributes.simulacao.data.attributes.uuid}`}
        >
          {record.attributes.simulacao.data.attributes.uuid}
        </a>
      ),
    },
    {
      title: "Estado",
      dataIndex: ["attributes", "estado"],
      key: "estado",
      render: (text: string, record: any) => (
        <div className="flex flex-col items-center gap-2">
          <span
            className="text-xs"
            style={{
              backgroundColor: getEstadoColor(record.attributes.estado),
              borderRadius: "5px",
              padding: "5px",
              color: "black",
            }}
          >
            {text}
          </span>
          {record.attributes.estado === "Reagendada" &&
            record.attributes.data_reagendamento && (
              <>
                <span className="text-xs">
                  {new Date(
                    record.attributes.data_reagendamento
                  ).toLocaleString("pt-PT", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </span>
                <span className="text-xs">
                  {new Date(
                    record.attributes.data_reagendamento
                  ).toLocaleString("pt-PT", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </>
            )}
        </div>
      ),
    },
    {
      title: "Data de Criação",
      dataIndex: ["attributes", "createdAt"],
      key: "createdAt",
      render: (text: string) => moment(text).format("DD/MM/YYYY"), // Format the date
    },
    {
      title: "Relatório",
      key: "action",
      render: (text: string, record: any) => (
        <div>
          <Button
            type="primary"
            className="bg-blue-500 hover:bg-blue-600/20"
            onClick={() => navigate(`/relatorio/${record.id}`)}
          >
            <AiOutlineEye />
          </Button>
          <Button
            type="primary"
            className="bg-red-500 hover:!bg-red-600/70 ml-2 mr-2"
            onClick={() => {
              const confirmation = window.confirm(
                `Tem a certeza que quer apagar o relatório de ${record.attributes.simulacao.data.attributes.nome}?`
              );
              if (confirmation) {
                deleteRelatorio(record.id);
                triggerUpdate();
              }
            }}
          >
            <FiTrash2 />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    // Only attempt to filter if dateRange has both start and end dates
    if (dateRange.length === 2) {
      const [startDate, endDate] = dateRange;

      // Convert start and end strings back to moment objects for comparison
      const startMoment = moment(startDate);
      const endMoment = moment(endDate);

      const filteredData = relatorios.filter((report) => {
        // Assuming report.attributes.createdAt is a string in ISO format
        const reportDate = moment(report.attributes.createdAt);

        // Check if the report date is between the start and end dates (inclusive)
        return reportDate.isBetween(startMoment, endMoment, null, "[]");
      });

      setFilteredData(filteredData);
    } else {
      // show only the current month relatorios

      const filteredData = relatorios.filter((report) => {
        const reportDate = moment(report.attributes.createdAt);
        // print the return before returning
        return reportDate.isSame(moment(), "month");
      });
      setFilteredData([...filteredData]);
    }
  }, [relatorios, dateRange]);

  const handleDateChange = (dates: any, dateStrings: any) => {
    if (dates) {
      // Now format to strings
      const start = dates[0].startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      const end = dates[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      setDateRange([start, end]);
    } else {
      setDateRange([]);
    }
  };

  const handleRefresh = () => {
    triggerUpdate();
  };

  useEffect(() => {
    if (relatorios.length > 0) {
    }
  }, [filteredData]);

  const handleExportToExcel = async () => {
    setLoading(true);

    // set variables with username start date and end date if is not set then set day 1 of current month to end of month
    const username = user?.username;
    const startDate = dateRange[0]
      ? moment(dateRange[0]).format("DD-MM-YYYY")
      : moment().startOf("month").format("DD-MM-YYYY");
    const endDate = dateRange[1]
      ? moment(dateRange[1]).format("DD-MM-YYYY")
      : moment().endOf("month").format("DD-MM-YYYY");

    //  turn to json object
    const exportData = {
      username,
      startDate,
      endDate,
    };

    const json = JSON.stringify(exportData);
    try {
      const response = await axios.post(
        `${backendApi}/generate-report`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
            "x-server-ip-for": SvIp,
          },
        }
      );
      const { output } = response.data;
      // if message is ERRO then return
      if (response.data.message === "ERRO") {
        message.warning(
          "Erro ao exportar o relatório, sem dados para exportar!"
        );
        setLoading(false);
        return;
      } else {
        // Cria um elemento de link
        const link = document.createElement("a");
        link.setAttribute("download", true as any); // O atributo download pode ser definido para true se você quiser forçar o download
        link.setAttribute("href", output); // Utiliza diretamente `output` como o href
        document.body.appendChild(link);

        // Simula o clique no link para disparar o download
        link.click();

        // Remove o link do DOM
        document.body.removeChild(link);
        message.success("Relatório exportado com sucesso!");
        setLoading(false);

      }
    } catch (error) {
      console.error(error);
      message.error("Erro ao exportar relatório!");
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 flex justify-center">
      {isLoading || loading ? (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-100 flex items-center justify-center">
          <Spin />
        </div>
      ) : (
        <div className="w-full p-12 mx-auto px-4 sm:px-6 lg:px-8  items-start">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4 p-4">
            <div className="mb-4">
              <Button
                type="primary"
                className="bg-blue-500 hover:bg-blue-600/20 mr-4"
                onClick={handleRefresh}
              >
                <RiRefreshLine />
              </Button>
              <RangePicker
                locale={locale} // Use a localização correta aqui
                className="mr-4"
                onChange={handleDateChange}
              />
              {/* caso seja level <= 3 mostrar o botao */}
              {user?.tipo?.level <= 3 && (
                <Button
                  type="primary"
                  className="bg-green-500 hover:bg-green-600/20 ml-4"
                  onClick={handleExportToExcel}
                >
                  Exportar para Excel
                </Button>
              )}
            </div>
            <Table
              columns={columns}
              dataSource={filteredData}
              pagination={{ pageSizeOptions: [10, 25, 50, 100, 200], defaultPageSize: 5 }}
              rowKey={(record: any) => record.id}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportsPage;
