import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../../../context/AuthContext";
import { Spin, message } from "antd";
import { CreateRelatorio, createObservacao } from "../../../../api/Backend";
import { getResultById2 } from "../../../../api/getSimInfo";
import { FaPlus } from "react-icons/fa";
import { RiArrowGoBackLine, RiFileTextLine } from "react-icons/ri";
import LeadReport from "../../Relatorio";
import SimuladoModal from "../../sales_sim/Modal/NewSimModal";

const TabelaComponent = () => {
  const [checkboxes, setCheckboxes] = useState<any[]>([
    { label: "Morte", value: 0 },
    {
      label: "Invalidez Absoluta e Definitiva",
      value: 3,
    },
    { label: "Médico Online", value: 19 },
    { label: "Médico ao Domicílio", value: 55 },
    {
      label: "Aconselhamneto Médico Telefónico",
      value: 56,
    },
    {
      label: "Invalidez Total e Permanente 60%",
      value: 26,
    },
    {
      label: "Invalidez Total e Permanente 65%",
      value: 27,
    },
    {
      label: "Morte por Acidente de Circulação",
      value: 5,
    },

    {
      label: "Doenças Graves e Medicina de Precisão",
      value: 52,
      extra: {
        risk52value: 1,
      },
    },

    {
      label: "Subsídio Diário por Hospitalização por Acidente",
      value: 18,
      extra: {
        risk18value: 25,
      },
    },

    {
      label: "Filhos Menores a Cargo",
      value: 62,
    },

    {
      label: "Morte por Acidente",
      value: 59,
    },
    {
      label: "Subsidio de Funeral",
      value: 63,
    },
  ]);

  const { id } = useParams<{ id: string }>();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const [data, setDados] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [riscos, setRiscos] = useState<any>(null);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [newSimData, setNewSimData] = useState({
    nome: "",
    email: "",
    phone: "",
    birthday: "",
    uuid: "",
    id: "",
    birthday2: "",
  });
  const [isNewSimModalOpen, setIsNewSimModalOpen] = useState(false);
  const [additionalData, setAdditionalData] = useState({
    user_id: "",
    referencia: "",
    username: "",
    created_at: "",
    id: "",
    saleSim: undefined,
  });

  useEffect(() => {
    const fetchDadosSeguro = async () => {
      const dados2 = await getResultById2(id);
      if (dados2.resultado_simulacao.result) {
        dados2.resultado_simulacao = dados2.resultado_simulacao.result;
      }
      setDados(dados2);
      setLoading(false);
    };
    fetchDadosSeguro();
  }, [id]);

  const formatCurrency = (value: string | undefined) => {
    const numericValue = parseFloat(value as any);

    // If the value is 0, return "0,00€"
    if (numericValue === 0) {
      return "0,00€";
    }

    // If the value is not a valid number, return an empty string
    if (isNaN(numericValue)) {
      return "";
    }

    return `${numericValue
      .toFixed(2)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}€`;
  };

  const closeReportModal = () => {
    setIsReportModalOpen(false)
  };

  const handleCloseModal = () => setIsNewSimModalOpen(false);

  const openReportModal = () => {
    const date = new Date(data?.createdAt);
    const dateStr = date.toLocaleDateString("pt-PT");

    setAdditionalData({
      user_id: user?.id.toString() || "",
      referencia: data?.uuid,
      username: user?.username || "",
      created_at: dateStr,
      id: data?.idSimuladorVida,
      saleSim: data?.saleSim,
    });

    setIsReportModalOpen(true);
  };

  const handleFormSubmit = (formData: any, observacao?: any) => {
    setLoading(true);

    CreateRelatorio(formData);

    if (observacao) {
      createObservacao(observacao);
    }

    closeReportModal();
    message.success("Relatório enviado com sucesso, a retornar ás leads");

    setTimeout(() => {
      navigate("/leads");
      window.location.reload();
    }, 1500);
  };

  window.onfocus = () => {
    // if (!isReportModalOpen) window.location.reload();
  };

  const openNewSimModal = () => {
    setNewSimData({
      nome: data?.nome,
      email: data?.email,
      phone: data?.phone,
      birthday: data?.dados_simulacao.birthDay1Insurance,
      uuid: data?.uuid,
      id: data?.idSimuladorVida || "",
      birthday2: data?.dados_simulacao.birthDay2Insurance || "",
    });
    setIsNewSimModalOpen(true);
  };
  useEffect(() => {
    if (data && data.resultado_simulacao.risks) {


      const riskValues = data.resultado_simulacao.risks
        .substring(1, data.resultado_simulacao.risks.length - 1)
        .split(",");

      // Converte os números de strings para números inteiros
      const parsedRisks = riskValues.map((value: any) => parseInt(value.trim(), 10));

      const riscosCorrespondentes = checkboxes.filter((checkbox) =>
        parsedRisks.includes(checkbox.value)
      );
      setRiscos(riscosCorrespondentes);
    }
  }, [data, checkboxes]);


  if (!data) {
    return (
      <div className="bg-gray-200 min-h-screen flex items-center justify-center p-8">
        <div className="max-w-screen-lg w-full text-center">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">Detalhes do Seguro</h1>
      <div className="flex justify-end">
        {/* <button
          className="bg-blue-200 hover:bg-blue-200 text-white px-4 py-2 rounded mr-2"
          onClick={openNewSimModal}
        >
          <FaPlus className="inline-block mr-2" />
          Gerar Nova Simulação
        </button> */}
        <button
          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
          onClick={openReportModal}
        >
          <RiFileTextLine className="inline-block mr-2" />
          Gerar Relatório
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded ml-2"
          onClick={() => navigate("/leads")}
        >
          <RiArrowGoBackLine className="inline-block mr-2" />
          Voltar
        </button>
      </div>

      {data && (
        <>
          <div className="container mx-auto p-8">
            <h2 className="text-2xl font-bold mb-4">Informações do Seguro</h2>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <tbody>
                <tr className="border-b bg-gray-100 dark:bg-gray-800 dark:border-gray-700">
                  {" "}
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-blue-900 whitespace-nowrap dark:text-white"
                  >
                    Nome
                  </th>
                  <td
                    className="px-6 py-4"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    {data.dados_simulacao.nome}
                  </td>
                </tr>
                <tr className="border-b bg-gray-100 dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-red-900 whitespace-nowrap dark:text-white"
                  >
                    Email
                  </th>
                  <td
                    className="px-6 py-4"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    {data.dados_simulacao.email}
                  </td>
                </tr> <tr className="border-b bg-gray-100 dark:bg-gray-800 dark:border-gray-700
                  cursor-pointer
                 "
                  onClick={
                    // tel to
                    () => window.open(`tel:00351${data.dados_simulacao.phone}`)
                  }
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Contacto
                  </th>
                  <td
                    className="px-6 py-4"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    +351{" "}
                    <a href={`tel:00351${data.dados_simulacao.phone}`}>
                      {data.dados_simulacao.phone}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-8">

            <div className="container mx-auto p-8">
              <h2 className="text-2xl font-bold mb-4">Resultados da simulação</h2>
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <tbody>
                  <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Capital Simulado</th>
                    <td className="px-6 py-4">{formatCurrency(data.resultado_simulacao?.capital)}</td>
                  </tr>
                  <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                    <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Data de início do contrato</th>
                    <td className="px-6 py-4">{data.resultado_simulacao?.date.split("T")[0].split("-").reverse().join("-")}</td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Número de titulares</th>
                    <td className="px-6 py-4">{data.resultado_simulacao?.numberOfInsurances}</td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Data de Nascimento do titular</th>
                    <td className="px-6 py-4">{data.resultado_simulacao?.birthDay1Insurance.split("T")[0].split("-").reverse().join("-")}</td>
                  </tr>
                  {data.resultado_simulacao?.birthDay2Insurance && (
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Data de Nascimento do segundo titular</th>
                      <td className="px-6 py-4">
                        {data.resultado_simulacao?.birthDay2Insurance
                          ? data.resultado_simulacao.birthDay2Insurance.split("T")[0].split("-").reverse().join("-")
                          : ""}
                      </td>
                    </tr>
                  )}
                  {data.resultado_simulacao?.paymentType === 68 && (
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Tipo de pagamento</th>
                      <td className="px-6 py-4">Débito Direto</td>
                    </tr>
                  )}
                  {data.resultado_simulacao?.paymentType === 77 && (
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Tipo de pagamento</th>
                      <td className="px-6 py-4">Referência Multibanco</td>
                    </tr>
                  )}
                  <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Fracionamento</th>
                    <td className="px-6 py-4">{data.resultado_simulacao?.fraccionamento}</td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">Riscos selecionados</th>
                    <td className="px-6 py-4">
                      {riscos?.map((risco: any) => (
                        <div key={risco.value}>{risco.label}</div>
                      ))}
                    </td>
                  </tr>
                  <tr className="border-b bg-yellow-100 dark:bg-yellow-900 dark:border-yellow-700">
                    <th className="px-6 py-4 font-semibold text-xl text-gray-900 whitespace-nowrap dark:text-white">
                      Valor Apresentado:
                    </th>
                    <td className="px-6 py-4 font-semibold text-xl text-gray-900 dark:text-white">
                      {formatCurrency(data.resultado_simulacao?.premioTotalFrac)} / Mensal
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            
            <div className="container mx-auto p-8">
              <h2 className="text-2xl font-bold mb-4">Lista de Coberturas RVP</h2>
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead>
                  <tr className="border-b bg-gray-100 dark:bg-gray-800 dark:border-gray-700">
                    <th className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">Nome</th>
                    <th className="px-6 py-3 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white">Idade limite de subscrição</th>
                    <th className="px-6 py-3 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white">Prémio</th>
                    <th className="px-6 py-3 text-center font-medium text-gray-900 whitespace-nowrap dark:text-white">Capital</th>
                  </tr>
                </thead>
                <tbody>
                  {JSON.parse(data.resultado_simulacao?.listoCoberturasRVP).map((cobertura: any, index: any) => (
                    <tr key={index} className="border-b bg-white dark:bg-gray-900 dark:border-gray-700">
                      <td className="px-6 py-4">{cobertura.nome}</td>
                      <td className="px-6 py-4 text-center">{cobertura.limiteSubscricao} Anos</td>
                      <td className="px-6 py-4 text-center">{formatCurrency(cobertura.premio)}</td>
                      <td className="px-6 py-4 text-center">{formatCurrency(cobertura.capital)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          

          {/* Relatório */}
          <LeadReport
            isOpen={isReportModalOpen}
            onRequestClose={closeReportModal}
            onSubmit={handleFormSubmit}
            additionalData={additionalData}
          />

          <SimuladoModal
            isOpen={isNewSimModalOpen}
            onRequestClose={handleCloseModal}
            dataRecieved={newSimData}
          />
        </>
      )}
    </div>
  );
};

export default TabelaComponent;
