import React, { useState, useEffect } from "react";
import { FaCalendar } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import { message, Spin } from "antd";
import axios from "axios";
import { useAuthContext } from "../../../../context/AuthContext";
import { GeralSubmitToBackEnd } from "../../../../api/sendForm";
import CheckboxList from "../../../checkboxList";

const backendApi = process.env.REACT_APP_BACKEND_API;
const SvIp = process.env.REACT_APP_SERVER_IP;
interface FormData {
  name: string;
  actualizacao_capitais: string | null;
  capital: string | null; // 'capital' corresponds to some string value
  data_inicio_contrato: Date | null; // 'data_inicio_contrato' corresponds to some string value
  data_nasc_1: Date | null; // 'data_nasc_1' corresponds to some string value
  data_nasc_2: Date | null; // 'data_nasc_1' corresponds to some string value
  endpoint: string | null; // 'endpoint' corresponds to some string value
  invalidez_60: string | null; // 'invalidez_60' corresponds to some string value
  mensalidade: string | null; // 'mensalidade' corresponds to some string value
  titulares: string | null; // 'titulares' corresponds to some string value
}
type RequestData = {
  capital: string;
  data_inicio_contrato: string;
  data_nasc_1: string;
  data_nasc_2?: string; // Making data_nasc_2 optional
  mensalidade: string;
  titulares: string;
  actualizacao_capitais: string;
  invalidez_60?: string;
  endpoint: string;
};

interface UserData {
  id: number;
  username: string;
  email: string;
  confirmed: boolean;
  blocked: boolean;
  role: {
    id: number;
    name: string;
    description: string;
    type: string;
  };
  tipo: {
    id: number;
    nivel: string;
    cor: string;
  };
  phone: string;
  equipa: {
    id: number;
    nome_equipa: string;
    zona: {
      id: number;
      nome_zona: string;
    };
  };
}
interface Checkbox {
  label: string;
  checked: boolean;
  disabled: boolean;
  value: number;
  extra?: any;
}

const Habitacao: React.FC = () => {
  // formdata
  const [formData, setFormData] = useState<FormData>({
    name: "",
    actualizacao_capitais: null,
    capital: null,
    data_inicio_contrato: null,
    data_nasc_1: null,
    data_nasc_2: null,
    endpoint: null,
    invalidez_60: null,
    mensalidade: null,
    titulares: null,
  });

  // email and phone
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [result, setResult] = useState<any>(null);
  const [error, setError] = useState<string>("");
  const { users, simuladorData, isLoading } = useAuthContext();
  //make a variable to send available users
  const [availableUsers, setAvailableUsers] = useState<UserData[]>([]);
  //make a variable to send available users
  // Adicione um novo estado para controlar se são um ou dois titulares
  const [titulares, settitulares] = useState<number>(1);

  // set isLoading2
  const [isLoading2, setIsLoading2] = useState(false);

  // Adicione um novo estado para controlar a opção de atualização automática do capital seguro
  const [autoUpdateCapital, setAutoUpdateCapital] = useState<string>("1");
  const [checkboxes, setCheckboxes] = useState<Checkbox[]>([
    { label: "Morte", checked: true, disabled: true, value: 10 },
    {
      label: "Morte por Acidente (oferta)",
      checked: true,
      disabled: true,
      value: 25,
    },
    {
      label: "Filhos Menores a Cargo (oferta)",
      checked: true,
      disabled: true,
      value: 50,
    },
    {
      label: "Invalidez Absoluta e Definitiva",
      checked: true,
      disabled: true,
      value: 75,
    },
    {
      label: "Invalidez Total e Permanente 60%",
      checked: false,
      disabled: false,
      value: 100,
    },
  ]);

  useEffect(() => {
    if (users && simuladorData) {
      // Supondo que o nível mais baixo (por exemplo, 5) represente os agentes
      const lowestLevel = Math.max(
        ...users.map((user) => user.tipo?.level || 0)
      );

      // Criar um conjunto com os IDs dos usuários que têm leads
      const userIdsWithLeads = new Set(
        simuladorData.map((lead) => lead?.attributes?.user?.data?.id)
      );

      // Filtrar agentes disponíveis que têm o level mais baixo e não têm leads
      let agentesDisponiveis = users.filter(
        (user) =>
          user.tipo?.level === lowestLevel && !userIdsWithLeads.has(user.id)
      );

      if (agentesDisponiveis.length === 0 && users.length > 0) {
        // Se não houver agentes disponíveis, selecionar um aleatoriamente de todos os agentes com o level mais baixo
        const agentesComLevelMaisBaixo = users.filter(
          (user) => user.tipo?.level === lowestLevel
        );
        const randomAgentIndex = Math.floor(
          Math.random() * agentesComLevelMaisBaixo.length
        );
        agentesDisponiveis = [agentesComLevelMaisBaixo[randomAgentIndex]];
      }

      setAvailableUsers(agentesDisponiveis);
    }
  }, [simuladorData, users]);

  // if is loading show loading page

  if (isLoading2 || isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );
  }

  function transformData(data: any) {
    function formatDate(dateString: any) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }

    const transformedData = {
      ...data,
      data_nasc_1: formatDate(data.data_nasc_1),
      data_nasc_2: data.data_nasc_2 ? formatDate(data.data_nasc_2) : null,
      data_inicio_contrato: formatDate(data.data_inicio_contrato),
    };
    return transformedData;
  }
  function formatDate(dateString: any) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    // set loading true
    setIsLoading2(true);
    e.preventDefault();

    const requestData: RequestData = {
      capital: formData.capital || "",
      data_inicio_contrato: formatDate(formData.data_inicio_contrato) || "",
      data_nasc_1: formatDate(formData.data_nasc_1) || "",
      mensalidade: formData.mensalidade || "",
      titulares: titulares.toString(),
      actualizacao_capitais: autoUpdateCapital,
      endpoint: "simulador/simular/habitacao",
    };
    if (formData.data_nasc_2) {
      // add data_nasc_2 to the requestdata
      requestData.data_nasc_2 = formatDate(formData.data_nasc_2);
    }
    // if checkbox with value 100 is checked then set invalidez_60 = "1"
    if (checkboxes[4].checked) {
      requestData.invalidez_60 = "1";
    }
    // Convert the requestData to JSON
    const json = JSON.stringify(requestData);

    try {
      // must post but must also wait for the result from psot

      const response = await axios.post(
        `${backendApi}/habitacao`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
            "x-server-ip-for": SvIp,
          },
        }
      );

      // make a 3 seconds pause before keep going
      await new Promise((resolve) => setTimeout(resolve, 3000));

      // response here on error
      // {"status":"ERROR","field":"capital","message":" Insira um capital válido."}

      // habdle if resonse is given
      if (response.data.status === "ERROR") {
        setError(response.data.message);
        setIsLoading2(false);
        message.error(error);
        return;
      }
      // get the response and put it into a variable called res
      const res = response.data;
      setResult(res);

      // if result is null send error
      if (result === null) {
        setError("Erro ao fazer simulação");
        setIsLoading2(false);
        return;
      }

      const formDataToSend2 = new FormData();
      formDataToSend2.append("resultado_simulacao", JSON.stringify(result));
      formDataToSend2.append("nome", formData.name);
      formDataToSend2.append("email", email);
      formDataToSend2.append("phone", phone);
      formDataToSend2.append("endpoint", "simulador/simular/habitacao");
      formDataToSend2.append(
        "tipo_simulacao",
        JSON.stringify({
          simulador: "Vida Habitação",
        })
      );
      formDataToSend2.append("dados_simulacao", json);

      const response2 = await GeralSubmitToBackEnd(
        formDataToSend2,
        availableUsers
      );
      //  if response2 is not undefined send to vida-habitacao/response2 else send to leads
      if (response2 === undefined) {
        setTimeout(() => {
          window.location.href = `/leads`;
        }, 4000);
      } else {
        setTimeout(() => {
          window.location.href = `/vida-habitacao/${response2}`;
        }, 4000);
      }

      setError("");

      message.success("Simulação feita com sucesso");

      // send to leads after 1 second
    } catch (error) {
      console.error(error);
      // set loading false and message error
      setIsLoading2(false);
      message.error("Erro ao fazer simulação");
      return;
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleBirthdayChange = (date: Date | null) => {
    setFormData((prevData) => ({
      ...prevData,
      data_nasc_1: date,
    }));
  };
  const handleBirthdayChange2 = (date: Date | null) => {
    setFormData((prevData) => ({
      ...prevData,
      data_nasc_2: date,
    }));
  };

  const handleCheckboxChange = (label: string, checked: boolean) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.label === label ? { ...checkbox, checked } : checkbox
    );
    setCheckboxes(updatedCheckboxes);
  };

  const handleSelectChange = (value: number, checkboxIndex: number) => {
    const updatedCheckboxes = checkboxes.map((checkbox, index) =>
      index === checkboxIndex
        ? {
            ...checkbox,
            extra: {
              risk52value: value,
            },
          }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center ">
      <div className="max-h-screen ">
        <h2 className="text-2xl font-bold mb-4">
          Simulação do seguro de Habitação
        </h2>
        <form onSubmit={handleSubmit} className="max-w-md">
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Nome
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded-md"
              required
            />
          </div>

          {/* email do cliente */}
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleChangeEmail}
              className="mt-1 p-2 w-full border rounded-md"
              required
            />
          </div>

          {/* telefone do cliente */}
          <div className="mb-6">
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Telefone
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="mt-1 p-2 w-full border rounded-md"
              required
            />
          </div>

          {/* Adicione um novo campo para escolher entre um ou dois titulares */}
          <div className="mb-4">
            <label
              htmlFor="titulares"
              className="block text-sm font-medium text-gray-700"
            >
              Número de Titulares
            </label>
            <select
              name="titulares"
              value={titulares}
              onChange={(e) => settitulares(Number(e.target.value))}
              className="mt-1 p-2 w-full border rounded-md"
            >
              <option value={1}>1 Titular</option>
              <option value={2}>2 Titulares</option>
            </select>
          </div>

          <div className="mb-4">
            <label
              htmlFor="birthday"
              className="block text-sm font-medium text-gray-700"
            >
              Data de Nascimento do Titular
            </label>
            <div className="relative mt-1 p-2 w-full border rounded-md">
              <DatePicker
                selected={formData.data_nasc_1}
                onChange={(date: Date | null) => handleBirthdayChange(date)}
                dateFormat="dd-MM-yyyy"
                required
                className="w-full h-full p-2"
                wrapperClassName="w-full h-full"
                maxDate={
                  new Date(
                    // 18 + 6 meses ago
                    new Date().getTime() -
                      18 * 365 * 24 * 60 * 60 * 1000 -
                      6 * 30 * 24 * 60 * 60 * 1000
                  )
                }
                minDate={
                  new Date(
                    // 57years + 6 months years ago
                    new Date().getTime() -
                      57 * 365 * 24 * 60 * 60 * 1000 -
                      6 * 30 * 24 * 60 * 60 * 1000
                  )
                }
                locale={pt}
                closeOnScroll={true}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd-mm-yyyy"
              />
              <span className="absolute right-2 top-4">
                <FaCalendar style={{ color: "#9CFFA5" }} />
              </span>
            </div>
          </div>

          {titulares === 2 && (
            <div className="mb-4">
              <label
                htmlFor="secondTitular.birthday"
                className="block text-sm font-medium text-gray-700"
              >
                Data de Nascimento do Segundo Titular
              </label>
              <div className="relative mt-1 p-2 w-full border rounded-md">
                <DatePicker
                  selected={formData.data_nasc_2}
                  onChange={(date: Date | null) => handleBirthdayChange2(date)}
                  dateFormat="dd-MM-yyyy"
                  required
                  className="w-full h-full p-2"
                  wrapperClassName="w-full h-full"
                  maxDate={
                    new Date(
                      // 18 + 6 meses ago
                      new Date().getTime() -
                        18 * 365 * 24 * 60 * 60 * 1000 -
                        6 * 30 * 24 * 60 * 60 * 1000
                    )
                  }
                  minDate={
                    new Date(
                      // 57years + 6 months years ago
                      new Date().getTime() -
                        57 * 365 * 24 * 60 * 60 * 1000 -
                        6 * 30 * 24 * 60 * 60 * 1000
                    )
                  }
                  locale={pt}
                  closeOnScroll={true}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd-mm-yyyy"
                />
                <span className="absolute right-2 top-4">
                  <FaCalendar style={{ color: "#9CFFA5" }} />
                </span>
              </div>
            </div>
          )}

          <div className="mb-4">
            <label
              htmlFor="contract"
              className="block text-sm font-medium text-gray-700"
            >
              Data de inicio do contrato
            </label>
            <div className="relative mt-1 p-2 w-full border rounded-md">
              <DatePicker
                selected={formData.data_inicio_contrato}
                onChange={(date: Date | null) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    data_inicio_contrato: date,
                  }))
                }
                dateFormat="dd-MM-yyyy"
                minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
                maxDate={
                  new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
                } // 30 days from today
                required
                dropdownMode="select"
                locale={pt}
                placeholderText="dd-mm-yyyy"
                className="w-full h-full p-2"
                wrapperClassName="w-full h-full"
              />
              <span className="absolute right-2 top-4">
                <FaCalendar style={{ color: "#9CFFA5" }} />
              </span>
            </div>
          </div>
          {/* Actualização Automática do Capital Seguro -> sim ou nao  */}
          <div className="mb-4">
            <label
              htmlFor="autoUpdateCapital"
              className="block text-sm font-medium text-gray-700"
            >
              Actualização Automática do Capital Seguro
            </label>
            <select
              name="autoUpdateCapital"
              value={autoUpdateCapital}
              onChange={(e) => setAutoUpdateCapital(e.target.value)}
              className="mt-1 p-2 w-full border rounded-md"
            >
              <option value="1">Sim</option>
              <option value="0">Não</option>
            </select>
          </div>
          {/* input para o capital em divida */}
          <div className="mb-4">
            <label
              htmlFor="capital"
              className="block text-sm font-medium text-gray-700"
            >
              Capital em dívida
            </label>
            <input
              type="number"
              // capital
              id="capital"
              name="capital"
              value={formData.capital || ""}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded-md"
              required
            />
          </div>
          {/* input para mensalidade do seguro atual */}
          <div className="mb-4">
            <label
              htmlFor="mensalidade"
              className="block text-sm font-medium text-gray-700"
            >
              Mensalidade do seguro atual
            </label>
            <input
              type="number"
              id="mensalidade"
              name="mensalidade"
              value={formData.mensalidade || ""}
              onChange={handleChange}
              className="mt-1 p-2 w-full border rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="radAwardCapital"
              className="block text-sm font-medium text-gray-700"
            >
              Coberturas
            </label>

            <CheckboxList
              checkboxes={checkboxes}
              onCheckboxChange={handleCheckboxChange}
              onSelectChange={handleSelectChange as any}
            />
          </div>
          {/* submit button */}
          <div className="flex justify-center items-center pb-24">
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-all mb-4"
            >
              Simular
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Habitacao;
