import React, { useEffect, useState } from "react";
import { Table, Spin, Button, message, Select, Checkbox } from "antd";
import { useAuthContext } from "../context/AuthContext";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import EditLeadModal from "./EditLeadModal";
import { updateLead } from "../api/Backend";
import Search from "antd/es/input/Search";
import { startOfWeek, endOfWeek } from "date-fns";
import { RiRefreshLine } from "react-icons/ri";

interface Lead {
  attributes: {
    nome: string;
    email: string;
    phone: string;
    uuid: string;
    user: {
      data: {
        id: number;
        attributes: {
          username: string;
        };
      };
    };
    estado: string;
    data_reagendamento?: string;
  };
  id: number;
  tipo_simulacao: {
    data: {
      id: number;
      attributes: {
        simulador: string;
        categoria: string;
        slug: string;
      };
    };
  };
}

const LeadsPage: React.FC = () => {
  const semanaAtual = {
    start: startOfWeek(new Date()),
    end: endOfWeek(new Date()),
  };
  const navigate = useNavigate();

  const { filters } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { simuladorData, isLoading, users, triggerUpdate, user } = useAuthContext();
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [searchText, setSearchText] = useState("");
  const [agentFilter, setAgentFilter] = useState("");
  const [stateFilter, setStateFilter] = useState("");
  const [loading, setLoading] = useState(false);
  // Set show fechadas variable as boolean
  const [showFechadas, setShowFechadas] = useState(false);
  const [filterText, setFilterText] = useState("Todos os filtros");

  const handleAgentFilter = (value: string) => {
    setAgentFilter(value);
  };

  const handleStateFilter = (value: string) => {
    setStateFilter(value === filterText ? "" : value);
  };

  // only level 0 to 4 can edit leads give can edit a value of true
  const [canEdit, setCanEdit] = useState(false);

  // check user level is higher than 4 if so canedit stays false else canedit is true
  useEffect(() => {
    // userlevel is in user.tipo.level
    if (user) {
      const userLevel = user.tipo.level;
      if (userLevel <= 4) {
        setCanEdit(true);
      }
    }
  }, [user]);


  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  // useEffect
  useEffect(() => {
    // if filters exists then check if filter is abertas or fechadas
    if (filters) {
      if (filters === "abertas") {
        setStateFilter("");
        setFilterText("Aberta");
      }
    }
  }, [filters]);

  const renderPhone = (text: string) => {
    const spaceIndex = text.indexOf(" ");

    if (spaceIndex !== -1) {
      const visiblePart = text.slice(0, spaceIndex + 1);
      const hiddenPart = text.slice(spaceIndex + 1, -4).replace(/./g, "*");
      const lastFourDigits = text.slice(-4);
      return <span>{visiblePart + hiddenPart + lastFourDigits}</span>;
    } else {
      const lastFourDigits = text.slice(-4);
      const hiddenPart = text.slice(0, -4).replace(/./g, "*");
      return <span>{hiddenPart + lastFourDigits}</span>;
    }
  };

  const handleEditClick = (record: Lead) => {
    setSelectedLead(record);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedLead(null);
  };

  const handleModalSave = (selectedOption: any) => {
    setLoading(true);
    updateLead(selectedLead?.id, selectedOption);

    message.success("Alteração realizada com sucesso");

    setTimeout(() => {
      setIsModalVisible(false);
      setSelectedLead(null);
      setLoading(false);
    }, 1000);
  };
  const columns = [
    {
      title: "Nome",
      dataIndex: ["attributes", "nome"],
      key: "name",
    },
    {
      title: "Telefone",
      dataIndex: ["attributes", "phone"],
      key: "phone",
      render: (text: string) => renderPhone(text),
    },
    {
      title: "Alocada a",
      key: "username",
      // render href to agente user
      render: (text: string, record: any) => (
        <span>
          {record.attributes.user && record.attributes.user.data ? (
            <span
            >
              {record.attributes.user.data.attributes.username}
            </span>
          ) : (
            "Sem User"
          )}
        </span>
      ),
    },

    {
      title: "ID da lead",
      dataIndex: ["attributes", "uuid"],
      key: "uuid",
    },
    // estado
    {
      title: "Estado",
      dataIndex: ["attributes", "estado"],
      key: "estado",
      // if estado is Reagendada show data de reagendamento por baixo do texto a dizer reagendada deve apenas dizer dd-mm hh:mm
      render: (text: string, record: any) => (
        <div className="flex flex-col items-center gap-2">
          <span
            className="text-xs text-center"
            style={{
              backgroundColor: getEstadoColor(record.attributes.estado),
              borderRadius: "5px",
              padding: "5px",
              color: "black",
            }}
          >
            {text}
          </span>
          {record.attributes.estado === "Reagendada" &&
            record.attributes.data_reagendamento && (
              <>
                <span className="text-xs">
                  {new Date(
                    record.attributes.data_reagendamento
                  ).toLocaleString("pt-PT", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </span>
                <span className="text-xs">
                  {new Date(
                    record.attributes.data_reagendamento
                  ).toLocaleString("pt-PT", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </>
            )}
        </div>
      ),
    },
    {
      title: "Categoria",
      dataIndex: [
        "attributes",
        "tipo_simulacao",
        "data",
        "attributes",
        "categoria",
      ],
      key: "categoria",
    },
    {
      title: "Simulador",
      dataIndex: [
        "attributes",
        "tipo_simulacao",
        "data",
        "attributes",
        "simulador",
      ],
      key: "simulador",
    },
    {
      title: "Ações",
      key: "Acoes",
      render: (text: string, record: any) => (
        <span className="flex">
          <Button
            icon={<AiOutlineEye />}
            className="bg-green-500 hover:bg-green-300 mr-2 text-white"
            onClick={() => {
              const uuid = record.attributes.uuid;
              const slug =
                record.attributes.tipo_simulacao.data.attributes.slug;

              navigate(`/${slug}/${uuid}`);
            }}
          />

          {canEdit && (
            <Button
              icon={<AiOutlineEdit />}
              className="bg-blue-500 hover:bg-blue-300 text-white"
              onClick={() => handleEditClick(record)}
            />
          )}
        </span>
      ),
    },
  ];

  const getEstadoColor = (estado: string): string => {
    switch (estado) {
      case "Fechada":
        return "#ff4d4f";
      case "Aberta":
        return "#ffa940";
      case "Entregue":
        return "#73d13d";
      case "Reagendada":
        return "#9254de";
      case "Cancelada":
        return "#595959";
      case "Falsa":
        return "#ffec3d";
      case "Repetida":
        return "#40a9ff";
      case "Outro":
        return "#ff7a45";
      case "Contacto Errado":
        return "#2596be"; // Cor a definir para este estado
      case "Não atendeu":
        return "#e28743"; // Cor a definir para este estado
      case "Não vai avançar":
        return "#abdbe3"; // Cor a definir para este estado

      default:
        return "#000000";
    }
  };
  const filteredData = simuladorData.filter((lead) => {
    const { nome, email, phone, uuid, user, estado, data_reagendamento,tipo_simulacao } = lead.attributes;
    const searchTextLower = searchText.toLowerCase();

    const matchesSearchText = nome.toLowerCase().includes(searchTextLower) ||
      email.toLowerCase().includes(searchTextLower) ||
      phone.toLowerCase().includes(searchTextLower) ||
      uuid.toLowerCase().includes(searchTextLower) ||
      (user.data.attributes.username.toLowerCase().includes(searchTextLower)) ||
      estado.toLowerCase().includes(searchTextLower) ||
      tipo_simulacao.data.attributes.categoria.toLowerCase().includes(searchTextLower) ||
      tipo_simulacao.data.attributes.simulador.toLowerCase().includes(searchTextLower);


    const matchesAgent = agentFilter ? user.data.attributes.username.toLowerCase() === agentFilter.toLowerCase() : true;

    const isUnwantedState = ["Fechada", "Falsa", "Cancelada", "Repetida", "Não atendeu", "Não vai avançar", "Outro","Reagendada"].includes(estado);
    const shouldShowBasedOnState = stateFilter ? (estado === stateFilter) : (showFechadas || !isUnwantedState);

    if (estado === "Reagendada" && data_reagendamento) {
      const hoje = new Date();
      hoje.setHours(0, 0, 0, 0); // Definir horas, minutos, segundos e milissegundos para zero

      const amanha = new Date(hoje);
      amanha.setDate(hoje.getDate() + 1); // Definir para amanhã

      const tresDiasDepois = new Date(hoje);
      tresDiasDepois.setDate(hoje.getDate() + 3); // Definir para daqui a 3 dias

      const dataReagendamento = new Date(data_reagendamento);
      if (dataReagendamento >= amanha && dataReagendamento <= tresDiasDepois) {
        return matchesSearchText && matchesAgent && (shouldShowBasedOnState || true);
      }
    }

    return matchesSearchText && matchesAgent && (shouldShowBasedOnState || false);


  }).map(lead => ({ ...lead, key: lead.attributes.uuid }));

  return (

    <div className="bg-gray-100 flex items-start justify-center mt-24">
      {isLoading && loading ? (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-100 flex items-center justify-center">
          <Spin />
        </div>
      ) : (
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">

          <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4">
            <div className="mb-4 flex space-x-4 max-w-full">
              <Search
                placeholder="Pesquisar"
                onSearch={handleSearch}
                style={{ width: 600 }}
              />
              <Select
                placeholder="Filtrar por Agente"
                onChange={handleAgentFilter}
                style={{ width: 200 }}
                defaultValue={"Todos os agentes"}
              >
                <Select.Option value="">Todos os agentes</Select.Option>
                {users &&
                  // users ->tipo -> level 3 ou maior
                  users
                    .filter((user) => user.tipo.level >= 5)
                    .map((user) => (
                      <Select.Option key={user.id} value={user?.username}>
                        {user?.username}
                      </Select.Option>
                    ))}
              </Select>
              <Select
                placeholder="Filtrar por Estado"
                onChange={handleStateFilter}
                style={{ width: 200 }}
                value={stateFilter !== "" ? stateFilter : "Todos os estados"}
              >
                <Select.Option value="">Todos os estados</Select.Option>
                <Select.Option value="Fechada">Fechada</Select.Option>
                <Select.Option value="Aberta">Aberta</Select.Option>
                <Select.Option value="Entregue">Entregue</Select.Option>
                <Select.Option value="Reagendada">Reagendado</Select.Option>
                <Select.Option value="Cancelada">Cancelada</Select.Option>
                <Select.Option value="Falsa">Falsa</Select.Option>
                <Select.Option value="Repetida">Repetida</Select.Option>
                <Select.Option value="Outro">Outro</Select.Option>
              </Select>
              {/* apenas mostrar checbox quando todos statefilter = ""*/}
              {stateFilter === "" && (
                <Checkbox
                  onChange={(e) => setShowFechadas(e.target.checked)}
                  checked={showFechadas}
                >
                  <div className="flex items-center">
                    <span className="mr-2 mt-1">Todas</span>
                  </div>
                </Checkbox>
              )}
              <Button
                type="primary"
                className="bg-blue-500 hover:bg-blue-600/20"
                onClick={() => triggerUpdate()}
              >
                <RiRefreshLine />

              </Button>
            </div>
            <Table
              dataSource={filteredData} // Utiliza os dados já filtrados
              loading={isLoading}
              pagination={{ pageSizeOptions: [10,25,50,100,200], defaultPageSize: 10 }}
              rowKey={(record) => record.attributes.uuid}
              columns={columns}
            // scroll={{ x: 1000 }}

            />
          </div>
          <EditLeadModal
            record={selectedLead}
            visible={isModalVisible}
            onCancel={handleModalCancel}
            onSave={handleModalSave}
          />
        </div>
      )}
    </div>
  );
};

export default LeadsPage;
