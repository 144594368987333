import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Form, Input, message, Select, Spin } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import {
  getUserByID,
  updateUserById as updateUser,
  deleteUserById as deleteUser,
} from "../../api/getSimInfo";
import Link from "antd/es/typography/Link";

interface UserData {
  id: number;
  username: string;
  email: string;
  confirmed: boolean;
  blocked: boolean;
  role: {
    id: number;
    name: string;
    description: string;
    type: string;
  };
  tipo: {
    id: number;
    nivel: string;
    cor: string;
    level:any;
  };
  phone: string;
  equipa: {
    id: number;
    nome_equipa: string;
    zona: {
      id: number;
      nome_zona: string;
    };
  };
}
const PageHeader = ({ title }: any) => (
  <div className="flex justify-between items-center mb-4">
    <h1 className="text-2xl font-semibold">{title}</h1>
    <button
      type="button"
      className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      onClick={() => window.history.back()}
    >
      Voltar
    </button>
  </div>
);

const { Option } = Select;

const AgenteView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    user: EditingUser,
    isLoading,
    setUser: ChangeUSer,
    zonas,
    tipos,
    equipas,
  } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [user, setUser] = useState<UserData | null>(null); // Initialize as null

  // also set loading


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getUserByID(id);
        setUser(response);
        form.setFieldsValue(response);
      } catch (error) {
        console.error(error);
        message.error("Error While Fetching User Data!");
      } finally {

        setLoading(false);
      }
    };
    fetchUser();
    setLoading(true);

  }, [id, form]);

  const handleProfileUpdate = async (data: any) => {
    try {
      setLoading(true);
      const response = await updateUser(user?.id, data);
      setUser(response);
      message.success("Data saved successfully!");
    } catch (error) {
      console.error(error);
      message.error("Error While Updating the Profile!");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteUser(id); // Implement this function to delete the user by ID
      message.success("User deleted successfully!");
      navigate("/agentes"); // Redirect to the agent list page or the appropriate page
    } catch (error) {
      console.error(error);
      message.error("Error While Deleting the User!");
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (err: any) => {
    console.error(err);
  };

  if (isLoading || loading) {
    return (
      <div className="flex items-center justify-center h-screen overflow-hidden">
        <Spin size="large" />
      </div>
    );
  }
  let option: string[] = [];
  if (EditingUser?.tipo?.level <= 1) {
    option = tipos.map(tipo => tipo.nivel); // tipos é o estado de contexto com todos os tipos
  } else {
    // Inclua apenas os tipos com level superior ao do usuário atual
    option = tipos.filter(tipo => tipo.level > EditingUser?.tipo.level).map(tipo => tipo.nivel);
  }

  // Antes de processar a edição, verifique se o usuário atual é um administrador.
  if (user?.tipo?.level === 1 && EditingUser?.tipo?.level > 1) {
    // Renderize uma página informando ao usuário que ele não pode editar um administrador.
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="bg-white rounded-lg shadow-md p-8">
          <h1 className="text-2xl font-semibold mb-4">
            Desculpe, você não tem permissão para editar um utilizador
            administrador.
          </h1>
          <Link href="/agentes">
            <a>
              <button className="bg-blue-500 text-white hover:bg-blue-600 py-2 px-4 rounded-md transition duration-300">
                Voltar
              </button>
            </a>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-md p-8">
      <PageHeader title="Editar Utilizador" />
      <Form
        form={form}
        layout="vertical"
        onFinish={handleProfileUpdate}
        onFinishFailed={onFinishFailed}
      >
        <div className="mb-4">
          <Form.Item
            label="Nome de Utilizador"
            name="username"
            className="font-bold"
          >
            <Input className="font-normal" />
          </Form.Item>
        </div>
        <div className="mb-4">
          <Form.Item label="Email" name="email" className="font-bold">
            <Input className="font-normal" />
          </Form.Item>
        </div>
        {EditingUser?.tipo?.level <= 1 && (
          <div className="mb-4">
            <div className="mb-4">
              <Form.Item
                label="Confirmado"
                name="confirmed"
                className="block font-semibold"
              >
                <Select disabled>
                  <Option value={true}>Sim</Option>
                  <Option value={false}>Não</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="mb-4">
              <Form.Item
                label="Bloqueado"
                name="blocked"
                className="block font-semibold"
              >
                <Select>
                  <Option value={true}>Sim</Option>
                  <Option value={false}>Não</Option>
                </Select>
              </Form.Item>
            </div>

            <div className="mb-4">
              <Form.Item
                label="Nome da Equipa"
                name={["equipa", "nome_equipa"]}
              >
                <Input disabled />
              </Form.Item>
            </div>

            <div className="mb-4">
              <Form.Item label="Zona" name={["equipa", "zona", "nome_zona"]}>
                <Input disabled />
              </Form.Item>
            </div>
          </div>
        )}
        {/* check editing user is diretor, and if user is Admin */}
        {EditingUser?.tipo?.level <= 2 && (
          <div className="mb-4">
            <div className="mb-4">
              <Form.Item
                label="Bloqueado"
                name="blocked"
                className="block font-semibold"
              >
                <Select>
                  <Option value={true}>Sim</Option>
                  <Option value={false}>Não</Option>
                </Select>
              </Form.Item>
            </div>

            <div className="mb-4">
              <Form.Item
                label="Tipo"
                name="tipo"
                className="block font-semibold"
              >
                <Select>
                  {option.map((option) => (
                    <Option value={option} key={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="mb-4">
              <Form.Item
                label="Nome da Equipa"
                name={["equipa", "nome_equipa"]}
                rules={[{ message: "Escolha uma equipa!" }]}
                className="font-bold"
              >
                <Select className="w-full">
                  {Array.isArray(equipas) ? (
                    equipas.map((equipa) => (
                      <Option key={equipa?.id} value={equipa?.id}>
                        {equipa?.attributes?.nome_equipa}
                      </Option>
                    ))
                  ) : (
                    <Option
                      key={user?.equipa?.id}
                      value={user?.equipa?.nome_equipa}
                    >
                      {user?.equipa?.nome_equipa}
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </div>
          </div>
        )}

        <div className="mb-4">
          <Form.Item label="Telefone" name="phone">
            <Input />
          </Form.Item>
        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className={`w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
              loading ? "cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? <Spin /> : "Atualizar Perfil"}
          </button>
        </div>

        <div className="mt-4">
          <button
            type="button"
            className={`w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
              loading ? "cursor-not-allowed" : ""
            }`}
            disabled={loading}
            onClick={handleDelete}
          >
            {loading ? <Spin /> : "Excluir Usuário"}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AgenteView;
