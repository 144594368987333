import React, { ReactNode, useEffect, useState } from "react";
import { getResultById3 } from "../../../../../api/getSimInfo";
import { useNavigate, useParams } from "react-router-dom";
// import relatorio and createrelatorio
import { Spin, message } from "antd";
import { useAuthContext } from "../../../../../context/AuthContext";
import { RiArrowGoBackLine, RiFileTextLine, RiSave2Line } from "react-icons/ri";
import { create_sim } from "../../../../../api/sendForm";

const SimPageResultadoSeguroHabit = () => {
  const { id } = useParams<{ id: string }>();
  const { simId } = useParams();
  const [dados, setDados] = useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const { triggerUpdate } = useAuthContext();

  const formatCurrency = (value: string | undefined) => {
    const numericValue = parseFloat(value as any);
    if (isNaN(numericValue)) {
      return "Invalid value";
    }

    return `${numericValue
      .toFixed(2)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}€`;
  };

  if (window.location.href.includes("relatorio")) {
    sessionStorage.removeItem("simulacao");
  }
  const [simulations, setSimulations] = useState("undefined");
  // get the data from the sessionStorage

  const checkdata = sessionStorage.getItem("simulacao")
    ? JSON.parse(sessionStorage.getItem("simulacao") || "{}")
    : {};


  // console.log(dados)

  useEffect(() => {
    const fetchDadosSeguro = async () => {
      getResultById3(simId)
        .then((res) => {
          setDados(res);
          setLoading(false);
        })
        .catch((err) => {
          // if error then show the message
          message.error("Ocorreu um erro");
        });
    };
    if (simId) {
      fetchDadosSeguro();
    }
  }, []);
  // if it is not set then should go use the checkData["vida-futura to set data"]
  useEffect(() => {
    // check if there is a simId
    if (!simId) {
      // get the data from the backend
      setDados(checkdata["vida-habitacao"]);
    }
  }, []);

  const navigate = useNavigate();

  const SaveSimulation = () => {
    // send to backend data to save it
    // send data to backend
    setLoading(true);

    // just send the data object, it is already made for the database
    create_sim(dados)
      .then((res) => {
        setLoading(false)
        // go back to the lead
        message.success(
          "Simulação guardada com sucesso, a regressar á lead de origem"
        );

        // timout for 3 seconds then go backk to vida futura and reload
        setTimeout(() => {
          triggerUpdate();
          window.close()
        }, 2000);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Ocorreu um erro ao guardar a simulação");
        setLoading(false);
      });
  };

  // loading
  if (!dados || !dados.resultado_simulacao) {
    return (
      <div className="bg-gray-200 min-h-screen flex items-center justify-center p-8">
        <div className="max-w-screen-lg w-full text-center">
          <Spin size="large" />
        </div>
      </div>
    );
  }
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">Detalhes do Seguro</h1>
      {
        !simId && (
          <div className="flex justify-end">
            <button
              className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
              onClick={SaveSimulation}
            >
              <RiSave2Line className="inline-block mr-2" />
              Guardar simulação
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded ml-2"
              onClick={() => navigate(`/vida-futura/${id}`)}
            >
              <RiArrowGoBackLine className="inline-block mr-2" />
              Voltar
            </button>
          </div>
        )
        //  else show new voltar button
      }
      {simId && (
        <div className="flex justify-end">
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded ml-2"
            // onclick navigate history back?
            onClick={() => window.history.back()}
          >
            <RiArrowGoBackLine className="inline-block mr-2" />
            Voltar
          </button>
        </div>
      )}

      {loading && (
        <div className="flex justify-center items-center h-screen">
          <div className="bg-real-color text-white font-bold py-2 px-4 rounded-full">
            A carregar...
          </div>
        </div>
      )}
      {dados && (
        <>
          <div className="container mx-auto p-8">
            <h2 className="text-2xl font-bold mb-4">Informações do Seguro</h2>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <tbody>
                <tr
                  className="border-b bg-blue-600
                 dark:bg-blue-800 dark:border-bg-blue-700"
                >
                  {" "}
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-blue-900 whitespace-nowrap dark:text-white"
                  >
                    Nome
                  </th>
                  <td
                    className="px-6 py-4"
                    style={{ color: "green", fontWeight: "bold" }}
                  >
                    {dados.dados_simulacao.nome}
                  </td>
                </tr>
                <tr
                  className="border-b bg-blue-600
                 dark:bg-blue-800 dark:border-bg-blue-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-red-900 whitespace-nowrap dark:text-white"
                  >
                    Email
                  </th>
                  <td
                    className="px-6 py-4"
                    style={{ color: "green", fontWeight: "bold" }}
                  >
                    {dados.dados_simulacao.email}
                  </td>
                </tr>
                <tr
                  className="border-b bg-blue-600
                 dark:bg-blue-800 dark:border-bg-blue-700
                  cursor-pointer
                 "
                  onClick={
                    // tel to
                    () => window.open(`tel:00351${dados.dados_simulacao.phone}`)
                  }
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Contacto
                  </th>
                  <td
                    className="px-6 py-4"
                    style={{ color: "green", fontWeight: "bold" }}
                  >
                    +351{" "}
                    <a href={`tel:00351${dados.dados_simulacao.phone}`}>
                      {dados.dados_simulacao.phone}
                    </a>
                  </td>
                </tr>
                {/* <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Capital Simulado
                  </th>
                  <td className="px-6 py-4">
                    {formatCurrency(dados.dados_simulacao.capital)}
                  </td>
                </tr> */}
                {/*  <td className="px-6 py-4">
                    {dados.dados_simulacao.data_inicio_contrato}
                  </td> */}
                {/* <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Data de inicio do contrato
                  </th>
                  <td className="px-6 py-4">
                    {dados.dados_simulacao.data_inicio_contrato}
                  </td>
                </tr> */}

                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Número de titulares
                  </th>
                  <td className="px-6 py-4">
                    {dados.resultado_simulacao.original.n_titulares}
                  </td>
                </tr>
                {/* <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Data de nascimento do titular
                  </th>
                  <td className="px-6 py-4">
                    {dados.dados_simulacao.data_nasc_1}
                  </td>
                </tr> */}
                {/* caso haja data_nasc_2 */}
                {/* {dados.dados_simulacao.data_nasc_2 && (
                  <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Data de nascimento do segundo titular
                    </th>
                    <td className="px-6 py-4">
                      {dados.dados_simulacao.data_nasc_2}
                    </td>
                  </tr>
                )} */}
                {/* <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Mensalidade paga atualmente
                  </th>
                  <td className="px-6 py-4">
                    {formatCurrency(dados.dados_simulacao.mensalidade)}
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>

          {/* Tabela Dados da Simulação Original */}
          <div className="container mx-auto p-8">
            <h2 className="text-2xl font-bold mb-4">
              Dados da Simulação Original
            </h2>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <tbody>
                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Número de titulares
                  </th>
                  <td className="px-6 py-4">
                    {dados.resultado_simulacao.original.n_titulares}
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Capital em dívida
                  </th>
                  <td className="px-6 py-4">
                    {dados.resultado_simulacao.original.c_divida}€
                  </td>
                </tr>
                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Cobertura não incluida
                  </th>
                  <td className="px-6 py-4">
                    {dados.resultado_simulacao.original.cobertura}
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Valor fraccionado
                  </th>
                  <td className="px-6 py-4">
                    {formatCurrency(
                      dados.resultado_simulacao.original.v_fraccionado
                    )}
                  </td>
                </tr>
                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Fraccionamento
                  </th>
                  <td className="px-6 py-4">
                    {dados.resultado_simulacao.original.fraccionamento}
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Valor Anual
                  </th>
                  <td className="px-6 py-4">
                    {formatCurrency(dados.resultado_simulacao.original.v_anual)}
                  </td>
                </tr>
                {/* <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Poupança anual
                  </th>
                  <td className="px-6 py-4">
                    {formatCurrency(dados.resultado_simulacao.original.p_anual)}
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>

          {/* Tabela Dados da Simulação Sugerido */}
          <div className="container mx-auto p-8">
            <h2 className="text-2xl font-bold mb-4">
              Dados da Simulação Sugerido
            </h2>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <tbody>
                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Número de titulares
                  </th>
                  <td className="px-6 py-4">
                    {dados.resultado_simulacao.original.n_titulares}
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Capital em dívida
                  </th>
                  <td className="px-6 py-4">
                    {dados.resultado_simulacao.original.c_divida}€
                  </td>
                </tr>
                {/* caso haja cobertura */}
                {dados.resultado_simulacao.sugerido.cobertura && (
                  <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Cobertura incluida
                    </th>
                    <td className="px-6 py-4">
                      {dados.resultado_simulacao.sugerido.cobertura}
                    </td>
                  </tr>
                )}
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Valor fraccionado
                  </th>
                  <td className="px-6 py-4">
                    {formatCurrency(
                      dados.resultado_simulacao.sugerido.v_fraccionado
                    )}
                  </td>
                </tr>
                <tr className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Fraccionamento
                  </th>
                  <td className="px-6 py-4">
                    {dados.resultado_simulacao.sugerido.fraccionamento}
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Valor Anual
                  </th>
                  <td className="px-6 py-4">
                    {formatCurrency(dados.resultado_simulacao.sugerido.v_anual)}
                  </td>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Poupança anual
                  </th>
                  <td className="px-6 py-4">
                    {formatCurrency(dados.resultado_simulacao.sugerido.p_anual)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default SimPageResultadoSeguroHabit;
