import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../api/Auth/useAuth';

const PrivateRoute = ({ children }: any) => {

  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return isAuthenticated ? children : window.location.reload();
};

export default PrivateRoute;
