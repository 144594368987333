import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from "react-icons/fa"; // Import the calendar icon
import pt from "date-fns/locale/pt";
import RadioGroup from "../../RadioGroup";
import axios, { AxiosError } from "axios";
import { useAuthContext } from "../../../../context/AuthContext";
import { Spin, message } from "antd";
import { GeralSubmitToBackEnd } from "../../../../api/sendForm";
import CheckboxList from "../../../checkboxList";

const backendApi = process.env.REACT_APP_BACKEND_API;
const SvIp = process.env.REACT_APP_SERVER_IP;

interface ExtraData {
  risk52value?: number;
  risk18value?: number;
}

interface Checkbox {
  label: string;
  checked: boolean;
  disabled: boolean;
  value: number;
  extra?: ExtraData;
}

interface FormData {
  type: string;
  data: {
    name: string;
    data_inicio_contrato: Date | null; // 'data_inicio_contrato' corresponds to some string value
    birthDay1Insurance: Date | null; // 'data_nasc_1' corresponds to some string value
    birthDay2Insurance: Date | null; // 'data_nasc_1' corresponds to some string value
    // Date is date now
    date: Date | null;
    // fraccionamento is mensal trimestral semestral ou anual
    fraccionamento: string | null; // 'fraccionamento' corresponds to some string value
    origemdoacesso: string | null; // 'origemdoacesso' corresponds to some string value
    capital: string | null; // 'capital' corresponds to some string value
    numberOfInsurances: string | null; // 'titulares' corresponds to some string value
    parcel: number | null;
    paymentType: number | null;
    risks: [] | null;
  };
}

interface UserData {
  id: number;
  username: string;
  email: string;
  confirmed: boolean;
  blocked: boolean;
  role: {
    id: number;
    name: string;
    description: string;
    type: string;
  };
  tipo: {
    id: number;
    nivel: string;
    cor: string;
  };
  phone: string;
  equipa: {
    id: number;
    nome_equipa: string;
    zona: {
      id: number;
      nome_zona: string;
    };
  };
}
type RequestData = {
  data: {
    attributes: {
      name: string;
      data_inicio_contrato: string;
      birthDay1Insurance: string;
      birthDay2Insurance: string;
      date: string;
      fraccionamento: string;
      origemdoacesso: string;
      capital: string;
      numberOfInsurances: string;
      parcel: number;
      paymentType: number;
      risks: [];
      user: number;
    };
  };
};

const PageSimuladorVidaPleno: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    type: "vida",
    data: {
      name: "",
      data_inicio_contrato: null,
      birthDay1Insurance: null,
      birthDay2Insurance: null,
      date: null,
      fraccionamento: null,
      origemdoacesso: null,
      capital: null,
      numberOfInsurances: null,
      parcel: null,
      paymentType: null,
      risks: [],
    },
  });

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [result, setResult] = useState<any>(null);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const { users, simuladorData, isLoading } = useAuthContext();
  //make a variable to send available users
  const [availableUsers, setAvailableUsers] = useState<UserData[]>([]);
  //make a variable to send available users
  // Adicione um novo estado para controlar se são um ou dois titulares
  const [titulares, settitulares] = useState<number>(1);

  // Adicione um novo estado para controlar a opção de atualização automática do capital seguro
  const [autoUpdateCapital, setAutoUpdateCapital] = useState<string>("1");
  const [checkboxes, setCheckboxes] = useState<Checkbox[]>([
    { label: "Morte", checked: true, disabled: true, value: 0 },
    {
      label: "Invalidez Absoluta e Definitiva",
      checked: true,
      disabled: true,
      value: 3,
    },
    { label: "Médico Online", checked: true, disabled: true, value: 19 },
    { label: "Médico ao Domicílio", checked: true, disabled: true, value: 55 },
    {
      label: "Aconselhamneto Médico Telefónico",
      checked: true,
      disabled: true,
      value: 56,
    },
    {
      label: "Invalidez Total e Permanente 60%",
      checked: false,
      disabled: false,
      value: 26,
    },
    {
      label: "Invalidez Total e Permanente 65%",
      checked: false,
      disabled: false,
      value: 27,
    },
    {
      label: "Morte por Acidente de Circulação",
      checked: false,
      disabled: false,
      value: 5,
    },

    {
      label: "Doenças Graves e Medicina de Precisão",
      checked: false,
      disabled: false,
      value: 52,
      extra: {
        risk52value: 1,
      },
    },

    {
      label: "Subsídio Diário por Hospitalização por Acidente",
      checked: false,
      disabled: false,
      value: 18,
      extra: {
        risk18value: 25,
      },
    },

    {
      label: "Filhos Menores a Cargo",
      checked: false,
      disabled: false,
      value: 62,
    },

    {
      label: "Morte por Acidente",
      checked: false,
      disabled: false,
      value: 59,
    },
    {
      label: "Subsidio de Funeral",
      checked: false,
      disabled: false,
      value: 63,
    },
  ]);

  useEffect(() => {
    if (users && simuladorData) {
      // Encontrar o maior 'level' entre os usuários, assumindo que representa o nível de agente regular
      const highestLevel = Math.max(...users.map(user => user.tipo?.level || 0));
  
      // Criar um conjunto com os IDs dos usuários que têm leads
      const userIdsWithLeads = new Set(simuladorData.map(lead => lead?.attributes?.user?.data?.id));
  
      // Filtrar agentes disponíveis que têm o level mais alto e não têm leads
      let agentesDisponiveis = users.filter(user => user.tipo?.level === highestLevel && !userIdsWithLeads.has(user.id));
  
      if (agentesDisponiveis.length === 0 && users.length > 0) {
        // Se não houver agentes disponíveis, selecionar um aleatoriamente de todos os agentes com o level mais alto
        const agentesComLevelMaisAlto = users.filter(user => user.tipo?.level === highestLevel);
        const randomAgentIndex = Math.floor(Math.random() * agentesComLevelMaisAlto.length);
        agentesDisponiveis = [agentesComLevelMaisAlto[randomAgentIndex]];
      }
      
      setAvailableUsers(agentesDisponiveis);
    }
  }, [simuladorData, users]);

  // if is loading show loading page

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );
  }
  function transformData(data: any) {
    function formatDate(dateString: any) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }

    // create an array with the risks that are checked

    // const risks = checkboxes
    //   .filter((checkbox) => checkbox.checked)
    //   .map((checkbox) => checkbox.value.toString());

    const risks = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);

    // add the checked risks to the default one
    const risksIds = [...risks];
    const newDate = new Date(data.data_inicio_contrato);
    const dateSet = newDate.toISOString();

    const transformedData = {
      type: "Pleno",
      data: {
        numberOfInsurances: titulares.toString(),
        date: dateSet,
        birthDay1Insurance: formatDate(data.birthDay1Insurance),
        birthDay2Insurance: data.birthDay2Insurance
          ? formatDate(data.birthDay2Insurance)
          : null,
        capital: data.capital,
        paymentType: data.paymentType ?? 68,
        parcel: 77,
        fraccionamento: data.fraccionamento ?? "Mensal",
        risks: risksIds,
      },
    };
    return transformedData;
  }
  function formatDate(dateString: any) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const handleSelectChange = (value: number, checkboxIndex: number) => {
    const updatedCheckboxes = checkboxes.map((checkbox, index) =>
      index === checkboxIndex
        ? {
            ...checkbox,
            extra: {
              risk52value: value,
            },
          }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const transformedData = transformData(formData.data);
    const dataToSend: any = {
      ...transformedData,
    };

    const json = JSON.stringify(dataToSend);
    const NewDataToSend: any = {
      ...transformedData.data,
      birthDay1Insurance: formatDate(transformedData.data.birthDay1Insurance),
      birthDay2Insurance: transformedData.data.birthDay2Insurance
        ? formatDate(transformedData.data.birthDay2Insurance)
        : null,
      // nome, email and phone
      name: formData.data.name,
      email: email,
      phone: phone,
    };

    const json2 = JSON.stringify(NewDataToSend);
    try {
      const response = await axios.post(`${backendApi}/pleno`, json, {
        headers: {
          "Content-Type": "application/json",
          "x-server-ip-for": SvIp,
        },
      });
      // make a 3 seconds pause before keep going
      await new Promise((resolve) => setTimeout(resolve, 3000));

      if (response.data.status === "ERROR") {
        setError(response.data.message);
        setLoading(false);
        message.error(error);
        return;
      }
      // get the response and put it into a variable called res
      const res = response.data;
      setResult(res);

      // if result is null send error
      if (result === null) {
        setError("Erro ao fazer simulação");
        setLoading(false);
        return;
      }

      const formDataToSend2 = new FormData();
      formDataToSend2.append("nome", formData.data.name);
      formDataToSend2.append("email", email);
      formDataToSend2.append("phone", phone);
      formDataToSend2.append("resultado_simulacao", JSON.stringify(result));
      formDataToSend2.append(
        "tipo_simulacao",
        JSON.stringify({
          simulador: "Vida Pleno",
        })
      );
      formDataToSend2.append("dados_simulacao", json2);

      const response2 = await GeralSubmitToBackEnd(
        formDataToSend2,
        availableUsers
      );

      if (response2 === undefined) {
        setTimeout(() => {
          window.location.href = `/leads`;
        }, 4000);
      } else {
        setTimeout(() => {
          window.location.href = `/vida-pleno/${response2}`;
        }, 4000);
      }
      setError("");

      message.success("Simulação feita com sucesso");
    } catch (error) {
      console.error(error);
      // set loading false and message error
      setLoading(false);
      message.error("Erro ao fazer simulação");
      return;
    }
  };
  const handleBirthdayChange = (date: Date | null) => {
    setFormData((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        birthDay1Insurance: date,
      },
    }));
  };
  const handleBirthdayChange2 = (date: Date | null) => {
    setFormData((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        birthDay2Insurance: date,
      },
    }));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      type: "vida",
      data: {
        ...formData.data,
        [name]: value,
      },
    });
  };

  const handleDateChange = (date: Date | null, name: string) => {
    setFormData({
      type: "vida",
      data: {
        ...formData.data,
        [name]: date,
      },
    });
  };

  const handleRadioChange = (value: string, name: string) => {
    setFormData({
      type: "vida",
      data: {
        ...formData.data,
        [name]: value,
      },
    });
  };

  const handleTitularesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    settitulares(parseInt(value));
  };

  const handleCheckboxChange = (
    label: string,
    checked: boolean,
    value: number,
    extra?: ExtraData
  ) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.label === label
        ? {
            ...checkbox,
            checked,
            extra: extra || checkbox.extra, // Preserve existing extra data
          }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="max-h-screen ">
          <h2 className="text-2xl font-bold mb-4">
            Simulação do seguro de Pleno
          </h2>

          <div className="flex flex-col items-center justify-center">
            <form onSubmit={handleFormSubmit} className="max-w-md">
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nome
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.data.name}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Telefone
                </label>
                <input
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              {/* select if is 1 or more titulares */}
              <div className="mb-4">
                <label
                  htmlFor="titulares"
                  className="block text-sm font-medium text-gray-700"
                >
                  Número de titulares
                </label>
                <select
                  name="numberOfInsurances"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    handleTitularesChange(e as any)
                  }
                  className="mt-1 p-2 w-full border rounded-md"
                >
                  <option value={1}>1 Titular</option>
                  <option value={2}>2 Titulares</option>
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="birthday"
                  className="block text-sm font-medium text-gray-700"
                >
                  Data de Nascimento do Titular
                </label>
                <div className="relative mt-1 p-2 w-full border rounded-md">
                  <DatePicker
                    selected={formData.data.birthDay1Insurance}
                    onChange={(date: Date | null) => handleBirthdayChange(date)}
                    dateFormat="dd-MM-yyyy"
                    required
                    className="w-full h-full p-2"
                    wrapperClassName="w-full h-full"
                    maxDate={
                      new Date(
                        // 18 + 6 meses ago
                        new Date().getTime() -
                          18 * 365 * 24 * 60 * 60 * 1000 -
                          6 * 30 * 24 * 60 * 60 * 1000
                      )
                    }
                    minDate={
                      new Date(
                        // 57years + 6 months years ago
                        new Date().getTime() -
                          57 * 365 * 24 * 60 * 60 * 1000 -
                          6 * 30 * 24 * 60 * 60 * 1000
                      )
                    }
                    locale={pt}
                    closeOnScroll={true}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd-mm-yyyy"
                  />
                  <span className="absolute right-2 top-4">
                    <FaCalendar style={{ color: "#9CFFA5" }} />
                  </span>
                </div>
              </div>
              {/* if is 2 titulares ask data de nascimento of titular 2 */}
              {titulares === 2 && (
                <div className="mb-4">
                  <label
                    htmlFor="secondTitular.birthday"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Data de Nascimento do Segundo Titular
                  </label>
                  <div className="relative mt-1 p-2 w-full border rounded-md">
                    <DatePicker
                      selected={formData.data.birthDay2Insurance}
                      onChange={(date: Date | null) =>
                        handleBirthdayChange2(date)
                      }
                      dateFormat="dd-MM-yyyy"
                      required
                      className="w-full h-full p-2"
                      wrapperClassName="w-full h-full"
                      maxDate={
                        new Date(
                          // 18 + 6 meses ago
                          new Date().getTime() -
                            18 * 365 * 24 * 60 * 60 * 1000 -
                            6 * 30 * 24 * 60 * 60 * 1000
                        )
                      }
                      minDate={
                        new Date(
                          // 57years + 6 months years ago
                          new Date().getTime() -
                            57 * 365 * 24 * 60 * 60 * 1000 -
                            6 * 30 * 24 * 60 * 60 * 1000
                        )
                      }
                      locale={pt}
                      closeOnScroll={true}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="dd-mm-yyyy"
                    />
                    <span className="absolute right-2 top-4">
                      <FaCalendar style={{ color: "#9CFFA5" }} />
                    </span>
                  </div>
                </div>
              )}
              {/* ask the data de inicio do contrato */}

              <div className="mb-4">
                <label
                  htmlFor="data_inicio_contrato"
                  className="block text-sm font-medium text-gray-700"
                >
                  Data de início do contrato
                </label>
                <div className="relative mt-1 p-2 w-full border rounded-md">
                  <DatePicker
                    selected={formData.data.data_inicio_contrato}
                    onChange={(date: Date | null) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        data: {
                          ...prevData.data,
                          data_inicio_contrato: date,
                        },
                      }))
                    }
                    dateFormat="dd-MM-yyyy"
                    minDate={
                      new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                    }
                    maxDate={
                      new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
                    } // 30 days from today
                    required
                    dropdownMode="select"
                    locale={pt}
                    placeholderText="dd-mm-yyyy"
                    className="w-full h-full p-2"
                    wrapperClassName="w-full h-full"
                  />
                  <span className="absolute right-2 top-4">
                    <FaCalendar style={{ color: "#9CFFA5" }} />
                  </span>
                </div>
              </div>
              {/* select the fraccionamento */}
              <div className="mb-4">
                <label
                  htmlFor="fraccionamento"
                  className="block text-sm font-medium text-gray-700"
                >
                  Fraccionamento
                </label>

                <select
                  name="fraccionamento"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    handleRadioChange(e.target.value, "fraccionamento")
                  }
                  className="border border-gray-300 rounded-md w-72 h-10 px-2"
                >
                  <option value="Mensal">Mensal</option>
                  <option value="Trimestral">Trimestral</option>
                  <option value="Semestral">Semestral</option>
                  <option value="Anual">Anual</option>
                </select>
              </div>
              {/* put the ammount of capital seguro */}
              <div className="mb-4">
                <label
                  htmlFor="capital"
                  className="block text-sm font-medium text-gray-700"
                >
                  Capital seguro
                </label>
                <input
                  type="text"
                  name="capital"
                  value={formData.data.capital ?? ""}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              {/* select the payment type */}
              <div className="mb-4">
                <label
                  htmlFor="paymentType"
                  className="block text-sm font-medium text-gray-700"
                >
                  Tipo de pagamento
                </label>
                <select
                  name="paymentType"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    handleRadioChange(e.target.value, "paymentType")
                  }
                  className="border border-gray-300 rounded-md w-72 h-10 px-2"
                >
                  <option value="68">Débito direto</option>
                  <option value="77">Referência multibanco</option>
                </select>
              </div>
              {/* os riscos apenas aparecem quando tudo tiver preenchido */}
              {formData && formData.data.capital && (
                <div className="mb-4">
                  <label
                    htmlFor="risks"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Riscos
                  </label>
                  <CheckboxList
                    checkboxes={checkboxes}
                    onCheckboxChange={handleCheckboxChange as any}
                    onSelectChange={handleSelectChange as any}
                    formData={formData}
                  />
                </div>
              )}

              <div className="flex flex-col items-center justify-center mt-4 mb-32">
                <button
                  type="submit"
                  className="border border-gray-300 rounded-md w-72 h-10 px-2"
                >
                  Simular
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageSimuladorVidaPleno;
