import { getToken } from "./Auth/helpers";
import { API } from "./Auth/constant";
import api from "./api";

const token = getToken();
const leadApiUrl = `${API}/simulacoes`;
const realtoriosApiUrl = `${API}/relatorios`;


export const updateLead = async (leadId, updatedData) => {
  try {
    const formData = new FormData();
    formData.append("data", JSON.stringify(updatedData));
    const response = await api.put(`/simulacoes/${leadId}`, formData);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const CreateRelatorio = async (updatedData) => {
  try {
    // Set the right variables
    const {
      id,
      resultadoTratamento,
      numeroApolices,
      reagendamentoDataHora,
      outros,
      fechouSimulacoes,
    } = updatedData;

    // Map the resultadoTratamento to the corresponding database value
    const mapResultadoTratamento = {
      "Fechou apólice": "Fechada",
      "Reagendou contacto": "Reagendada",
      "Não vai avançar": "Não vai avançar",
      "Lead falsa": "Falsa",
      "Lead repetida": "Repetida",
      "Não atendeu": "Não atendeu",
      Outros: "Outro",
    };

    // Create a FormData object
    const formData = new FormData();

    // Create a top-level "data" object
    const dataObject = {
      simulacao: id,
      estado:
        mapResultadoTratamento[resultadoTratamento] || resultadoTratamento,
      fechada: false, // initialize fechada as false
      reagendado: null, // initialize reagendado as null
      outros: null, // initialize outros as null
      selectedSimSales: null,
    };

    // If Estado is Fechada/Cancelada/Falsa/Repetida, set fechada to true
    if (
      resultadoTratamento === "Fechou apólice" ||
      resultadoTratamento === "Não vai avançar" ||
      resultadoTratamento === "Lead falsa" ||
      resultadoTratamento === "Lead repetida"
    ) {
      dataObject.fechada = true;
    }

    // If reagendamentoDataHora is provided, set reagendado to reagendamentoDataHora
    if (reagendamentoDataHora) {
      // expected a timestamp or an ISO date, is going as "Wed, 18 Oct 2023 17:45:45 GMT",
      const timestamp = new Date(reagendamentoDataHora).getTime();
      dataObject.reagendado = timestamp;
    }

    // If outros is provided, set outros
    if (outros) {
      dataObject.outros = outros;
    }

    // If selectedSimSales is provided, set selectedSimSales
    if (fechouSimulacoes) {
      // fechou simulacoes is an array with numbers as string make it an array with numbers
      const selectedSimSales = fechouSimulacoes.map((sim) => parseInt(sim));
      dataObject.selectedSimSales = selectedSimSales;
    }

    // Convert the "data" object to a JSON string and append it to FormData
    formData.append("data", JSON.stringify(dataObject));

    const response = await api.post("/relatorios", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const data = response.data;

    const dataObject2 = {
      estado: dataObject.estado,
      reagendado: false,
      data_reagendamento: dataObject.reagendado,
    };

    // caso data_reagendamento então reagendado tem de ser true
    if (dataObject2.data_reagendamento) {
      dataObject2.reagendado = true;
    }

    // If no error, also update simulador vida Estado
    if (!data.error) {
      await updateLead(id, dataObject2);
    }

    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error creating/updating relatorio:", error);
    throw error;
  }
};

export const updateRelatorio = async (relatorioId, lead_id, updatedData) => {
  try {
    // Set the right variables
    const {
      id,
      resultadoTratamento,
      observacao,
      numeroApolices,
      reagendamentoDataHora,
      outros,
    } = updatedData;

    // Create a FormData object
    const formData = new FormData();

    // Create a top-level "data" object
    const dataObject = {
      simulacao: lead_id,
      estado: resultadoTratamento,
      observacoes: observacao ? observacao.replace(/\n/g, "</br>") : "",
      fechada: ["Repetida", "Falsa", "Cancelada", "Fechada"].includes(
        resultadoTratamento
      ),
      reagendado: null, // initialize reagendado as null
      outros: null, // initialize outros as null
    };
    if (reagendamentoDataHora) {
      const timestamp = new Date(reagendamentoDataHora).getTime();
      dataObject.reagendado = timestamp;
    }

    // If outros is provided, set outros
    if (outros) {
      dataObject.outros = outros;
    }

    formData.append("data", JSON.stringify(dataObject));

    const response = await api.put(`/relatorios/${relatorioId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // Handle the response
    const data = response.data;

    // Update simulador vida Estado only if no error
    if (!data.error) {
      const dataObject2 = {
        estado: dataObject.estado,
        reagendado: !!dataObject.reagendado,
        data_reagendamento: dataObject.reagendado,
      };
      await updateLead(lead_id, dataObject2);
    }

    return data;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error creating/updating relatorio:", error);
    throw error;
  }
};

//deletebyid relatorio
export const deleteRelatorio = async (relatorioId) => {
  try {
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({ deletedAt: new Date(), estado: "Cancelada" })
    );

    const response = await api.put(`/relatorios/${relatorioId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// make a get request to tipo_simulacao just to get the tipo of simulacao based on the slug
export const getTipoSimulacao = async (slug) => {
  try {
    const response = await api.get(
      `/tipo-simulacoes?filters[slug][$eq]=${slug}`
    );
    const data = response.data.data[0].id;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// make a get request to tipo_simulacao just to get the tipo of simulacao based on the id
export const getTipoSimulacaoId = async (id) => {
  try {
    const response = await api.get(`/tipo-simulacoes?filters[id][$eq]=${id}`);
    const data = response.data.data[0].slug;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createObservacao = async (observacao,user,id) => {
  try {

    const formData = new FormData();
    formData.append("data", JSON.stringify(observacao));

    // Post the new observacao
    const response = await api.post("/observacoes", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = response.data?.data;
    // Get current observacoes and add the new one
    const response3 = await api.get(
      `/simulacoes/${observacao.simulacao}?populate[observacoes][id]&populate[user][populate][equipa][populate]=*`
    );
    const data3 = response3.data.data.attributes;

    // Extract observacao IDs and add the new observacao ID
    const observacoes = data3.observacoes.data.map((obs) => obs.id);
    observacoes.push(data.id);

    // Update the simulacao with the new observacoes
    const updateData = {
      observacoes: observacoes,
    };

    const formData2 = new FormData();
    formData2.append("data", JSON.stringify(updateData));

    // Update the simulacao
    await api.put(`/simulacoes/${observacao.simulacao}`, formData2, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const AgentID = data3.user.data.id;
    //  if exists ChiefID = data3.user.data.attributes.equipa.data.attributes.users_permissions_user.data.id;
    const ChiefID = data3.user.data.attributes.equipa.data.attributes.users_permissions_user.data.id;

    // if level is betwen 0 and 3 then level is High if is 3 or 4 then level is Medium and after that is Low
    let level = "Low";
    if (user.tipo.level <= 3) {
      level = "High";
    }
    else if (user.tipo.level <= 4) {
      level = "Medium";
    }


    const notificationData = {
      message: "Nova observação no relatório " + id,
      time: new Date().getTime(),
      // level
      level: level,
      link: `/relatorio/${id}`,
    };

    // Create a notification
    const formData3 = new FormData();
    formData3.append("data", JSON.stringify(notificationData));
    const notificationResponse = await api.post("/notifications", formData3, 
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const notificationId = notificationResponse.data.data.id;
    
    // create notification-user for the agent and the chief
    const notificationUserData = {
      user: AgentID,
      notification: notificationId,
    };

    const notificationUserData2 = {
      user: ChiefID,
      notification: notificationId,
    };

    const formData4 = new FormData();
    formData4.append("data", JSON.stringify(notificationUserData));
    const notificationUserResponse = await api.post("/notification-users", formData4, 
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const formData5 = new FormData();
    formData5.append("data", JSON.stringify(notificationUserData2));
    const notificationUserResponse2 = await api.post("/notification-users", formData5, 
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// get tipo by doing a get request to tipos table
export const getTipo = async () => {
  try {
    const response = await api.get('/tipos');
    const data = response.data.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateTipo = async (tipoId, updatedData) => {
  try {
    // Create a FormData object and append the updatedData
    const formData = new FormData();
    formData.append("data", JSON.stringify(updatedData));

    // Make the PUT request with the FormData
    const response = await api.put(`/tipos/${tipoId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // Handle the response
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
