import React, { useState } from "react";
import { Button, Card, Form, Input, message, Select, Spin } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../api/Auth/constant";
import { getToken } from "../../api/Auth/helpers";

const { Option } = Select;

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const { user, isLoading, setUser } = useAuthContext();

  const handleProfileUpdate = async (data: any) => {
    setLoading(true);
    try {
      const response = await fetch(`${API}/users/${user?.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();

      setUser(responseData);
      message.success("Data saved successfully!");
    } catch (error) {
      console.error(error);
      message.error("Error While Updating the Profile!");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen overflow-hidden">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-screen overflow-hidden">
      <Card className="p-4 md:w-1/2">
        <Form
          layout="vertical"
          initialValues={{
            username: user?.username,
            email: user?.email,
            phone: user?.phone,
          }}
          onFinish={handleProfileUpdate}
        >
          <table className="w-full">
            <tbody>
              <tr>
                <td className="py-2">
                  <label className="font-semibold">Nome do utilizador:</label>
                </td>
                <td className="py-2">
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input className="w-full" />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <label className="font-semibold">Email:</label>
                </td>
                <td className="py-2">
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please input your email!" },
                    ]}
                  >
                    <Input className="w-full" />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <label className="font-semibold">Telefone:</label>
                </td>
                <td className="py-2">
                  <Form.Item
                    name="phone"
                    rules={[
                      { required: true, message: "Please input your phone!" },
                    ]}
                  >
                    <Input className="w-full" />
                  </Form.Item>
                </td>
              </tr>
            </tbody>
          </table>
          <Form.Item>
            <div className="flex items-center justify-center">
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                className="w-1/2 bg-blue-700 hover:bg-blue-600"
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Profile;
