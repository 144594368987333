import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Row,
  Col,
  Spin,
  message,
  Button,
  Radio,
  Modal,
} from "antd";
import { getRelatorioById } from "../../api/getSimInfo";
import { createObservacao, updateRelatorio } from "../../api/Backend";
import { AddToCalendarButton } from "add-to-calendar-button-react";

import PrintableReport from "../print/PrintableReport";
import ReactDOM from "react-dom"; // Add this import
import { useAuthContext } from "../../context/AuthContext";

interface FormData {
  referencia: string | null;
  id_ref: number | null;
  id_ref_sim: number | null;
  dataRececao: string | null;
  numeroAgente: string | null;
  nomeAgente: string | null;
  resultadoTratamento: string;
  reagendamentoDataHora: string | null;
  outros: string | null;
  numeroApolices: string | null;
  AddObservacao?: string | null;
}
interface observacao {
  data: observacaoData[];
}

interface observacaoData {
  id: number;
  attributes: {
    user: any;
    texto: string;
    simulacao: SaleSimData;
    createdAt: string;
  };
}
interface SaleSimData {
  id: number;
  attributes: {
    nome: string;
    email: string;
    phone: string;
    resultado_simulacao: any;
    dados_simulacao: any;
    createdAt: string;
    updatedAt: string;
    tipo_simulacao: {
      data: {
        id: number;
        attributes: {
          simulador: string;
          createdAt: string;
          updatedAt: string;
          slug: string;
        };
      };
    };
    simulacao: simuladorData;
  };
}
interface sales {
  data: SaleSimData[];
}

interface RelatorioData {
  id: number;
  attributes: {
    simulacao: simuladorData;
    estado: any;
    Fechado: boolean;
    reagendado: boolean;
    outros: string;
    selectedSimSales?: sales;
  };
}

interface simuladorData {
  data: {
    attributes: {
      nome: string;
      email: string;
      phone: string;
      birthday: string;
      resultado_simulacao: ResultadoSimulacao;
      createdAt: string;
      updatedAt: string;
      uuid: string;
      user: UserData;
      estado: any;
      observacoes: observacao;
      tipo_simulacao: {
        data: {
          id: number;
          attributes: {
            simulador: string;
            createdAt: string;
            updatedAt: string;
            slug: string;
          };
        };
      };
    };
    id: number;
  };
}

interface UserData {
  id: number;
  username: string;
  email: string;
  confirmed: boolean;
  blocked: boolean;
  role: {
    id: number;
    name: string;
    description: string;
    type: string;
  };
  tipo: {
    nivel: string;
    cor: string;
    id: number;
    level:any;
  };
  phone: string;
  equipa: {
    id: number;
    nome_equipa: string;
    zona: {
      id: number;
      nome_zona: string;
    };
  };
}
interface ResultadoSimulacao {
  name: string;
  birthday: string;
  contract: string;
  duration: number;
  radAwardCapital: string;
  award: number;
  capital: number;
  payment: number;
  percentage: number;
  numberIterations: number;
  age: number;
  guaranteedRate: number;
  indexing: number;
  capital_Ma: number;
  capital_Mac: number;
}

const RelatorioView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [relatorioData, setRelatorioData] = useState<RelatorioData | null>(
    null
  );

  const { user, triggerUpdate } = useAuthContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formattedStartDate, setFormattedStartDate] = useState<string>("");
  const [formattedStartMinutes, setFormattedStartMinutes] =
    useState<string>("");
  const [addtime, setAddTime] = useState<string>("");

  // make a variable that saves the relatorio form
  const [relatorio, setRelatorio] = useState<FormData | null>(null);

  const observacaoValue = form.getFieldValue("observacao");
  useEffect(() => {
    const fetchRelatorioData = async () => {
      try {
        setLoading(true);
        const response = await getRelatorioById(id);

        // Certifique-se de que está acessando os campos corretos
        const relatorioAttributes = response.attributes;

        // make function to formate dates
        const formatDate = (date: string) => {
          const dateObject = new Date(date);
          const day = dateObject.getDate();
          const month = dateObject.getMonth() + 1;
          const year = dateObject.getFullYear();
          const hours = dateObject.getHours();
          const minutes = dateObject.getMinutes();
          const formattedDate = `${day}-${month}-${year}`;
          return formattedDate;
        };
        const formatDate2 = (date: string) => {
          const dateObject = new Date(date);
          const day = dateObject.getDate();
          const month = dateObject.getMonth() + 1;
          const year = dateObject.getFullYear();
          const hours = dateObject.getHours();
          const minutes = dateObject.getMinutes();
          const formattedDate = `${year}-${month}-${day}`;
          return formattedDate;
        };
        // make function to formate dates
        const formatMinutes = (date: string) => {
          const dateObject = new Date(date);
          const hours = dateObject.getHours();
          const minutes = dateObject.getMinutes();
          const formattedDate = `${hours}:${minutes}`;
          return formattedDate;
        };

        const initialValues = {
          referencia: relatorioAttributes.simulacao.data.attributes.uuid,
          id_ref: response.id,
          id_ref_sim: relatorioAttributes.simulacao.data.id,
          dataRececao: `${formatDate(
            relatorioAttributes.createdAt
          )} ${formatMinutes(relatorioAttributes.createdAt)}`,
          outros: relatorioAttributes.outros,
          observacao: relatorioAttributes.observacoes,
          resultadoTratamento: relatorioAttributes.estado,
          reagendamentoDataHora: relatorioAttributes.reagendado
            ? formatDate(relatorioAttributes.reagendado)
            : null,
          numeroApolices: relatorioAttributes.fechada ? "Fechada" : "",
          numeroAgente:
            relatorioAttributes.simulacao.data.attributes.user.data.id,
          nomeAgente:
            relatorioAttributes.simulacao.data.attributes.user.data.attributes
              .username,
        };
        form.setFieldsValue(initialValues);

        setRelatorioData(response);

        setFormattedStartDate(formatDate2(relatorioAttributes.reagendado));
        setFormattedStartMinutes(formatMinutes(relatorioAttributes.reagendado));

        const endDateTime = addMinutes(relatorioAttributes.reagendado, 45);

        setAddTime(formatMinutes(endDateTime as any));

        // set the initalvalues to the relatorio
        setRelatorio(initialValues);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRelatorioData();
  }, [id, form]);
  const addMinutes = (dateString: any, minutes: any) => {
    const dateObject = new Date(dateString);
    dateObject.setMinutes(dateObject.getMinutes() + minutes);
    return dateObject;
  };

  const handleSubmit = async (values: FormData) => {
    setLoading(true);
    // check if the observacao is empty
    if (!values.AddObservacao) {
      message.error("Observação não pode estar vazia");
      setLoading(false);
      return;
    }

    const formdata = {
      texto: values.AddObservacao,
      simulacao: relatorio?.id_ref_sim,
      user: user?.id,
    };
    // create observacao
    const response = await createObservacao(formdata,user,id);
    if (response) {
      message.success("Observação adicionada com sucesso");
    } else {
      message.error("Erro ao adicionar observação");
    }
    setLoading(false);
    triggerUpdate();
    // reload page
    window.location.reload();
  };
  const handleImprimirRelatorio = () => {
    // Open a new window for printing
    const printWindow = window.open("", "_blank");

    // Render the PrintableReport component inside the new window
    if (printWindow) {
      printWindow.document.write(
        "<html><head><title>Print</title></head><body>"
      );
      printWindow.document.write('<div style="text-align:center;">');
      if (relatorioData) {
        ReactDOM.render(
          <PrintableReport data={relatorioData} />,
          printWindow.document.body
        );
      } else {
        console.error("RelatorioData is null");
      }
      printWindow.document.write("</div></body></html>");
      printWindow.document.close();

      // Trigger the print function
      printWindow.print();
    } else {
      // Handle window not opened (possibly blocked by the browser)
      console.error("Print window blocked by the browser.");
    }
  };

  function getTextColorBasedOnBackground(backgroundColor: string): string {
    // Converte a cor hexadecimal para RGB
    const hexToRgb = (hex: string): number[] => {
      const bigint = parseInt(hex.slice(1), 16);
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    };

    // Obtém os componentes RGB da cor de fundo
    const [r, g, b] = hexToRgb(backgroundColor);

    // Calcula o valor de luminosidade (brilho)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Retorna a cor do texto com base na luminosidade
    return brightness > 128 ? "text-black" : "text-white";
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen overflow-hidden ">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen overflow-hidden">
      <h1 className="text-2xl font-bold text-gray-900">Relatório</h1>
      <Form
        form={form}
        name="relatorio"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Row gutter={[36, 8]} align={"middle"} style={{ marginTop: "5%" }}>
          <Col span={12}>
            <Form.Item label="Referência" name="referencia">
              {/* make a label that looks like a input disabled */}
              <div
                className="bg-gray-100 border border-gray-400 rounded p-1 text-gray-400 cursor-pointer hover:text-gray-600 hover:border-gray-700"
                onClick={() =>
                  window.open(
                    // should be tipo_simulacao/sim/uuid da venda mae
                    `/${relatorioData?.attributes?.simulacao?.data?.attributes?.tipo_simulacao?.data?.attributes?.slug}/${relatorioData?.attributes?.simulacao?.data?.attributes?.uuid}`
                  )
                }
              >
                <div
                  style={{ whiteSpace: "pre-line" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      relatorioData?.attributes?.simulacao?.data?.attributes?.uuid.replace(
                        /\n/g,
                        "<br />"
                      ) || "",
                  }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Data de Receção" name="dataRececao">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Número de Agente" name="numeroAgente">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Nome de Agente" name="nomeAgente">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[36, 8]} align={"middle"}>
          <Col span={12}>
            <Form.Item
              label="Resultado do Tratamento"
              name="resultadoTratamento"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          {form.getFieldValue("resultadoTratamento") === "Fechada" &&
            relatorioData?.attributes?.selectedSimSales &&
            relatorioData?.attributes?.selectedSimSales?.data?.length > 0 && (
              <>
                <Col span={12}>
                  <Form.Item label="Número das Apólices" name="numeroApolices">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Vendas fechadas a partir das simulações"
                    name="numeroApolices"
                  >
                    {/* make a label that looks like a input disabled */}
                    {relatorioData?.attributes?.selectedSimSales?.data?.map(
                      (sale: SaleSimData) => (
                        <div
                          className="bg-gray-100 border border-gray-400 rounded p-1 text-gray-400 cursor-pointer hover:text-gray-600 hover:border-gray-700 mb-2"
                          onClick={() =>
                            window.open(
                              // should be tipo_simulacao/sim/uuid da venda mae

                              `${sale.attributes.tipo_simulacao.data.attributes.slug}/sim/${sale.attributes.simulacao.data.attributes.uuid}/${sale.id}`
                            )
                          }
                        >
                          <div
                            style={{ whiteSpace: "pre-line" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                `${
                                  sale.attributes.tipo_simulacao.data.attributes
                                    .simulador
                                } - ${sale.attributes.nome} - ${
                                  sale.attributes?.dados_simulacao.award
                                    ? sale.attributes?.dados_simulacao.award +
                                      "€/mês"
                                    : "Capital " + sale.attributes?.dados_simulacao.capital +
                                      "€"
                                }` || "",
                            }}
                          />
                        </div>
                      )
                    )}
                  </Form.Item>
                </Col>
              </>
            )}{" "}
          {form.getFieldValue("resultadoTratamento") === "Reagendada" && (
            <Col span={12}>
              <Form.Item
                label="Data Hora de Reagendamento"
                name="reagendamentoDataHora"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          )}
          {form.getFieldValue("resultadoTratamento") === "Reagendada" &&
            formattedStartDate &&
            relatorioData && (
              <Col span={12}>
                <>
                  <AddToCalendarButton
                    name={`Contactar o potencial segurado ${relatorioData?.attributes?.simulacao?.data?.attributes?.nome} no dia ${formattedStartDate} ${formattedStartMinutes}`}
                    startDate={formattedStartDate}
                    startTime={formattedStartMinutes}
                    endTime={addtime}
                    language="pt"
                    listStyle="modal"
                    key={
                      relatorioData?.attributes?.simulacao?.data?.attributes
                        ?.uuid
                    }
                    options={["Apple", "Google", "Yahoo", "iCal"]}
                    description={`Entrar em contacto com o segurado ${relatorioData?.attributes?.simulacao?.data?.attributes?.nome} da lead ${relatorioData?.attributes?.simulacao?.data?.attributes?.uuid}\n\n\n - [url]http://localhost:3000/vida-futura/${relatorioData?.attributes?.simulacao?.data?.attributes?.uuid}[url]`}
                    timeZone="UTC"
                    trigger="hover"
                  ></AddToCalendarButton>
                </>
              </Col>
            )}
          {form.getFieldValue("resultadoTratamento") === "Outro" && (
            <Col span={12}>
              <Form.Item label="Outro" name="outros">
                <Input disabled />
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <div
              className="observacoes-container bg-gray-100 rounded-md p-4 shadow-md"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <h2 className="text-lg font-semibold mb-4">Observações:</h2>

              {relatorioData?.attributes?.simulacao?.data?.attributes
                ?.observacoes?.data &&
                relatorioData?.attributes?.simulacao?.data?.attributes?.observacoes?.data.map(
                  (observacao: observacaoData, index: number) => {
                    const tipoCor =
                      observacao.attributes.user.data.attributes.tipo.data
                        .attributes.cor;
                    const corTexto = getTextColorBasedOnBackground(tipoCor);
                    return (
                      <div
                        key={index}
                        className="bg-gray-100 rounded-md p-4 shadow-md mb-4"
                      >
                        <div
                          className={`flex justify-between w-full p-2 rounded-md mb-2 text-sm font-semibold
                          ${corTexto}
                          `}
                          style={{
                            backgroundColor: tipoCor,
                          }}
                        >
                          <div className="flex items-center">
                            <p>
                              {`${observacao.attributes.user.data.attributes.username} (${observacao.attributes.user.data.attributes.tipo.data.attributes.nivel})`}
                            </p>
                          </div>
                          <p>
                            {new Date(
                              observacao.attributes.createdAt
                            ).toLocaleString("pt-PT", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </p>
                        </div>
                        <p className="text-gray-900">
                          {observacao.attributes.texto}
                        </p>
                      </div>
                    );
                  }
                )}

              {/* Adicionar Observação: */}
              <Form.Item label="Nova Observação" name="AddObservacao">
                <Input.TextArea className="mb-2" />
              </Form.Item>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => form.submit()}
              >
                Adicionar Observação
              </button>
            </div>
          </Col>
        </Row>

        {/* botão para enviar observacao */}
        <Row gutter={[36, 8]} align={"middle"}>
          <Col span={24}>
            <Form.Item>
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={() => handleImprimirRelatorio()}
              >
                Imprimir Relatório
              </button>

              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => navigate("/relatorios")}
              >
                Voltar
              </button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default RelatorioView;
